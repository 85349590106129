import React from "react"

const CustomSlider = ({
  handleUpdateQuestion, question
}) => (
  <>
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label>Left Value</label>
          <input
            className="form-control"
            name="slider_label_one"
            onBlur={handleUpdateQuestion}
            placeholder="Less than usual"
            type="text"
            defaultValue={question.slider_label_one} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label>Right Value</label>
          <input
            className="form-control"
            name="slider_label_two"
            onBlur={handleUpdateQuestion}
            placeholder="More than usual"
            type="text"
            defaultValue={question.slider_label_two} />
        </div>
      </div>
    </div>
  </>
)

export default CustomSlider