import React, { useState } from "react"
import styled from "styled-components"
import PreviewAnswer from  "./PreviewAnswer"

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;

  > *:nth-child(2) {
    text-align: right;
  }

  @media (max-width: 550px) {
    margin-left: 10px;
  }
`

const SliderContainer = styled.div`
  display: flex;
  
  > *:nth-child(2) {
    margin-left: 12px;
  }

  @media (max-width: 550px) {
    margin-left: 10px;
  }
`

const Slider = styled.input`
  width: 80%;

  ::-webkit-slider-thumb {
    ${props => (!props.showThumb && "opacity: 0")};
  }
`
const PulseTemplateQuestion = ({
  question: {
    answers,
    question_type,
    question_label,
    label,
    slider_label_one,
    slider_label_two,
    id
  },
}) => {
  const [value, setValue] = useState(0)
  const [showThumb, setShowThumb] = useState(false)
  const buttonGroupClass = answers.length > 5 ? "btn-group btn-group-toggle horizontal" : "btn-group btn-group-toggle"

  const handleChange = e => {
    setValue(e.target.value)
  }

  const handleSliderThumbShow = () => {
    if (!showThumb) {
      setShowThumb(true)
    }
  }

  switch (question_type) {
    case "input":
      return (
        <div className="q-group pulse-input mb-5">
          <p>{label}</p>
          <div className="form-group row">
            <label className="col-5 col-md-3 col-form-label strong">{question_label}</label>
            <div className="col-6 col-sm-5">
              <input
                className="form-control"
                name="slider_label_one"
                type="text"
                defaultValue="..." />
            </div>
          </div>
        </div>
      )
    case "slider_7":
    case "slider_10":
    case "slider_10_reversed":
      return (
        <div className="q-slider-group mb-5" onMouseEnter={handleSliderThumbShow}>
          <p className="slider-label">{label}</p>
          <SliderContainer>
            <Slider
              id={answers[0]?.id}
              min="0"
              max={question_type === "slider_7" ? "7" : "10"}
              className="custom-range"
              name={id}
              onChange={handleChange}
              showThumb={showThumb}
              type="range"
              value={value} />
            <div>{value}</div>
          </SliderContainer>
          <LabelContainer>
            <div className="col-6">{slider_label_one}</div>
            <div className="col-6">{slider_label_two}</div>
          </LabelContainer>
        </div>
      )
    default:
      return (
        <>
          <div className="q-group mb-5">
            <p>{label}</p>
            <div className={buttonGroupClass} data-toggle="buttons">
              {answers && answers.map(answer => (
                <PreviewAnswer
                  answer={answer}
                  key={answer.id} />
              ))}
            </div>
          </div>
          <br></br>
        </>
      )
  }
}

export default PulseTemplateQuestion
