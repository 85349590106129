import React, { useEffect, useState } from "react"

import _ from "lodash"
import SimplifiedQuestion from "./SimplifiedQuestion"

const SimpleForm = ({
  questionSet, form, onChangeHandler, onInputChangeHandler, onFreeTextChangeHandler
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const { compulsory_question_slugs, section_title, section_slug } = questionSet

  useEffect(() => {
    if (compulsory_question_slugs.length && form.length) {
      setIsCollapsed(_.difference(compulsory_question_slugs, Object.keys(form)).length === 0)
    }
  }, [questionSet])

  const questionSetElement = ({ questions }) => {
    const elements = questions.map(q => {
      const {
        answers,
        compulsory,
        descriptor,
        dependent_on: dependentOn,
        label,
        question_slug: questionSlug,
        question_type: questionType,
        sorting_index: sortingIndex
      } = q

      return (
        <SimplifiedQuestion
          answers={answers}
          compulsory={compulsory}
          descriptor={descriptor}
          dependentOn={dependentOn}
          form={form}
          label={label}
          questionSlug={questionSlug}
          questionType={questionType}
          selectedAnswer={form[questionSlug]}
          sortingIndex={sortingIndex}
          onChangeHandler={onChangeHandler}
          onInputChangeHandler={onInputChangeHandler}
          onFreeTextChangeHandler={onFreeTextChangeHandler} />
      )
    })
    return elements
  }

  return (
    <>
      {/* Not hiding correctly for some reason */}
      {/* <div className="accordion" id="accordionExample">
        <div className="card">
          <button
            className="btn btn-link p-0"
            type="button"
            data-toggle="collapse"
            data-target={`#collapseOne-${section_slug}`}
            aria-expanded="true"
            aria-controls={`collapseOne-${section_slug}`}>
            <div className="card-header" id={`heading-${section_slug}`}>
              <div className="mb-0">{section_title}</div>
            </div>
          </button>
          <div
            id={`collapseOne-${section_slug}`}
            className={`collapse ${isCollapsed ? "show" : ""}`}
            aria-labelledby={`heading-${section_slug}`}
            data-parent="#accordionExample">
            <div className="card-body">
              {questionSetElement(questionSet)}
            </div>
          </div>
        </div>
      </div> */}

      <div className="accordion" id="accordionExample">
        <div className="card">
          <button
            className="btn btn-link p-0"
            type="button"
            onClick={() => setIsCollapsed(!isCollapsed)}
            aria-expanded="true"
            aria-controls={`collapseOne-${section_slug}`}>
            <div className="card-header" id={`heading-${section_slug}`}>
              <div className="mb-0">{section_title}</div>
            </div>
          </button>
          {!isCollapsed && (
            <div
              id={`collapseOne-${section_slug}`}
              className={`collapse ${isCollapsed ? "" : "show"}`}
              aria-labelledby={`heading-${section_slug}`}
              data-parent="#accordionExample">
              <div className="card-body">
                {questionSetElement(questionSet)}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default SimpleForm
