import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"

import _ from "lodash"
import { getMatchfitSimple } from "../../../../requesters/CalculatorRequester"

import { getCalculatorResult, updateCalculatorResult } from "../../../../requesters/CalculatorResultsRequester"
import Header from "../Header"
import SimpleSection from "./SimpleSection"

const SimpleForm = ({ baseUrl, currentUser }) => {
  const [questionSets, setQuestionSets] = useState()
  const [form, setForm] = useState({})
  const [resultId, setResultId] = useState("")
  const { addToast } = useToasts()

  // eslint-disable-next-line no-console
  console.log(questionSets)
  // eslint-disable-next-line no-console
  console.log(form)

  const fetchQuestionSet = () => {
    const params = { id: currentUser.active_matchfit_id, calculator_type: "matchfit" }
    getMatchfitSimple(baseUrl, params, currentUser).then(res => {
      setQuestionSets(res.response.json.calc.grouped_questions)
    })
  }

  const fetchCalculatorResult = () => {
    getCalculatorResult(baseUrl, currentUser, "matchfit").then(res => {
      const {
        calc_responses: calculatorResponses,
      } = res.response.json?.calculator_results
      if (calculatorResponses?.length) {
        const retrievedAnswers = {}
        calculatorResponses.forEach(cr => {
          retrievedAnswers[cr.question_slug] = {
            answer_id: cr.answer_id,
            answer_slug: cr.answer_slug,
            free_text_field: cr.free_text_field,
            number_field: cr.number_field,
            hours: cr.hours,
            minutes: cr.minutes,
            seconds: cr.seconds,
          }
        })
        setForm(retrievedAnswers)
      }
      setResultId(res.response.json?.calculator_results?.id)
    })
  }

  useEffect(() => {
    fetchQuestionSet()
    fetchCalculatorResult()
  }, [])

  const onChangeHandler = e => {
    const { name, id } = e.target
    const formCopy = { ...form }
    const answer_slug = e.target.getAttribute("slug")
    formCopy[name] = { answer_id: id, answer_slug }

    setForm(formCopy)
  }

  const onFreeTextChangeHandler = e => {
    const { id, name, value } = e.target
    const formCopy = { ...form }
    formCopy[name] = { answer_id: id, free_text_field: value }
    setForm(formCopy)
  }

  const onInputChangeHandler = e => {
    const {
      name, id, value, hours, minutes, seconds
    } = e.target
    const formCopy = { ...form }
    formCopy[name] = {
      answer_id: id,
      number_field: value,
      hours,
      minutes,
      seconds
    }
    setForm(formCopy)
  }

  const onSaveHandler = () => {
    const formWithFloatFields = { ...form }
    const {
      sit_to_stands,
      left_leg_balance,
      right_leg_balance,
      tobacco_cigarettes_quantity,
      tobacco_cigars_quantity,
      tobacco_pipes_quantity,
      tobacco_vapes_quantity,
    } = formWithFloatFields

    if (sit_to_stands?.number_field) {
      sit_to_stands.float_field = sit_to_stands.number_field
      delete sit_to_stands.number_field
    }
    if (left_leg_balance?.number_field) {
      left_leg_balance.float_field = left_leg_balance.number_field
      delete left_leg_balance.number_field
    }
    if (right_leg_balance?.number_field) {
      right_leg_balance.float_field = right_leg_balance.number_field
      delete right_leg_balance.number_field
    }
    if (tobacco_cigarettes_quantity?.number_field) {
      tobacco_cigarettes_quantity.float_field = tobacco_cigarettes_quantity.number_field
      delete tobacco_cigarettes_quantity.number_field
    }
    if (tobacco_cigars_quantity?.number_field) {
      tobacco_cigars_quantity.float_field = tobacco_cigars_quantity.number_field
      delete tobacco_cigars_quantity.number_field
    }
    if (tobacco_pipes_quantity?.number_field) {
      tobacco_pipes_quantity.float_field = tobacco_pipes_quantity.number_field
      delete tobacco_pipes_quantity.number_field
    }
    if (tobacco_vapes_quantity?.number_field) {
      tobacco_vapes_quantity.float_field = tobacco_vapes_quantity.number_field
      delete tobacco_vapes_quantity.number_field
    }
    const payload = _.toArray(form)
    updateCalculatorResult(baseUrl, resultId, payload, false, currentUser).then(() => {
      addToast(`Success: mfc survey saved!`, { appearance: "success" })
      window.location.reload()
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }

  return (
    <div className="container">
      <Header form={form} onSaveHandler={onSaveHandler} />
      {!!questionSets && questionSets.map(questionSet => (
        <SimpleSection
          key={questionSet.section_slug}
          form={form}
          questionSet={questionSet}
          onChangeHandler={onChangeHandler}
          onInputChangeHandler={onInputChangeHandler}
          onFreeTextChangeHandler={onFreeTextChangeHandler} />
        ))}
    </div>
  )
}

export default SimpleForm
