import React from "react"
import { ANSWER_TYPES } from "../constants"
import CustomAnswer from "../CustomAnswer"
import CustomSlider from "../CustomSlider"

const CustomQuestionCard = ({
  answerList,
  currentQuestion,
  handleDeleteOption,
  handleNewOption,
  handleUpdateAnswers,
  handleUpdateQuestion,
  index,
}) => {
  const renderQuestionSelection = () => {
    switch (currentQuestion.question_type) {
      case "custom":
        return (
          <>
            <button
              className="btn btn-link"
              disabled={answerList.length >= 20}
              type="button"
              onClick={handleNewOption}>
              + Add Option
            </button>
            {!!answerList.length && answerList.map((answer, index) => (
              <CustomAnswer
                answer={answer}
                answerlist={answerList}
                handleDelete={handleDeleteOption}
                handleUpdateAnswers={handleUpdateAnswers}
                index={index}
                key={answer.label} />
            ))}
          </>
        )
      case "slider_10":
      case "slider_10_reversed":
        return (
          <CustomSlider
            handleUpdateQuestion={handleUpdateQuestion}
            question={currentQuestion} />
        )
      default:
        return null
    }
  }

  return (
    <>
      <div className="form-group">
        <label>{`Question ${index + 1}`}</label>
        <textarea
          className="form-control form-control-lg"
          name="label"
          onChange={handleUpdateQuestion}
          placeholder="Over the past week, how often have you felt..."
          type="text"
          value={currentQuestion.label} />
      </div>
      <div className="form-group">
        <label>Dashboard Description</label>
        <textarea
          className="form-control form-control-lg"
          name="descriptor"
          onChange={handleUpdateQuestion}
          placeholder="During your week, try to..."
          type="text"
          value={currentQuestion.descriptor} />
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Dashboard Label</label>
            <input
              className="form-control"
              name="question_label"
              onBlur={handleUpdateQuestion}
              placeholder={`question_${index + 1}`}
              type="text"
              onChange={handleUpdateQuestion}
              value={currentQuestion.question_label} />
          </div>
          {!currentQuestion.question_label
            && <div className="err-msg text-danger">Question label cannot be blank</div>}
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Target Score (0-100)</label>
            <input
              className="form-control"
              name="target_score_percent"
              onChange={handleUpdateQuestion}
              placeholder={80}
              type="number"
              min={0}
              max={100}
              onWheel={e => e.target.blur()}
              value={currentQuestion.target_score_percent} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Answer Type</label>
            <select
              className="form-control"
              name="question_type"
              onChange={handleUpdateQuestion}
              value={currentQuestion.question_type || ANSWER_TYPES[0]}>
              {ANSWER_TYPES.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Target Score Label (appears on Dashboard)</label>
            <input
              className="form-control"
              name="target_score"
              onBlur={handleUpdateQuestion}
              placeholder="Often"
              type="text"
              defaultValue={currentQuestion.target_score} />
          </div>
        </div>
      </div>
      <div className="custom-qns-set">
        <div className="custom-qns-options">
          {renderQuestionSelection()}
        </div>
      </div>
    </>
  )
}

export default CustomQuestionCard