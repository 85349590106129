import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { getBaseUrl, getCurrentUser } from "../../../reducers"
import DraggableQuestions from "./DraggableQuestions"

const TemplateQuestionForm = ({
  onAddQuestion,
  onAddSystemQuestion,
  questionList,
  setQuestionList,
  systemQuestionList
}) => (
  <>
    <div className="template-btn-container">
      <button
        className="btn btn-primary mt-4 mb-4"
        onClick={onAddQuestion}
        type="button">
        Add Question
      </button>
      <button
        className="btn btn-primary mt-4 mb-4"
        onClick={onAddSystemQuestion}
        type="button">
        Add System Question
      </button>
    </div>
    {!!questionList.length && (
      <DraggableQuestions
        form={questionList}
        setForm={setQuestionList}
        systemQuestionList={systemQuestionList} />
    )}
  </>
  )


const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  baseUrl: getBaseUrl(state)
})

export default withRouter(connect(mapStateToProps)(TemplateQuestionForm))
