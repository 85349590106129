import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { CSVLink } from "react-csv"
import { getExportsList } from "../../requesters/Backend/ProgramRequester"

const BackendExportsDashboard = ({ baseUrl, currentUser }) => {
  const [list, setList] = useState([])

  const fetchList = () => {
    getExportsList(baseUrl, currentUser).then(res => {
      setList(res.response.json.exports)
      return null
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Recent Exports</h2>
          </div>
        </div>
        {/* <div className="table-filters d-inline-flex">
          <input className="form-control" placeholder="Search Organisation / Unit" type="search" />
          <select className="form-control"><option>Status</option></select>
        </div> */}
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Program Name</th>
                <th>Initiated by</th>
                <th>File Name</th>
                <th>Status</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {list.map(item => (
                <tr key={item.id}>
                  {/* <td><Link to={`/backend/organisations/${program.organisation_id}`}>{program.organisation_name}</Link></td>
                  <td><Link to={`/backend/units/${program.unit_id}`}>{program.unit_name}</Link></td>

                  <td>{program.unique_id}</td>
                  <td>{program.status}</td>
                  <td>{program.unit_contact_name}</td>
                  <td>{program.unit_contact_email}</td> */}
                  <td>{item.created_at}</td>
                  <td><Link to={`/backend/programs/${item.calculator?.program_id}`}>{item.calculator?.program_name}</Link></td>
                  <td>{item.author}</td>
                  <td>{item.file_name}</td>
                  <td>{item.status}</td>
                  <td>
                    {!!item.data && (
                      <CSVLink
                        className="btn btn-main btn-xs mr-3"
                        data={JSON.parse(item.data)}
                        filename={item.file_name}
                        target="_blank">
                        Download
                      </CSVLink>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default BackendExportsDashboard