import React from "react"
import { Link } from "react-router-dom"
import LiveLifeHeader from "./LiveLifeHeader"


const LiveLifeIntro = ({ currentUser }) => {
  const unfinishedSurveyExists = currentUser.active_enrolment.is_new_attempt_live_life


  // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_live_life_id) {
    window.location.href = "/overview"
    return null
  }

  // Route to sociodemographic questions when they are not complete
  if (!currentUser.active_enrolment?.sociodemographic_complete) {
    window.location.href = "/demographic/livelife"
    return null
  }

  return (
    <>
      <LiveLifeHeader />
      <div className="container pb-5">
        <h1>LiveLife Score</h1>
        <p>Welcome to the LiveLife Score. This survey will take approximately 7 minutes to complete.</p>
        <p>Please have the following ready:</p>
        <ol className="pb-3">
          <li>Your height (cm) and weight (kg) measurements</li>
          <li>If you use a wearable device for activities, ensure you can access the last week of data</li>
          <li>If you use a wearable device that measures heart rate, please wear the device</li>
          <li>A space where you can perform a push-up test (optional).</li>
        </ol>
        <p>
          <b>Note: </b>
          We do not recommend undertaking the LiveLife Score Survey if you:
        </p>
        <ul>- are currently pregnant,</ul>
        <ul>- are within 6-month postnatal, or</ul>
        <ul>- have a pre-existing medical condition, or or suffer from an injury or condition that can be made worse by strenuous physical activity, we recommend not undertaking the strength test (push ups).</ul>
        <p>Individual data remains confidential and will never be shared with your organisation. We only report anonymised and aggregated data to share insights with your employer and for research purposes.</p>
        {/* <p>Please complete this survey and click the &apos;Complete Survey&apos; button when all sections are done.</p> */}
        <p>You may save your progress at any time by clicking on the &apos;Save Draft&apos; button at the top right of the screen.</p>
        <Link className="btn btn-main mt-4" to="/livelife/questions">{`${unfinishedSurveyExists ? "Start" : "Continue"} Survey`}</Link>
      </div>
    </>
  )
}

export default LiveLifeIntro
