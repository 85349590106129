import React from "react"
import { Link } from "react-router-dom"
import BurnoutProofHeader from "./BurnoutProofHeader"

const BurnoutProofIntro = ({ currentUser }) => {
  // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_burnout_proof_id) {
    window.location.href = "/overview"
    return null
  }

  // Route to sociodemographic questions when they are not complete
  if (!currentUser.active_enrolment?.sociodemographic_complete) {
    window.location.href = "/demographic/burnout-proof"
    return null
  }

  return (
    <>
      <BurnoutProofHeader inflight />
      <div className="container pb-5">
        <h1>Burnout Proof Calculator</h1>
        <p>Burnout is characterised by symptoms of exhaustion, disconnection or alienation from work and reduced performance. Such symptoms may include feeling emotionally drained and overwhelmed, physical exhaustion, or feelings of cynicism, inadequacy, or resentment. Burnout, like other long-term stress, lowers your immunity levels and makes you more susceptible to viruses such as colds and flu, and can lead to mental health concerns like depression and anxiety symptoms. Negative impacts of burnout can affect your work, home and social life and so it is important to recognise the signs of burnout so that you can be proactive in managing your mental health.</p>
        <p>By completing this survey, you will be provided with your likelihood or risk of burnout and recommendations on what to do to reduce symptoms of burnout.</p>
        <Link className="btn btn-main mt-4" to="/burnout-proof/questions">Start Survey</Link>
      </div>
    </>
  )
}

export default BurnoutProofIntro
