import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { createTemplate, getSystemQuestions } from "../../requesters/Backend/CalculatorRequester"
import TemplateQuestionForm from "./Templates/TemplateQuestionForm"
import ScoringSplit from "./Templates/ScoringSplit"
import { ANSWER_TYPES, DEFAULT_LIKERT_ANSWERS } from "./Templates/constants"

const CreateTemplatePage = ({ baseUrl, currentUser }) => {
  const [form, setForm] = useState({
    name: "",
    new_status: "template",
    languishing_calculation: 0,
    surviving_calculation: 50,
    striving_calculation: 65,
    flourishing_calculation: 85,
  })
  const [showErrors, setShowErrors] = useState(false)
  const [showValidationError, setShowValidationError] = useState(false)
  const [questionList, setQuestionList] = useState([])
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [systemQuestionList, setSystemQuestionList] = useState([])
  const [duplicateSlugError, setDuplicateSlugError] = useState(false)
  const { addToast } = useToasts()

  const DEFAULT_QUESTION = {
    label: "",
    question_label: `question_${questionList.length + 1}`,
    status: "active",
    slider_label_one: "",
    slider_label_two: "",
    target_score: "",
    target_score_percent: "",
    question_type: ANSWER_TYPES[0],
    answers: DEFAULT_LIKERT_ANSWERS,
    key: Date.now().toString(),
  }

  const checkQuestionLabelValidation = () => {
    const result = questionList.map(a => a.question_label)
    setDuplicateSlugError(_.uniq(result).length !== result.length)
    const hasErrors = _.uniq(result).length !== result.length || questionList.length < 1 || !form.name
    setReadyToSubmit(!hasErrors)
  }

  const fetchSystemQuestions = () => {
    getSystemQuestions(baseUrl, currentUser)
      .then(res => {
        setSystemQuestionList(res?.response?.json?.system_question_list)
        return null
      })
  }

  const onAddQuestion = () => {
    if (questionList.length < 15) {
      setQuestionList([...questionList, DEFAULT_QUESTION])
    }
  }

  const onAddSystemQuestion = () => {
    if (questionList.length < 15 && systemQuestionList.length) {
      const FIRST_SYSTEM_QUESTION = systemQuestionList[0]
      setQuestionList([...questionList, FIRST_SYSTEM_QUESTION])
    }
  }

  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    setShowErrors(true)

    if (readyToSubmit) {
      createTemplate(baseUrl, { ...form, question_list: questionList }, currentUser)
        .then(res => {
          if (res?.response?.json?.template) {
            addToast(`Template created successfully`, { appearance: "success" })
            window.location.href = `/backend/templates/${res.response.json.template.id}`
          }
        })
        .catch(err => {
          addToast(`Something went wrong: ${err?.request?.responseText}`, { appearance: "error" })
          setShowValidationError(true)
        })
    }
  }

  useEffect(() => {
    checkQuestionLabelValidation()
  }, [form, questionList])

  useEffect(() => {
    fetchSystemQuestions()
  }, [])

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="form-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/backend/templates">Template</Link>
              </li>
              <li aria-current="page" className="breadcrumb-item active">Add New</li>
            </ol>
          </nav>
          <div className="card-section">
            <div className="card custom-qns-header">
              <div className="card-body">
                <div className="form-group">
                  <label>Template Name</label>
                  <input
                    className="form-control form-control-xl"
                    name="name"
                    onChange={onChange}
                    placeholder="Template Name"
                    type="text"
                    value={form.name} />
                </div>
                <ScoringSplit
                  setForm={setForm}
                  form={form} />
              </div>
            </div>
            <TemplateQuestionForm
              onAddQuestion={onAddQuestion}
              onAddSystemQuestion={onAddSystemQuestion}
              questionList={questionList}
              setQuestionList={setQuestionList}
              systemQuestionList={systemQuestionList} />
          </div>
          <div className="form-container-cta fixed-bottom">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary rounded-pill"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                Add
              </button>
              <div className="dropdown-menu">
                <button
                  className="btn btn-primary mb-4"
                  onClick={onAddQuestion}
                  type="button">
                  Custom Question
                </button>
                <button
                  className="btn btn-primary mb-4"
                  onClick={onAddSystemQuestion}
                  type="button">
                  System Question
                </button>
              </div>
            </div>
            <Link className="btn btn-outline-main" to="/backend/templates">Cancel</Link>
            <button className="btn btn-main" onClick={onSubmit} type="submit">Create</button>
            {showErrors && !form.name && <div className="err-msg text-danger">Title is blank</div>}
            {showValidationError && <div className="err-msg text-danger">Title is taken try another</div>}
            {duplicateSlugError && <div className="err-msg text-danger">Duplicate Dashboard Label Detected.</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateTemplatePage
