import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { OVERVIEW_PAGE_CONTENT } from "../../Dashboard/constants"

const Header = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
`

const PulseCheckEnd = ({ currentUser }) => {
  // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_pulse_id) {
    window.location.href = "/overview"
  }

  return (
    <>
      <Header>
        <nav className="navbar navbar-light bg-white">
          <Link className="navbar-brand" to="/">
            <img src={OVERVIEW_PAGE_CONTENT.pulse_check.logoSrc} width={64} height={64} />
            <img src="/assets/pulse-logo-text-164ef3ebeff4935c27394dc225e7e30cf7283bb10ad2dc6b2ff14e33b2940c6e.svg" width={100} height={36} />
          </Link>
        </nav>
        <div className="progress survey-progress"><div aria-valuemax={100} aria-valuemin={0} aria-valuenow={100} style={{ width: `100%` }} className="progress-bar" role="progressbar" /></div>
      </Header>
      <div className="container">
        <h1>Thank you!</h1>
        <p>Thank you for completing the Pulse Check. Click on the &quot;Back to Dashboard&quot; button to see a breakdown of your scores.</p>
        <p>Don&apos;t forget that the Pulse Check can be completed multiple times. Take a Pulse Check at least once each week to help track your progress throughout the program.</p>
        {/* <hr /> */}
        <Link className="btn btn-outline-main my-5" to="/pulse-check-dashboard">Back to Dashboard</Link>
      </div>
    </>
  )
}

export default PulseCheckEnd
