import React from "react"
import { Link } from "react-router-dom"
import { DashboardBody } from "../styles"

const AboutBurnoutProof = () => (
  <DashboardBody>
    <div className="container dashboard py-5">
      <div className="d-flex align-items-center my-4">
        <Link className="btn btn-outline-main" to="/burnout-proof-dashboard">Back</Link>
      </div>
      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <div className="pre-text">BPC</div>
              <h1 className="mb-4">What is the Burnout Proof Calculator?</h1>
              <p>The Burnout Proof Calculator (BPC) is a science-based assessment measuring your experience of common burnout symptoms in the past month. Burnout is characterised by symptoms of exhaustion, disconnection or alienation from work and reduced performance and productivity.</p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-xl-9">
              <h4>Understanding Burnout</h4>
              <p>At its core, burnout occurs when the demands of a job outweigh a person&apos;s ability to cope with the pressure and/or requirements.</p>
              <p>In 2019 the World Health Organization updated the 11th Revision of the International Classification of Diseases (ICD-11) to include burnout as an occupational phenomenon, stating: Burnout is a syndrome conceptualised as resulting from chronic workplace stress that has not been successfully managed. It is characterised by three dimensions:</p>
              <ol>
                <li>Feelings of physical and emotional energy depletion and/or chronic exhaustion</li>
                <li>Increased mental distance from one&apos;s job, or feelings of negativism or cynicism related to one&apos;s job; and</li>
                <li>
                  Reduced personal accomplishment and professional inefficacy.
                  <sup>1</sup>
                </li>
              </ol>
              <p>
                The term &apos;burnout&apos; was first coined in the 1970s by American psychologist Herbert Freudenberger
                <sup>2</sup>
                {" "}
                who used it to describe the consequences of severe stress and high ideals in &apos;helping&apos; professions. Doctors and nurses who sacrifice themselves for others would often end up being burned out - exhausted, listless, and unable to cope. Nowadays, &apos;burnout&apos; is not only used to describe the dark side of self-sacrifice but is much more prevalent across multiple industries and is on its way to becoming a global phenomenon.
              </p>

              <h4 className="mt-5">Individual Burnout</h4>
              <p>The Burnout Proof Calculator focuses on Individual Burnout. Key characteristics are exhaustion, disconnection or alienation from work, and reduced performance.</p>
              <p>Lockdowns and disruption from COVID combined with the perpetual connectivity of technology, have disrupted the rhythms we work and live to. This means it is harder to get the downtime and relief from the pressure and pace of our lives. Many people are working more, having less time off, and are physically isolated from their colleagues. Additional anxiety from the pandemic and the subsequent agitation to our personal and working lives has, in many ways, created a perfect storm for burnout.</p>

              <h4 className="mt-5">Organisational Burnout</h4>
              <p className="mb-5">Organisational Burnout is cultural and occurs when an organisation is in a paralysed state which it can no longer positively change on its own. This is multifactorial with common triggers including constant change and transformation, reorganisation, workforce inadequacies or increasing complexities and difficulties dealing with them. Organisational culture is the shared values, attitudes and practices that characterise an organisation. It includes behaviours that are accepted, and not accepted.</p>

              <hr />

              <small>
                <sup>1 </sup>
                World Health Organisation. Burnout, an international phenomena. 2019.
              </small>
              <p>
                <small>
                  <sup>2 </sup>
                  Heineeman L., Heineeman V. Burnout Research: Emergence and Scientific Investigation of a Contested Diagnosis. Sage Open 2017:1-12.
                </small>
              </p>

            </div>
          </div>

        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">The Burnout Continuum</h2>
              <p>Burnout is not a dichotomous phenomenon, meaning it can exist along a continuum from minor symptoms like less energy and occasionally being under stress to suffering from physical and emotional exhaustion, which if not addressed can result in feeling completely burned out. Likewise, physical fatigue and/or exhaustion can trigger emotional exhaustion, and mental fatigue can result in physiological expression, such as depleted energy, poor quality sleep, raised blood sugar, raised heart rate and blood pressure and expression of known stress-related diseases. The model below highlights the Burnout Risk categories.</p>

              <div className="row mt-5">
                <img src="/assets/pdf/BPC_description-6429673578091d568f4620047aaa6fecca5c8d673f0791bd5b26a2fcc6eb644d.jpg" alt="12 Essential Mental Skills chart" width="90%" />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-5">Understanding Your Results</h2>
              <h4>Your BPC Score</h4>

              <p>This assessment has been designed to measure your experience of burnout symptoms over the last month. Your confidential, personalised report provides an overall index ranging from 0-100. Scores are calculated into 4 categories with scores:</p>
              <li>&gt; 85 considered Extreme Risk;</li>
              <li>66-85 High Risk;</li>
              <li>40-65% Moderate Risk;</li>
              <li>
                {"<"}
                {" "}
                40% Low Risk of burnout.
              </li>

              <p className="mt-2">As you complete more than one BPC assessment, a trend of your recent BPC scores is shown on your calculator dashboard.</p>

              <div className="mt-5">
                <h4>Breakdown of the BPC Score</h4>
                <p>The Burnout Proof Calculator score is further broken down into four categories of symptoms:</p>
                <ol>
                  <li>
                    <b>Exhaustion:</b>
                    {" "}
                    a state of chronic emotional and physical depletion.
                  </li>
                  <li>
                    <b>Occupational Cynicism (Depersonalisation):</b>
                    {" "}
                    is a state of indifference or a loss of interest in one&apos;s work and not seeing it as meaningful.
                  </li>
                  <li>
                    <b>Low Personal Accomplishment:</b>
                    {" "}
                    feelings of not performing adequately.
                  </li>
                  <li>
                    <b>Strained (or Difficulty with) Social Relationships:</b>
                    {" "}
                    connections that exist between people that are perceived to have personal meaning.
                  </li>
                </ol>
                <p>Your score for each category is displayed in the dashboard, followed by your overall perception of the level of burnout symptoms, which was the final question in the survey.</p>
              </div>
              <div className="mt-5">
                <h4>Your Recommendations</h4>
                <p>A set of recommendations is tailored for you based on your overall BPC score.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">Accessing Your Latest Report</h2>
              <p>You can download a PDF report of your latest BPC assessment at any time by clicking on the &ldquo;Download Latest Report&rdquo; button. This report contains your latest results, information on BPC and your recommendations in one easy-to-read document. You are also sent a copy of this report via email each time you complete an assessment.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardBody>
)

export default AboutBurnoutProof
