/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"

const LoginPage = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  const handleLogin = event => {
    event.preventDefault()
    const path = "/users/sign_in"
    const params = {
      user: {
        email,
        password,
      }
    }
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
    }

    axios.post(path, params, headers)
      .then(response => {
        if (_.get(response, "status") === 200) window.location.href = response.data.location
        else setError(_.get(response, "data.error"))
      })
      .catch(error => setError(_.get(error, "response.data.error")))
  }

  const onChangeEmail = e => {
    setEmail(e.target.value)
  }

  const onChangePw = e => {
    setPassword(e.target.value)
  }

  return (
    <div className="session-box">
      <h2>Log in</h2>
      <p>Welcome to iStrive. Like having a Performance Coach in your pocket.</p>
      <form className="new_user" id="new_user" action="/users/sign_in" acceptCharset="UTF-8" method="post">
        <div className="form-group">
          <input
            autoFocus
            autoComplete="email"
            className="form-control"
            type="email"
            name="email"
            id="user_email"
            onChange={onChangeEmail}
            placeholder="Email" />
        </div>
        <div className="form-group">
          <input
            autoComplete="current-password"
            className="form-control"
            type="password"
            name="password"
            id="user_password"
            onChange={onChangePw}
            placeholder="Password" />
        </div>
        {error && <div className="err-msg text-danger">{error}</div>}
        <Link to="/forgot-password" className="text-main forgot-password">Forgot Password</Link>
        <div className="form-group">
          <button
            type="submit"
            className="btn btn-main"
            onClick={handleLogin}
            disabled={!email || !password}>
            Log In
          </button>
        </div>
      </form>
      Don&#39;t have an account?
      <Link to="/signup" className="text-main">&nbsp;Sign Up</Link>
    </div>
  )
}

export default LoginPage
