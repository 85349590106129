/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { getCurrentUser, getBaseUrl } from "../reducers"
import BackendHeader from "./Backend/BackendHeader"

const Header = ({ currentUser, location: { pathname }, ...rest }) => {
  useEffect(() => {
    let newClass = "user"
    const oldClass = document.body.className
    if (["/login", "/signup"].includes(pathname)) {
      newClass = "session"
    } else if (pathname.includes("survey") || pathname.includes("pulse-check/") || pathname.includes("mental-skills/") || pathname.includes("matchfit/") || pathname.includes("burnout-proof/") || pathname.includes("livelife/")) {
      newClass = "survey"
    } else if (pathname.includes("backend")) {
      newClass = "backend"
      if (currentUser?.role !== "admin") {
        window.location.href = "/"
      }
    }
    document.body.className = newClass
    if (oldClass !== newClass) {
      window.location.reload()
    }
  }, [pathname])

  if (window.location.pathname === "/verify-email") {
    return (
      <div className="navbar navbar-light bg-white">
        <Link
          className="navbar-brand"
          to="#">
          <img src="/assets/ss-logo-8839d9e24fca0b324b5083eb6616dc26fa5bd8dd92c8072bbe29439db5ecb553.svg" className="ss-logo" />
        </Link>
      </div>
    )
  }

  if (window.location.pathname !== "/verify-email" && currentUser && !currentUser?.confirmed_at) {
    window.location.href = "/verify-email"
  }

  // const currentPage = ["", "overview", "settings", "enrol"].includes(pathname.split("/")[1]) ? "overview" : "10DWC"
  let currentPage = "overview"
  if (pathname.includes("ten-dwc")) {
    currentPage = "10DWC"
  } else if (pathname.includes("pulse-check")) {
    currentPage = "Pulse Check"
  } else if (pathname.includes("mental-skills")) {
    currentPage = "Mental Skills"
  } else if (pathname.includes("burnout-proof")) {
    currentPage = "Burnout Proof"
  } else if (pathname.includes("matchfit")) {
    // Not sure if this will break something else
    // } else if (pathname.includes("matchfit/")) {
    currentPage = "Matchfit"
  } else if (pathname.includes("livelife")) {
    currentPage = "LiveLife"
  }

  if (currentUser?.role === "admin" || process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.log(currentUser, "current user", rest)
  }

  if (["/login", "/signup"].includes(pathname)) {
    return null
  }
  if (pathname.includes("survey") || pathname.includes("pulse-check/") || pathname.includes("mental-skills/") || pathname.includes("matchfit/") || pathname.includes("burnout-proof/") || pathname.includes("livelife/")) {
    return null
  }
  if (pathname.includes("backend")) {
    return <BackendHeader currentUser={currentUser} />
  }

  document.getElementById("react-app").style.display = "flex"
  document.getElementById("react-app").style.flexDirection = "column"
  return (
    <div className="navbar navbar-light bg-white">
      <Link
        className="navbar-brand"
        to="/">
        <img src="/assets/ss-logo-8839d9e24fca0b324b5083eb6616dc26fa5bd8dd92c8072bbe29439db5ecb553.svg" className="ss-logo" />
      </Link>
      {currentUser?.firstname && (
        <>
          <div className="calc-toggle-container">
            <div className="dropdown calc-toggle">
              <a
                aria-expanded="true"
                aria-haspopup="true"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                id="navbarDropdown"
                role="button">
                <span>{currentPage}</span>
              </a>
              <div aria-labelledby="navbarDropdown" className="dropdown-menu dropdown-menu-right">
                <Link
                  className="dropdown-item"
                  to="/overview">
                  Overview
                </Link>
                {currentUser?.active_ten_dwc_id && (
                  <>
                    <Link
                      className="dropdown-item"
                      to="/ten-dwc-dashboard">
                      10DWC
                    </Link>
                  </>
                )}
                {currentUser?.active_pulse_id && (
                  <>
                    <Link
                      className="dropdown-item"
                      to="/pulse-check-dashboard">
                      Pulse Check
                    </Link>
                  </>
                )}
                {currentUser?.active_mental_skills_id && (
                  <>
                    <Link
                      className="dropdown-item"
                      to="/mental-skills-dashboard">
                      Mental Skills
                    </Link>
                  </>
                )}
                {currentUser?.active_matchfit_id && (
                  <>
                    <Link
                      className="dropdown-item"
                      to="/matchfit-dashboard">
                      MatchFit
                    </Link>
                  </>
                )}
                {currentUser?.active_burnout_proof_id && (
                  <>
                    <Link
                      className="dropdown-item"
                      to="/burnout-proof-dashboard">
                      Burnout Proof
                    </Link>
                  </>
                )}
                {currentUser?.active_live_life_id && (
                  <>
                    <Link
                      className="dropdown-item"
                      to="/livelife-dashboard">
                      LiveLife
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="dropdown">
            <a
              aria-expanded="false"
              aria-haspopup="true"
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              href="#"
              id="navbarDropdown"
              role="button">
              <span>{currentUser.firstname}</span>
            </a>
            <div aria-labelledby="navbarDropdown" className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to="/">
                Home
              </Link>
              <Link
                className="dropdown-item"
                to="/settings">
                Settings
              </Link>
              <Link
                className="dropdown-item"
                to="/enrol">
                Enrol
              </Link>
              <a className="dropdown-item" href="https://www.strivestronger.com/pages/terms">Terms of Use</a>
              <a className="dropdown-item" href="https://www.strivestronger.com/pages/privacy-policy"> Privacy Policy</a>
              {currentUser?.role === "admin" && (
                <>
                  <div className="dropdown-divider"></div>
                  <Link
                    className="dropdown-item"
                    to="/backend">
                    Admin View
                  </Link>
                </>
              )}
              <div className="dropdown-divider"></div>
              <Link
                className="dropdown-item"
                to="/logout"
                onClick={() => { window.location.href = "/logout" }}>
                Log out
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  baseUrl: getBaseUrl(state)
})

export default withRouter(connect(mapStateToProps)(Header))