import React, { useState, useMemo } from "react"
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { iconPaths } from "../../../util/iconPaths"
import ProgramTableCell from "./Table/ProgramTableCell"
// import CheckboxCell from "./Table/CheckboxCell"

const DashboardTable = ({ data }) => {
  const [sorting, setSorting] = useState([])
  const [rowSelection, setRowSelection] = useState({})

  const columns = useMemo(
    () => [
      // REQUIRED FOR NEXT TICKET
      // {
      //   id: "select",
      //   header: ({ table }) => (
      //     <CheckboxCell
      //       {...{
      //         checked: table.getIsAllRowsSelected(),
      //         indeterminate: table.getIsSomeRowsSelected(),
      //         onChange: table.getToggleAllRowsSelectedHandler(),
      //       }} />
      //   ),
      //   cell: ({ row }) => (
      //     <div className="px-1">
      //       <CheckboxCell
      //         {...{
      //           checked: row.getIsSelected(),
      //           indeterminate: row.getIsSomeSelected(),
      //           onChange: row.getToggleSelectedHandler(),
      //         }} />
      //     </div>
      //   ),
      // },
      {
        id: "organisation_id",
        accessorKey: "organisation_name",
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>Organisation</span>,
      },
      {
        accessorFn: row => row.unit_name || row.name || "",
        id: "unit_id",
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>Unit</span>,
      },
      {
        accessorFn: row => (row.unique_id ? row.name : ""),
        id: "id",
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>Program</span>,
      },
      {
        accessorFn: row => (row.unique_id || ""),
        id: "unique_id",
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>Unique Code</span>,
      },
      {
        accessorFn: row => row.status,
        id: "status",
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>Status</span>,
      },
      {
        accessorFn: row => row.contact_name || row.unit_contact_name,
        id: "contact",
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>Contact</span>,
      },
      {
        accessorFn: row => row.contact_email || row.unit_contact_email,
        id: "contact_email",
        cell: info => info.getValue(),
        footer: props => props.column.id,
        header: () => <span>Contact Email</span>,
      },
      {
        accessorFn: row => row.created_on,
        id: "created_on",
        cell: info => moment.unix(info.getValue()).format("DD MMM YYYY"),
        footer: props => props.column.id,
        header: () => <span>Created Date</span>,
      },
      {
        accessorFn: row => (row.last_enrolment_date || ""),
        id: "last_enrolment_date",
        cell: info => (info.getValue() ? moment.unix(info.getValue()).format("DD MMM YYYY") : ""),
        footer: props => props.column.id,
        header: () => <span>Last Enrolment Date</span>,
      },
    ],
    []
  )

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
      sorting,
    },
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  })

  return (
    <table className="table">
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                  <div
                    {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <img src={iconPaths.upIcon} style={{ marginLeft: 10, width: "15px" }} />,
                      desc: <img src={iconPaths.downIcon} style={{ marginLeft: 10, width: "15px" }} />,
                    }[header.column.getIsSorted()] || null}
                  </div>
                )}
              </th>
            ))}
          </tr>
          ))}
      </thead>
      <tbody>
        {table
            .getRowModel()
            .rows
            .map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <ProgramTableCell cell={cell} flexRender={flexRender} key={cell.id} />
                ))}
              </tr>
            ))}
      </tbody>
    </table>
  )
}

export default DashboardTable
