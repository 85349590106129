
export const ANSWER_TYPES = [
  "Likert - Frequency - Positive",
  "Likert - Frequency - Negative",
  "Likert - Intensity - Positive",
  "Likert - Intensity - Negative",
  "custom",
  "slider_10",
  "slider_10_reversed"
]

export const DEFAULT_LIKERT_ANSWERS = () => [
    "Always",
    "Very Frequently",
    "Sometimes",
    "Rarely",
    "Very Rarely",
    "Never"
  ].map((answer, index) => (
    {
      label: answer,
      sorting_index: index,
      status: "active",
      net_result: (5 - index) * 20,
    }
  ))
