import React from "react"
import { InputQuestion } from "../../../Common"
import Answer from "../../Answer"

const SimplifiedQuestion = ({
  answers,
  compulsory,
  dependentOn,
  descriptor,
  form,
  label,
  onChangeHandler,
  onFreeTextChangeHandler,
  onInputChangeHandler,
  questionSlug,
  questionType,
  selectedAnswer,
  sortingIndex
}) => {
  const questionElement = () => {
    if (["input", "multiple_input"].includes(questionType)) {
      return (
        <div className="q-group no-scroll">
          <p>{label}</p>
          <div className="small">
            <i>{descriptor}</i>
          </div>
          <div className="mt-4">
            <div className="form-group row">
              <label className="col-5 col-md-3 col-form-label strong">{answers[0]?.label}</label>
              <div className="col-6 col-sm-5">
                <input
                  className="form-control"
                  id={answers[0]?.id}
                  name={questionSlug}
                  onChange={onInputChangeHandler}
                  pattern="/d+"
                  step="1"
                  min={0}
                  onWheel={e => e.target.blur()}
                  type="number"
                  inputMode="numeric"
                  value={selectedAnswer?.number_field || ""} />
              </div>
            </div>
          </div>
        </div>
      )
    } if (questionType === "table_input") {
      return (
        <div className="q-group no-scroll">
          <p>{label}</p>
          {/* <div className="small">
            <i>{descriptor}</i>
          </div> */}
          <div className="mt-4">
            <div className="form-group row">
              <label className="col-5 col-md-3 col-form-label strong">{answers[0]?.label}</label>
              <div className="col-6 col-sm-5">
                <input
                  className="form-control"
                  id={answers[0]?.id}
                  name={questionSlug}
                  onChange={onInputChangeHandler}
                  pattern="/d+"
                  step="1"
                  min={0}
                  onWheel={e => e.target.blur()}
                  type="number"
                  inputMode="numeric"
                  value={selectedAnswer?.number_field || ""} />
              </div>
            </div>
          </div>
        </div>
      )
    } if (questionType.includes("time_input")) {
      return (
        <InputQuestion
          answer={answers[0]}
          descriptor={descriptor}
          formIndex={questionSlug}
          label={label}
          onChangeHandler={onInputChangeHandler}
          questionType={questionType}
          selectedAnswer={selectedAnswer} />
      )
    }
    if (["custom", "long_custom"].includes(questionType)) {
      return (
        <div>
          <p>{label}</p>
          {descriptor && (
            <div className="mb-3 small">
              <i>{descriptor}</i>
            </div>
          )}
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            {answers && answers.map(answer => (
              <Answer
                answer={answer}
                onChangeHandler={onChangeHandler}
                key={answer.id}
                selectedAnswer={selectedAnswer}
                formIndex={questionSlug} />
            ))}
          </div>
        </div>
      )
    }
    if (questionSlug === "birthday") {
      return (
        <div className="col-6 col-sm-5">
          <p>{label}</p>
          <input
            className="form-control"
            id={answers[0].id}
            name={questionSlug}
            onChange={onFreeTextChangeHandler}
            pattern="/d+"
            step="1"
            min={0}
            type="date"
            value={_.get(form,
            `${questionSlug}.free_text_field`) || ""} />
        </div>
      )
    }
    if (questionSlug === "sex") {
      return (
        <div className="col-6 col-sm-5">
          <p className="mt-5">{label}</p>
          <select
            className="form-control"
            id={answers[0].id}
            name={questionSlug}
            onChange={onFreeTextChangeHandler}
            value={_.get(form,
            `${questionSlug}.free_text_field`) || ""}>
            <option disabled value="">Select</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
          </select>
        </div>
      )
    }
    return (
      <div>weird q type detected</div>
    )
  }

  if (dependentOn && !(_.toArray(form).map(x => x?.answer_slug)).includes(dependentOn)) {
    return null
  }

  return (
    <div className="m-5">
      <small>{sortingIndex}</small>
      {compulsory && <small>COMPULSORY</small>}
      {/* <small>{JSON.stringify(selectedAnswer)}</small> */}
      {questionElement()}
    </div>
  )
}

export default SimplifiedQuestion