import React, { useEffect, useState, useMemo } from "react"
import { Link } from "react-router-dom"
import { getPrograms } from "../../requesters/Backend/ProgramRequester"
import { getUnitList } from "../../requesters/Backend/UnitRequester"
import DashboardTable from "./Programs/DashboardTable"

const BackendDashboard = ({ baseUrl, currentUser }) => {
  const [activeList, setActiveList] = useState([])
  const [inactiveList, setInactiveList] = useState([])
  const [unitList, setUnitList] = useState([])
  const [showActive, setShowActive] = useState(true)
  const [showInactive, setShowInactive] = useState(false)
  const [query, setQuery] = useState("")

  const fetchActivePrograms = () => {
    getPrograms(baseUrl, currentUser, "active").then(res => {
      const json = res?.response?.json
      if (json) {
        setActiveList(json.programs)
      }
      return null
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  const fetchInactivePrograms = () => {
    getPrograms(baseUrl, currentUser, "inactive").then(res => {
      const json = res?.response?.json
      if (json) {
        setInactiveList(json.programs)
      }
      return null
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  const fetchUnits = () => {
    getUnitList(baseUrl, currentUser).then(res => {
      setUnitList(res.response.json?.units || [])
      return null
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  const handleStatus = event => {
    switch (event.target.value) {
      case "active":
        setShowActive(true)
        setShowInactive(false)
        break
      case "inactive":
        setShowActive(false)
        setShowInactive(true)
        break
      default:
        setShowActive(true)
        setShowInactive(true)
        break
    }
  }

  const handleSearch = event => setQuery(event.target.value.toLowerCase().trim())

  const filteredActiveList = useMemo(() => activeList.filter(program => (query.length > 0
                              ? `${program.organisation_name}${program.unit_name}${program.name}${program.unique_id}`.toLowerCase().includes(query)
                              : true)), [query, activeList])

  const filteredInactiveList = useMemo(() => inactiveList.filter(program => (query.length > 0
                              ? `${program.organisation_name}${program.unit_name}${program.name}${program.unique_id}`.toLowerCase().includes(query)
                              : true)), [query, inactiveList])

  const filteredUnitList = useMemo(() => unitList.filter(unit => (query.length > 0
                              ? `${unit.organisation_name}${unit.name}`.toLowerCase().includes(query)
                              : true)), [query, unitList])

  useEffect(() => {
    fetchUnits()
    fetchActivePrograms()
    fetchInactivePrograms()
  }, [])

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Organisation & Programs</h2>
            <span>
              <Link to="/backend/create-unit" className="btn btn-outline-main btn-sm">+ Add New</Link>
            </span>
          </div>
        </div>
        <div className="table-filters d-inline-flex">
          <input
            type="search"
            className="form-control"
            placeholder="Search Organisation / Unit"
            onChange={handleSearch} />
          <select className="form-control" onChange={handleStatus} defaultValue="active">
            <option value="all">Status: All</option>
            <option value="active">Status: Active</option>
            <option value="inactive">Status: Inactive</option>
          </select>
        </div>
        <div className="table-responsive">
          <DashboardTable data={[
            ...(showActive ? filteredActiveList : []),
            ...(showActive ? filteredUnitList : []),
            ...(showInactive ? filteredInactiveList : [])]} />
        </div>
      </div>
    </div>
  )
}

export default BackendDashboard
