import _ from "lodash"
import React, { useEffect, useState } from "react"
import { checkIfDuplicatesInArray } from "../../../../util"
import MatchfitQuestionElement from "../MatchfitQuestionElement"

const SittingTimeFormSection = ({
  currentSection,
  form,
  onInputChangeHandler,
  questionSet,
  setCurrentSection,
  title,
}) => {
  // const [hasMissingValues, setHasMissingValues] = useState(false)
  const [invalidInput, setInvalidInput] = useState(true)
  const [userInputDetected, setUserInputDetected] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const {
    input_question_slugs: inputQuestionSlugs,
    questions,
  } = questionSet

  const onNextStep = () => {
    if (userInputDetected && !invalidInput) {
      setCurrentSection(currentSection + 1)
    } else if (showWarning && !invalidInput) {
      setCurrentSection(currentSection + 1)
    } else {
      setShowWarning(true)
    }
  }

  const skipWholeSection = questions.map(question => {
    const dependentOn = question.dependent_on
    return dependentOn && !(_.toArray(form).map(x => x?.answer_slug)).includes(dependentOn)
  }).every(x => x === true)

  const onPreviousStep = () => {
    setCurrentSection(currentSection - 1)
  }

  useEffect(() => {
    if (currentSection === questionSet.page_index) {
      const daysOne = _.get(form, "monitor_vigorous_activity_days.number_field")
      const daysTwo = _.get(form, "monitor_moderate_activity_days.number_field")

      const daysThree = _.get(form, "vigorous_days.number_field")
      const minutesOne = _.get(form, "vigorous_minutes.number_field")

      const daysFour = _.get(form, "moderate_days.number_field")
      const minutesTwo = _.get(form, "moderate_minutes.number_field")

      const daysFive = _.get(form, "walking_days.number_field")
      const minutesThree = _.get(form, "walking_minutes.number_field")

      const daysSix = _.get(form, "resistance_days.number_field")
      const minutesFour = _.get(form, "resistance_minutes.number_field")

      const daysSeven = _.get(form, "flexibility_days.number_field")
      const minutesFive = _.get(form, "flexibility_minutes.number_field")

      const daysEight = _.get(form, "monitor_resistance_days.number_field")
      const minutesSix = _.get(form, "monitor_resistance_minutes.number_field")

      const daysNine = _.get(form, "monitor_flexibility_days.number_field")
      const minutesSeven = _.get(form, "monitor_flexibility_minutes.number_field")

      const isInvalidInput = daysOne > 7
        || daysTwo > 7
        || daysThree > 7
        || minutesOne > 1440
        || daysFour > 7
        || minutesTwo > 1440
        || daysFive > 7
        || minutesThree > 1440
        || daysSix > 7
        || minutesFour > 1440
        || daysSeven > 7
        || minutesFive > 1440
        || daysEight > 7
        || minutesSix > 1440
        || daysNine > 7
        || minutesSeven > 1440
      setInvalidInput(isInvalidInput)

      // If any hours questions on this page are answered, then Warning is skipped
      if (checkIfDuplicatesInArray([...Object.keys(form), ...inputQuestionSlugs])) {
        setUserInputDetected(true)
      } else {
        setUserInputDetected(false)
      }
    } else {
      setShowWarning(false)
    }
  }, [currentSection, form])

  const handleInputChange = e => {
    setUserInputDetected(true)
    onInputChangeHandler(e)
  }

  if (currentSection !== questionSet.page_index) return null

  if (skipWholeSection) {
    setCurrentSection(currentSection + 1)
    return null
  }

  return (
    <>
      <h1>{title}</h1>
      <hr />
      {questions.map(question => (
        <MatchfitQuestionElement
          form={form}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          key={`question-${question.id}`}
          onInputChangeHandler={handleInputChange}
          integerOnly
          question={question}
          selectedAnswer={form[question.question_slug]}
          surveyType="matchfit" />
      ))}
      <div>
        {showWarning && !userInputDetected && (
          <div className="bottom-cta mb-5 pb-3 d-flex justify-content-center bg-warning">Note: You have not answered any questions on this page. Please double check before proceeding.</div>
        )}
        {showWarning && invalidInput && (
          <div className="bottom-cta mb-5 pb-3 d-flex justify-content-center bg-warning">Note: Please enter a maximum of 7 days or 1440 minutes.</div>
        )}
        <div className="bottom-cta d-flex justify-content-between">
          <button
            className="btn btn-light"
            type="button"
            onClick={onPreviousStep}>
            Previous
          </button>
          <button
            className="btn btn-main"
            type="button"
            onClick={onNextStep}>
            Next
          </button>
        </div>
      </div>
    </>
  )
}

export default SittingTimeFormSection