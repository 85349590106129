import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getMentalSkillsDashboardResults } from "../../../requesters/CalculatorResultsRequester"
import { getWellbeingScore } from "../constants"
import { SUBCATEGORIES } from "./constants"
import { DashboardBody } from "../styles"


const CategoryDetailPage = ({ baseUrl, currentUser, match }) => {
const sectionNameSlug = match?.params?.categoryId.replace("-", "_")

const [inflight, setInflight] = useState(true)
const [surveyResults, setSurveyResults] = useState([])
const [currentCategoryResult, setCurrentCategoryResult] = useState({})
const [prevSectionNetResult, setPrevSectionNetResult] = useState(0)
const [currentCategoryName, setCurrentCategoryName] = useState("")
const [subcategories, setSubcategories] = useState([])

const fetchResults = () => {
  getMentalSkillsDashboardResults(baseUrl, currentUser).then(res => {
    if (res.response.json?.calculator_results) {
      const recentResults = res.response.json?.calculator_results
      const currentSection = recentResults[0]?.section_results?.filter(sr => sr.section_name === sectionNameSlug)[0]
      setSurveyResults(res.response.json?.calculator_results)
      setCurrentCategoryResult(currentSection)
      setPrevSectionNetResult(recentResults[1]?.section_results?.filter(sr => sr.section_name === sectionNameSlug)[0].section_net_result)
      setCurrentCategoryName(currentSection?.section_name_titlized === "Self Discovery" ? "Self-Discovery" : currentSection?.section_name_titlized)
      setSubcategories(recentResults[0]?.subsection_results?.filter(sr => sr.section === sectionNameSlug).sort((a, b) => b.subsection_net_result - a.subsection_net_result))
    }
    setInflight(false)
    return null
  }).catch(err => {
    setInflight(false)
    // eslint-disable-next-line no-console
    console.log(err)
  })
}

useEffect(() => {
  fetchResults()
}, [])

if (inflight) {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

  return (
    <DashboardBody className="content">
      <div className="container dashboard" id="ditem">
        <div className="d-flex align-items-center justify-content-between py-4">
          <Link className="btn btn-outline-main mr-2" to="/mental-skills-dashboard">Back</Link>
          <div className="start-survey">
            {!!surveyResults[0]?.completed_at && <div className="small">{`Last completed on ${surveyResults[0]?.completed_at}`}</div>}
          </div>
        </div>
        <div className="dash-row card">
          <div className="card-body">
            <div className="pre-text">MSC</div>
            <div className="row">
              <div className="col-lg-7">
                <div className="ditem-header">
                  <h1>{currentCategoryName}</h1>
                  <p className="mb-0">{`${currentCategoryName} skills includes the following subcategories:`}</p>
                  <ol>
                    {!!subcategories.length && subcategories.map(sub => (
                      <li key={sub.subsection_name}>
                        <strong>{SUBCATEGORIES[sub.subsection_name].heading}</strong>
                        {SUBCATEGORIES[sub.subsection_name].description}
                      </li>
                    ))}
                  </ol>
                  <p>In each category you also answered a question which give an indication of how effective you thought your skills were in that area.</p>
                  <p>Both your Category Score and you Effectiveness Score are shown below.</p>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="d-flex align-items-center justify-content-lg-end">
                  <div className="ditem-score text-center">
                    <div className="current-score">{currentCategoryResult?.section_net_result}</div>
                    <h6>{getWellbeingScore(currentCategoryResult?.section_net_result)}</h6>
                    {prevSectionNetResult && (
                      <div className="small">{`Was ${prevSectionNetResult}`}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <ul className="legend list-inline">
              <li className="list-inline-item">Effectiveness Score</li>
              <li className="list-inline-item">Skill Score</li>
            </ul>
            <div className="ditem-grid-container global-wellbeing-grid mental-skills">
              <div id="ditem-grid" />
              <div className="ditem-questions">
                {!!subcategories.length && subcategories.map(sub => (
                  <div className="row ditem-questions-item no-gutters" key={sub.subsection_name}>
                    <div className="col-lg-6 col-xl-5 dimension-question">
                      <div className="mr-4 d-inline-flex align-items-center">
                        <h6 className="mb-0">{SUBCATEGORIES[sub.subsection_name].heading}</h6>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 dimension-graph">
                      <div className="progress" style={{ height: "1px", display: "grid" }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${sub.subsection_net_result}%` }} />
                        <div className="progress-bar" role="progressbar" style={{ width: `${sub.effective_value}%` }} />
                      </div>
                    </div>
                  </div>
              ))}
              </div>
            </div>
            <ul className="nav nav-fill ditem-weight mt-0">
              <li className="nav-item">0</li>
              <li className="nav-item">25</li>
              <li className="nav-item">50</li>
              <li className="nav-item">75</li>
              <li className="nav-item">100</li>
            </ul>
          </div>
        </div>
      </div>
    </DashboardBody>
  )
}

export default CategoryDetailPage
