import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getProgramsWithStats } from "../../requesters/Backend/ProgramRequester"

const BackendDashboard = ({ baseUrl, currentUser }) => {
  const [programList, setProgramList] = useState([])

  const fetchPrograms = () => {
    getProgramsWithStats(baseUrl, currentUser).then(res => {
      setProgramList(res.response.json.programs)
      return null
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  useEffect(() => {
    fetchPrograms()
  }, [])

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Organisation</h2>
            <Link to="/backend/create_unit" className="btn btn-outline-main btn-sm">+ Add New</Link>
          </div>
        </div>
        {/* <div className="table-filters d-inline-flex">
          <input className="form-control" placeholder="Search Organisation / Unit" type="search" />
          <select className="form-control"><option>Status</option></select>
        </div> */}
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Organisation</th>
                <th>Unit</th>
                <th>Program</th>
                <th>Unique Code</th>
                <th>Status</th>
                <th>Contact</th>
                <th>Contact Email</th>
                <th className="text-center">Active Users</th>
                <th className="text-center">Last 7D signups</th>
                <th className="text-center">Last 7D enrolments</th>
                <th className="text-center">{`# 10DWC finished >0`}</th>
                <th className="text-center">{`# 10DWC finished >1`}</th>
                <th className="text-center">{`# Pulse finished >0`}</th>
                <th className="text-center">{`# Pulse finished >1`}</th>
              </tr>
            </thead>
            <tbody>
              {programList.map(program => (
                <tr key={program.id}>
                  <td><Link to={`/backend/organisations/${program.organisation_id}`}>{program.organisation_name}</Link></td>
                  <td><Link to={`/backend/units/${program.unit_id}`}>{program.unit_name}</Link></td>
                  <td><Link to={`/backend/programs/${program.id}`}>{program.name}</Link></td>
                  <td>{program.unique_id}</td>
                  <td>{program.status}</td>
                  <td>{program.unit_contact_name}</td>
                  <td>{program.unit_contact_email}</td>
                  <td className="text-center">{program.active_enrolments}</td>
                  <td className="text-center">{program.number_of_signups_between}</td>
                  <td className="text-center">{program.number_of_enrolments_between}</td>
                  <td className="text-center">{program.number_of_ten_dwc_completed?.at_least_once}</td>
                  <td className="text-center">{program.number_of_ten_dwc_completed?.more_than_once}</td>
                  <td className="text-center">{program.number_of_pulse_check_completed?.at_least_once}</td>
                  <td className="text-center">{program.number_of_pulse_check_completed?.more_than_once}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default BackendDashboard