import React from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"
import { OVERVIEW_PAGE_CONTENT } from "../Dashboard/constants"

const StyledNav = styled.nav`
  img:first-child {
    width: 64px;
    margin-right: 1em;
  }
  img:last-child {
    display: none;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`

const SurveyHeader = ({ form, onSaveHandler }) => (
  <div className="fixed-top">
    <StyledNav className="navbar navbar-light bg-white">
      <Link to="/ten-dwc-dashboard">
        <img src={OVERVIEW_PAGE_CONTENT["10DWC"].logoSrc} width={64} height={64} />
        <img src="/assets/10dwc-word-logo-c909afbd971364a2fc010364ba994f0b6bde429b95d041290ebdc8b28823b72a.svg" width={240} height={36} />
      </Link>
      {onSaveHandler && <button className="btn btn-outline-main btn-sm" type="button" onClick={onSaveHandler}>Save Draft</button>}
    </StyledNav>
    <div className="progress survey-progress">
      <div
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={0}
        style={{ width: `${form?.length / 110 * 100}%` }}
        className="progress-bar"
        role="progressbar" />
    </div>
  </div>
)

export default withRouter(SurveyHeader)