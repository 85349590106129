import React from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"
import { OVERVIEW_PAGE_CONTENT } from "../../Dashboard/constants"

const StyledNav = styled.nav`
  img:first-child {
    width: 64px;
    margin-right: 1em;
  }
  img:last-child {
    display: none;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`

const BurnoutProofHeader = ({
  form = {}, inflight, invalidInput, totalQuestionLength, onSubmit
}) => (
  <div className="fixed-top">
    <StyledNav className="navbar navbar-light bg-white">
      <Link to="/burnout-proof-dashboard">
        <img src={OVERVIEW_PAGE_CONTENT.burnout_proof.logoSrc} width={64} height={64} />
        <img src={OVERVIEW_PAGE_CONTENT.burnout_proof.textSrc} width={240} height={36} />
      </Link>
      <div>
        {onSubmit && (
          <>
            <Link className="btn btn-outline-main btn-sm mr-2" to="/burnout-proof-dashboard">Cancel</Link>
            {inflight ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
          ) : (
            <button
              className="btn btn-main btn-sm"
              disabled={totalQuestionLength !== Object.keys(form).length || inflight || invalidInput}
              type="submit"
              onClick={onSubmit}>
              Done
            </button>
        )}
          </>
        )}


      </div>
    </StyledNav>
    <div className="progress survey-progress">
      <div
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={0}
        style={{ width: `${Object.keys(form).length / totalQuestionLength * 100}%` }}
        className="progress-bar"
        role="progressbar" />
    </div>
  </div>
)

export default withRouter(BurnoutProofHeader)