import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"

import {
  getTemplateDetails, updateTemplateDetails, cloneTemplate, getSystemQuestions
} from "../../requesters/Backend/CalculatorRequester"
import { ANSWER_TYPES, DEFAULT_LIKERT_ANSWERS } from "./Templates/constants"
import ScoringSplit from "./Templates/ScoringSplit"
import TemplateQuestionForm from "./Templates/TemplateQuestionForm"

const TemplateDetailPage = ({ baseUrl, currentUser, match }) => {
  const [form, setForm] = useState({
    name: "Test template",
    // name: "",
    new_status: "template",
  })
  const [showErrors, setShowErrors] = useState(false)
  const [duplicateSlugError, setDuplicateSlugError] = useState(false)
  const [questionList, setQuestionList] = useState([])
  const [inflight, setInflight] = useState(true)
  const [systemQuestionList, setSystemQuestionList] = useState([])
  const { addToast } = useToasts()

  const fetchTemplateDetails = () => {
    getTemplateDetails(baseUrl, match?.params?.templateId, currentUser)
      .then(res => {
        setInflight(false)
        setForm(res.response.json.template)
        setQuestionList(res.response.json.template?.questions)
        return null
      })
  }

  const checkQuestionLabelValidation = () => {
    const result = questionList.map(a => a.question_label)
    setShowErrors(_.uniq(result).length !== result.length)
    setDuplicateSlugError(_.uniq(result).length !== result.length)
  }

  const DEFAULT_QUESTION = {
    label: "",
    question_label: `question_${questionList.length + 1}`,
    status: "active",
    slider_label_one: "",
    slider_label_two: "",
    target_score: "",
    target_score_percent: "",
    question_type: ANSWER_TYPES[0],
    answers: DEFAULT_LIKERT_ANSWERS,
    key: Date.now().toString(),
  }

  const onAddQuestion = () => {
    if (questionList.length < 15) {
      setQuestionList([...questionList, DEFAULT_QUESTION])
    }
  }

  const onAddSystemQuestion = () => {
    if (questionList.length < 15 && systemQuestionList.length) {
      const FIRST_SYSTEM_QUESTION = { ...systemQuestionList[0], key: Date.now().toString() }
      setQuestionList([...questionList, FIRST_SYSTEM_QUESTION])
    }
  }

  const fetchSystemQuestions = () => {
    getSystemQuestions(baseUrl, currentUser)
      .then(res => {
        setSystemQuestionList(res?.response?.json?.system_question_list)
        return null
      })
  }

  useEffect(() => {
    if (questionList.length) {
      checkQuestionLabelValidation()
    }
  }, [questionList])

  useEffect(() => {
    fetchTemplateDetails()
    fetchSystemQuestions()
  }, [])

  const onSubmit = () => {
    updateTemplateDetails(baseUrl, { ...form, question_list: questionList }, currentUser)
      .then(res => {
        // eslint-disable-next-line no-console
        console.log(res)
        if (res?.response?.json?.template) {
          addToast(`Template updated successfully`, { appearance: "success" })
          setForm(res.response.json.template)
          // setQuestionList(res.response.json.template?.questions)
        }
      })
      .catch(err => {
        addToast(`Something went wrong: ${err?.request?.responseText}`, { appearance: "error" })
      })
  }

  const redirectHandler = id => {
    window.location.href = `/backend/templates/${id}`
  }

  const onClone = () => {
    cloneTemplate(baseUrl, { ...form, question_list: questionList }, currentUser)
      .then(res => {
        if (res?.response?.json?.template) {
          addToast(`Template updated successfully`, { appearance: "success" })
          setForm(res.response.json.template)
          redirectHandler(res.response.json.template.id)
        }
      })
      .catch(err => {
        addToast(`Something went wrong: ${err?.request?.responseText}`, { appearance: "error" })
      })
  }

  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  if (inflight) {
    return <div>Loading...</div>
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="form-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/backend/templates">Template</Link>
              </li>
              <li aria-current="page" className="breadcrumb-item active">{form.name}</li>
            </ol>
          </nav>
          <div className="card-section">
            <div className="card custom-qns-header">
              <div className="card-body">
                <div className="form-group">
                  <label>Template Name</label>
                  <input
                    className="form-control form-control-xl"
                    name="name"
                    onChange={onChange}
                    placeholder="Template Name"
                    required
                    type="text"
                    value={form.name} />
                </div>
                <ScoringSplit
                  setForm={setForm}
                  form={form} />
              </div>
            </div>
            <TemplateQuestionForm
              onAddQuestion={onAddQuestion}
              onAddSystemQuestion={onAddSystemQuestion}
              questionList={questionList}
              setQuestionList={setQuestionList}
              systemQuestionList={systemQuestionList} />
          </div>
          <div className="form-container-cta fixed-bottom">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary rounded-pill"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
                Add
              </button>
              <div className="dropdown-menu">
                <button
                  className="btn btn-primary mb-4"
                  onClick={onAddQuestion}
                  type="button">
                  Custom Question
                </button>
                <button
                  className="btn btn-primary mb-4"
                  onClick={onAddSystemQuestion}
                  type="button">
                  System Question
                </button>
              </div>
            </div>
            <Link className="btn btn-outline-main" to="/backend/templates">Cancel</Link>
            <button
              className="btn btn-outline-main"
              onClick={onClone}
              disabled={showErrors || duplicateSlugError}
              type="submit">
              Clone
            </button>
            <Link
              to={`/backend/templates/preview/${form.id}`}
              className="btn btn-outline-main">
              Preview
            </Link>
            {/* DEVNOTE: CZ TO CLEAN UP VALIDATION */}
            <button
              className="btn btn-main"
              onClick={onSubmit}
              disabled={showErrors || duplicateSlugError}
              type="submit">
              Update
            </button>
            {duplicateSlugError && <div className="err-msg text-danger">Duplicate Dashboard Label Detected.</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplateDetailPage
