import React from "react"
import { Link } from "react-router-dom"
import WellbeingCardHeading from "./WellbeingCardHeading"
import { WELLBEING_CARD_DESC } from "./constants"

const WellbeingCard = ({
  result, prevResult, index, dimension
}) => (
  <div className="card">
    <div className="card-header" id="headingOne">
      <div className="mb-0">
        <div
          aria-controls={`#collapseOne${result.section}`}
          aria-expanded="true"
          data-target={`#collapseOne${result.section}`}
          data-toggle="collapse">
          <div className="row dimension-item no-gutters">
            <div className="col-lg-6 col-xl-5 dimension-content">
              <div className="d-flex justify-content-between">
                <WellbeingCardHeading title={result.section} />
                <div className="score-change">
                  <div className="score">{result.net_result}</div>
                  {!!prevResult && (
                    <>
                      {result.net_result > prevResult.net_result ? (
                        <img src="/assets/icons/icn-up-e8d5c31c843bf3fb4ac17b435af749fb2606add0806bb29a81761fb0614a8bb4.svg" />
                      ) : (
                        <img src="/assets/icons/icn-down-fb56867ca128f33ff7661601e208c2c619b2148c1efbb857952c74e036cb2956.svg" />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-7 dimension-graph">
              <div className="progress" style={{ height: "1px" }}>
                <div
                  aria-valuemax={100}
                  aria-valuemin={0}
                  aria-valuenow={97}
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${result.net_result}%` }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div aria-labelledby="headingOne" className={`collapse ${index === 0 && "show"}`} data-parent="#dimensions" id={`collapseOne${result.section}`}>
      <div className="dimension-description">
        <div className="small">
          {WELLBEING_CARD_DESC[dimension]}
        </div>
        <Link className="btn btn-outline-main btn-xs" to={`dimension/${dimension}`}>View More</Link>
      </div>
    </div>
  </div>
)

export default WellbeingCard