import React from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 1rem 1rem;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
`

const SetItUpModal = ({
  show, onHide, getStartedHandler
}) => (
  <Modal show={show} onHide={onHide} size="lg" centered id="kajabi-instruction-modal">
    <Header>
      <button type="button" className="close" onClick={onHide}>
        <span aria-hidden="true">&times;</span>
      </button>
    </Header>
    <div className="modal-body pl-5 pr-5 pb-5">
      <h3 className="mb-3">How to connect to your program content</h3>
      <p className="mb-4">All your program content is hosted on our Strive:Stronger Digital Platform. To access this content, you just need to perform a few steps to get connected:</p>
      <p>1. Click on the &apos;Get Started&apos; button below. You will receive an email with the log in credentials for your new Strive:Stronger Digital Platform account.</p>
      <p>2. Copy and paste the username and password from this email to log in to the Strive:Stronger Digital Platform. We recommend that cookies are enabled so that accessing your program is seamless after this.</p>
      <p>3. You can now access your program content at any time by clicking &apos;Take Me There&apos; from your iStrive dashboard.</p>

      <div className="mt-4 mb-5">
        <button className="btn btn-main" type="button" onClick={getStartedHandler}>
          Get Started
        </button>
      </div>

      <div className="mt-2 mb-2"><b>Did not receive a new account email?</b></div>
      <p>
        You may already have a Strive:Stronger Digital Platform account. Click the &quot;Get Started&quot; button above to log in.
      </p>
      <p className="mb-0">
        If you have forgotten your password, you can reset it by clicking on this
        {" "}
        <a href="https://www.strivestronger.com/password/new" target="_blank" rel="noreferrer">link</a>
        .
      </p>
      <p className="mt-0">
        If you still have issues, contact
        {" "}
        <a href="mailto:support@strivestronger.com">support@strivestronger.com</a>
        .
      </p>
      <div className="mt-4">
        <button type="button" className="btn btn-outline-main" onClick={onHide}>
          <img src="/assets/icons/icn-back-009e79b5747b122738def7d3b12743f2fc8a033afbefde260aa458eb38a9f2db.svg" />
        </button>
      </div>
    </div>
  </Modal>
)

export default SetItUpModal