/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { DashboardBody } from "./styles"
import DashboardEmptyState from "./DashboardEmptyState"
import { getDashboardResults } from "../../requesters/CalculatorResultsRequester"
import WellbeingCard from "./WellbeingCard"
import RecommendationSection from "./RecommendationSection"
import { getWellbeingOverallDesc, OVERVIEW_PAGE_CONTENT } from "./constants"
import WellbeingIndexChart from "./WellbeingIndexChart"
import IndexGauge from "./IndexGauge"

const Dashboard = ({ baseUrl, currentUser }) => {
  const [surveyResults, setSurveyResults] = useState([])
  const [wellbeingIndexes, setWellbeingIndexes] = useState([])
  const [results, setResults] = useState({})
  const [inflight, setInflight] = useState(true)
  const unfinishedSurveyExists = _.get(currentUser, "active_enrolment.is_new_attempt_ten_dwc", true)

  useEffect(() => {
    document.title = "iStrive | 10DWC Dashboard"
  }, [])

  const fetchResults = () => {
    getDashboardResults(baseUrl, currentUser).then(res => {
      setInflight(false)
      if (res.response.json?.calculator_results) {
        const recentResults = res.response.json?.calculator_results
        setSurveyResults(res.response.json?.calculator_results)
        setResults(res.response.json?.calculator_results[0]?.total_results)
        setWellbeingIndexes(recentResults.map(result => result.wellbeing_index))
      }
      return null
    })
  }

  useEffect(() => {
    fetchResults()
  }, [])

  if (inflight) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_enrolment) {
    window.location.href = "/overview"
  }

  // Render empty state page if no results are found
  if (!surveyResults.length && !inflight) {
    return <DashboardEmptyState unfinishedSurveyExists={unfinishedSurveyExists} />
  }

  return (
    <DashboardBody>
      <div className="content">
        <div className="container dashboard" id="dashboard-10dwc">
          <div className="d-flex align-items-center justify-content-between py-4">
            <div>
              <Link
                to="/overview"
                className="back-to-overview">
                <img src="/assets/icons/icn-back-009e79b5747b122738def7d3b12743f2fc8a033afbefde260aa458eb38a9f2db.svg" />
              </Link>
              <img src={OVERVIEW_PAGE_CONTENT["10DWC"].logoSrc} />
            </div>
            <div className="start-survey">
              <Link className="btn btn-main ml-4" to="/survey/intro">{`${unfinishedSurveyExists ? "Start" : "Continue"} Survey`}</Link>
              <div className="small">{`Last completed on ${surveyResults[0]?.completed_at}`}</div>
            </div>
          </div>
          <div className="dash-row row wellness-index">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="pre-text">Your Wellbeing Index</div>
                  <div className="gauge">
                    <IndexGauge
                      category={surveyResults[0].wellbeing_index_category}
                      color={surveyResults[0].wellbeing_index_color}
                      index={surveyResults[0].wellbeing_index} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <div className="pre-text">Your Wellbeing Index Trend</div>
                  <WellbeingIndexChart results={surveyResults} />
                </div>
              </div>
            </div>
          </div>
          <div className="dash-row row">
            <div className="col-lg-4"><Link className="btn btn-lg-block bg-main-120" to="/about-10dwc">What is 10DWC?</Link></div>
            {currentUser.active_enrolment?.latest_submitted_id && (
              <div className="col-lg-8">
                <a
                  className="btn btn-lg-block btn-outline-main"
                  target="_blank"
                  rel="noreferrer"
                  href={`/calculator_results/${currentUser.active_enrolment?.latest_submitted_id}.pdf`}>
                  {/* TODO: add the download icon */}
                  Download Latest Report
                </a>
              </div>
            )}
          </div>
          <div className="dash-row card">
            <div className="card-body">
              <div className="pre-text">10DWC</div>
              <h3>Your 10 Dimensions of Wellbeing</h3>
              <div id="dimensions-grid" />
              <div className="accordion" id="dimensions">
                {!!results.length && results.map((result, index) => (
                  <WellbeingCard
                    index={index}
                    dimension={result.section}
                    key={`wellbeing-card-${result.section}`}
                    prevResult={_.get(surveyResults, ["1", "total_results", index])}
                    result={result} />
                ))}
              </div>
            </div>
          </div>
          <div className="dash-row card">
            <div className="card-body">
              <div className="accordion" id="dimensions">
                {!!results.length && (
                  <WellbeingCard
                    index={10}
                    dimension="global"
                    key="wellbeing-card-global"
                    prevResult={_.get(surveyResults, ["1", "global_results"])}
                    result={_.get(surveyResults, ["0", "global_results"])} />
                )}
              </div>
            </div>
          </div>
          <div className="dash-row recommendations">
            <div className="row">
              <div className="col-xl-3">
                <div className="pre-text">How to improve</div>
                <h3>Recommendations</h3>
                <div>
                  {getWellbeingOverallDesc(wellbeingIndexes[0] || 0)}
                </div>
              </div>
              <RecommendationSection results={results} />
            </div>
          </div>
        </div>
      </div>
    </DashboardBody>
  )
}

export default Dashboard
