import React, { memo } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { iconPaths } from "../../../util/iconPaths"
import QuestionCard from "./QuestionCard/Base"
// DEV-NOTE: library sandbox for future reference https://codesandbox.io/s/zqwz5n5p9x

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

function Question({
  question, index, form, setForm, systemQuestionList
}) {
  return (
    <Draggable draggableId={question.id || question.key} index={index}>
      {provided => (
        <div
          className="custom-qns-container"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <img src={iconPaths.reorderDots} style={{ marginRight: 10, width: 20 }} />
          <QuestionCard
            key={question.key}
            form={form}
            systemQuestionList={systemQuestionList}
            question={question}
            setForm={setForm}
            index={index} />
        </div>
      )}
    </Draggable>
  )
}

const QuestionList = memo(({
  questions, setForm, systemQuestionList
}) => questions.map((question, index) => (
  <Question
    question={question}
    index={index}
    key={question.id || question.key}
    form={questions}
    setForm={setForm}
    systemQuestionList={systemQuestionList} />
  )))

function DraggableQuestions({
  form,
  setForm,
  systemQuestionList
}) {
  const onDragEnd = result => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const questions = reorder(
      form,
      result.source.index,
      result.destination.index
    )

    setForm(questions)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <QuestionList
              questions={form}
              setForm={setForm}
              systemQuestionList={systemQuestionList} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default DraggableQuestions