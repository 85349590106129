import React, { useState, useEffect } from "react"
import { resendEmailConfirmation } from "../../requesters/UsersRequester"

export default function EmailVerification({ baseUrl, currentUser }) {
  const [showResendLink, setShowResendLink] = useState(true)

  const sendConfirmationEmail = () => {
    async function fetchData() {
      await resendEmailConfirmation(baseUrl, currentUser)
      setShowResendLink(false)
      setTimeout(() => { setShowResendLink(true) }, 10000)
    }
    if (currentUser) {
      fetchData()
    }
  }

  const checkUserConfirmation = () => {
    if (currentUser.confirmed_at) {
      window.location.href = "/overview"
    }
  }

  useEffect(() => {
    checkUserConfirmation()
  }, [])

  return (
    <div className="container text-center">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <h2 className="mt-5 mb-4 pt-5">Please confirm your email to complete sign up</h2>
          <p>We’ve just sent you an email with a confirmation link.</p>
          <p>If you do not receive a confirmation email, please check your spam folder and make sure you have entered a valid email address.</p>
          {showResendLink && <button type="button" onClick={sendConfirmationEmail} className="btn btn-link">Resend Verification Email</button>}
          {!showResendLink && <p className="btn btn-plain">Email resent!</p>}
        </div>
      </div>
    </div>
  )
}
