import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"

import { getUserOverview, sendKajabiAccessRequest, sendLearnWorldAccessRequest } from "../../requesters/UsersRequester"
import IndexGauge from "./IndexGauge"
import { DashboardBody } from "./styles"
import WellbeingIndexChartTiny from "./WellbeingIndexChartTiny"
import SetItUpModal from "./SetItUpModal"
import { OVERVIEW_PAGE_CONTENT } from "./constants"

const SurveyOverviewPage = ({ currentUser, baseUrl }) => {
  const [userOverview, setUserOverview] = useState([])
  const [inflight, setInflight] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)
  const unfinishedTenDwcExists = _.get(currentUser, "active_enrolment.is_new_attempt_ten_dwc", true)
  const unfinishedMscExists = _.get(currentUser, "active_enrolment.is_new_attempt_mental_skills", true)
  const unfinishedMfcExists = _.get(currentUser, "active_enrolment.is_new_attempt_matchfit", true)
  const unfinishedLlsExists = _.get(currentUser, "active_enrolment.is_new_attempt_live_life", true)
  const [showSetItUpModal, setShowSetItUpModal] = useState(false)
  const [isSignedUpForKajabi, setIsSignedUpForKajabi] = useState(currentUser.active_enrolment?.kajabi_signed_up)
  const integrationType = currentUser.active_enrolment?.integration_type
  const webhookUrl = currentUser.active_enrolment?.kajabi_webhook_url
  const { addToast } = useToasts()

  useEffect(() => {
    document.title = "iStrive | Overview"
  }, [])

  const fetchResults = () => {
    getUserOverview(baseUrl, currentUser).then(res => {
      setInflight(false)
      if (res.response.json?.overview?.overview_data) {
        setUserOverview(res.response.json?.overview?.overview_data)
      }
      return null
    })
  }

  useEffect(() => {
    fetchResults()
  }, [])

  const kajabiHandler = () => {
    window.open(currentUser.active_enrolment.offer_redirect_url, "_blank")
  }

  const getStartedHandler = () => {
    sendKajabiAccessRequest(baseUrl, currentUser).then(res => {
      if (res.response?.status === "ok") {
        setIsSignedUpForKajabi(true)
        window.open(currentUser.active_enrolment.offer_redirect_url, "_blank")
      }
      return res
    }).catch(err => addToast(`${err}`, { appearance: "error" }))
  }

  const getStartedLearnWorldHandler = () => {
    setShowSpinner(true)
    sendLearnWorldAccessRequest(baseUrl, currentUser.program_id, currentUser)
        .then(res => {
            if (res.response?.status === "ok") {
                setShowSpinner(false)
                window.open(res.response.json.learn_world.url, "_blank")
            }
            return res
        })
        .catch(err => {
            setShowSpinner(false)
            const errorMessages = err.response?.data?.errors
            if (!errorMessages) {
              addToast("Something went wrong", { appearance: "error" })
              return
            }

            errorMessages.forEach(error => {
              addToast(`${error.detail}`, { appearance: "error" })
            })
        })
  }

  if (inflight) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  // If no calculator options, reroute to enrollment page
  // TODO: base on calculator options
  if (!currentUser?.is_program_active) {
    window.location.href = "/enrol"
  }

  const continueStartBtnHandler = calculator_type => {
    if ((!unfinishedTenDwcExists && (calculator_type === "10DWC")) || (!unfinishedMscExists && (calculator_type === "mental_skills")) || (!unfinishedMfcExists && (calculator_type === "matchfit")) || (!unfinishedLlsExists && (calculator_type === "live_life"))) {
      return "Continue Survey"
    }
    return "Start Survey"
  }

  // Render empty state page if no results are found
  // if (!userOverview.length && !inflight && currentUser?.active_ten_dwc_id) {
  //   return <DashboardEmptyState unfinishedTenDwcExists={unfinishedTenDwcExists} />
  // }

  return (
    <DashboardBody className="content">
      <div className="container overview">
        <div className="pt-4">
          <h1>
            {`Hi ${currentUser.firstname}`}
          </h1>
          {`Organisation: ${currentUser.organisation_info?.name}`}
          <hr />
        </div>
        <h4 className="my-3 py-3">
          {`Currently Enrolled Program: ${currentUser.active_enrolment?.program_name}`}
        </h4>
        {currentUser.active_enrolment?.integration_type && (
          <div className="program-resource mb-5">
            <img src="/assets/icons/Digital-Content-18a4127d6e6cc36bc859fb7c8a833ce1ebe3f900890607d82c935a48238c750a.svg" />
            <div className="program-resource-content d-md-flex ml-4 ml-md-0">
              <div className="mx-md-4">
                {(currentUser.active_enrolment?.program_header)
                ? (
                  <h4>{currentUser.active_enrolment?.program_header}</h4>
                )
                : (
                  <h4>
                    Access
                    {" "}
                    <span>{currentUser.active_enrolment?.program_name}</span>
                    {" "}
                    Digital Content
                  </h4>
                )}
                {
                  (currentUser.active_enrolment?.program_body)
                  ? (
                    <p className="mb-1">
                      {currentUser.active_enrolment?.program_body}
                    </p>
                  )
                  : (
                    <p className="mb-1">
                      On-demand access to the resource-rich digital content to further embed change. Includes all Educational Webinars and Connection Zone classes.
                    </p>
                  )}
                {(integrationType === "kajabi" && !!webhookUrl) && (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a className="text-nowrap pointer" onClick={() => setShowSetItUpModal(true)}>Need Help?</a>
                )}
              </div>
              {integrationType === "learn_world" && (
                <button
                  className="btn btn-main btn-sm text-nowrap"
                  type="button"
                  onClick={showSpinner ? null : getStartedLearnWorldHandler}
                  disabled={showSpinner}>
                  {showSpinner ? (
                    <>
                      <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </>
                  ) : (
                    "Take me There"
                  )}
                </button>
              )}

              {integrationType === "kajabi" && (
                <>
                  {isSignedUpForKajabi ? (
                    <button
                      className="btn btn-main btn-sm text-nowrap"
                      type="button"
                      onClick={kajabiHandler}>
                      Take me There
                    </button>
                  ) : (
                    integrationType === "kajabi" && (
                      <button
                        className="btn btn-main btn-sm text-nowrap"
                        type="button"
                        onClick={() => setShowSetItUpModal(true)}>
                        Set it up
                      </button>
                    )
                  )}
                </>
              )}
              <SetItUpModal
                show={showSetItUpModal}
                currentUser={currentUser}
                baseUrl={baseUrl}
                getStartedHandler={getStartedHandler}
                onHide={() => {
                  setShowSetItUpModal(false)
                }} />
            </div>
          </div>
        )}

        {userOverview.map(overview => {
          if (overview.calculator_type === null) return null
          return (
            <Fragment key={overview.calculator_type}>
              {/* <div className="block-header"><h4>{overview.calculator_type}</h4></div> */}
              <div className="calc-item">
                <div className="card">
                  <div className="card-body">
                    <div className="calc-intro d-md-flex align-items-start">
                      <img className="mb-2" src={OVERVIEW_PAGE_CONTENT[overview.calculator_type].logoSrc} />
                      <div className="ml-1">
                        <h4 className="my-1">{OVERVIEW_PAGE_CONTENT[overview.calculator_type].header}</h4>
                        <p>{OVERVIEW_PAGE_CONTENT[overview.calculator_type].description}</p>
                      </div>
                    </div>
                    <div className="calc-item-footer justify-content-space-between">
                      <div className="calc-summary-container">
                        <div className="calc-summary">
                          <IndexGauge
                            category={overview?.results[0]?.wellbeing_index_category}
                            color={overview?.results[0]?.wellbeing_index_color}
                            index={overview?.results[0]?.wellbeing_index} />
                        </div>
                        {!!(overview?.results.length > 1) && (
                          <div className="calc-summary recharts">
                            <WellbeingIndexChartTiny results={overview?.results} />
                          </div>
                        )}
                      </div>
                      <div className="links">
                        {!!overview?.results.length && <Link className="btn btn-sm btn-outline-main" to={OVERVIEW_PAGE_CONTENT[overview.calculator_type].dashboardHref}>View</Link>}
                        <Link className="btn btn-sm btn-main" to={OVERVIEW_PAGE_CONTENT[overview.calculator_type].surveyIntroHref}>{continueStartBtnHandler(overview.calculator_type)}</Link>
                      </div>
                    </div>
                  </div>
                </div>
                {overview?.results[0]?.completed_at && <small className="d-block mt-2 text-muted">{`Last completed on ${overview?.results[0]?.completed_at}`}</small>}
              </div>
            </Fragment>
          )
        })}
      </div>
    </DashboardBody>
  )
}

export default SurveyOverviewPage
