/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import {
  // getOrganisations,
  createOrganisation
} from "../../requesters/Backend/OrganisationRequester"
import { emailRegex } from "../../util/regex"

const CreateOrgPage = ({ baseUrl, currentUser }) => {
  const [form, setForm] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const requiredValues = [
    "organisation_name",
    "unit_name",
    "contact_name",
    "contact_email",
    "contact_number"
  ]
  // const [organisationList, setOrganisationList] = useState([])
  const { addToast } = useToasts()

  // const fetchOrganisations = () => {
  //   getOrganisations(baseUrl).then(res => {
  //     setOrganisationList(res.response.json.organisations)
  //     return null
  //   })
  // }

  // useEffect(() => {
  //   fetchOrganisations()
  // }, [])

  const validateEmail = () => {
    // Check if valid email according to regex
    setIsValidEmail(emailRegex.test(form.contact_email))
  }

  useEffect(() => {
    validateEmail()
  }, [form.contact_email])

  const onSubmit = () => {
    setShowErrors(true)
    if (requiredValues.every(k => k in form) && isValidEmail) {
      createOrganisation(baseUrl, form, currentUser)
        .then(res => {
          window.location.href = `/backend/organisations/${res.response.json.unit.organisation_id}`
        })
        .catch(() => {
          addToast(`Something went wrong: Organisation Name is not unique`, { appearance: "error" })
        })
    }
  }

  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="form-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/backend">Organisation</Link>
              </li>
              <li aria-current="page" className="breadcrumb-item active"> Add New</li>
            </ol>
          </nav>
          <h1>Add New</h1>
          <div className="card-section">
            <div className="card">
              <div className="card-header"><h5>Organisation &amp; Unit</h5></div>
              <div className="card-body">
                <div className="form-group">
                  <label>Organisation name</label>
                  <input className="form-control" type="search" name="organisation_name" onChange={onChange} />
                  {showErrors && !form.organisation_name
                    && <div className="err-msg text-danger">Organisation Name is required</div>}
                </div>
                <div className="form-group">
                  <label>Unit</label>
                  <input className="form-control" type="text" name="unit_name" onChange={onChange} />
                  {showErrors && !form.unit_name
                    && <div className="err-msg text-danger">Unit Name is required</div>}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header"><h5>Contact</h5></div>
              <div className="card-body">
                <div className="form-group">
                  <label>Contact name</label>
                  <input className="form-control" type="text" name="contact_name" onChange={onChange} />
                  {showErrors && !form.contact_name
                    && <div className="err-msg text-danger">Contact Name is required</div>}
                </div>
                <div className="form-group">
                  <label>Contact email</label>
                  <input className="form-control" type="email" name="contact_email" onChange={onChange} />
                  {showErrors && !form.contact_email
                    && <div className="err-msg text-danger">Contact email is required</div>}
                  {showErrors && !isValidEmail
                    && <div className="err-msg text-danger">Contact email is invalid</div>}
                </div>
                <div className="form-group">
                  <label>Contact number</label>
                  <input className="form-control" type="text" name="contact_number" onChange={onChange} maxLength="10" pattern="[0-9]{10}" />
                  {showErrors && !form.contact_number
                    && <div className="err-msg text-danger">Contact number is required</div>}
                </div>
              </div>
            </div>
          </div>
          <div className="form-container-cta fixed-bottom">
            <Link to="/backend" className="btn btn-outline-main">Cancel</Link>
            <button type="button" className="btn btn-main" onClick={onSubmit}>Create</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateOrgPage
