import React from "react"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import styled from "styled-components"

const Container = styled.div`
  margin: auto;
  max-height: 300px;
  max-width: 300px;
  width: 100%;
`
const IndexNumber = styled.div`
  font-size: 5rem;
  font-weight: bold;
`

const IndexScore = styled.div`
  margin-top: -16px;
  font-size: 1.5rem;
  font-weight: bold;
`

const IndexGauge = ({ index, category, color }) => {
  if (!index) return null
  return (
    <Container className="gauge-container">
      <CircularProgressbarWithChildren
        value={index}
      // ratio sets the limits of the circle gauge
        circleRatio={0.80}
        styles={buildStyles({
        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: "butt",
        // rotates the gauge so ends sit on the bottom
        rotation: 0.6,
        pathColor: `#${color}`,
        trailColor: "#d6d6d6",
      })}>
        <div className="gauge-text">
          <IndexNumber>{index}</IndexNumber>
          <IndexScore className="text-nowrap">{category}</IndexScore>
        </div>
      </CircularProgressbarWithChildren>
    </Container>
  )
}

export default IndexGauge
