import React from "react"

export const srcLink = title => {
  if (title === "global") return "assets/icons/10dwc/icn-10dwc-global-f40a82ce9dd05809e59ded2425550bc9409cc699995259d7a5d0cdac311ee8df.png"
  let suffix = ""
  switch (title) {
    case "cultural":
      suffix = "cultural-b8b5747ccc83679d50e7aba41440fa726bc3ed17a621c8b2b423b2429ff72d03.svg"
      break
    case "digital":
      suffix = "digital-308cdc4915812d1bebc6a06ec8c897fed8591275069c71e9c9d63dcf051a91ee.svg"
      break
    case "environmental":
      suffix = "environmental-632222eaeb1e8ec6d59ebc1221a94a5467cc24b1f96cadfef21d4990449444ce.svg"
      break
    case "financial":
      suffix = "financial-33dc40ef74ba7c88648f788d823eb3d6edf94e8b12a0bc463cebb497fcc0632c.svg"
      break
    case "intellectual":
      suffix = "intellectual-b38193d798fc24390d87d6e05d2a175ff9898c2e2cdc5c220751d437b760d258.svg"
      break
    case "occupational":
      suffix = "occupational-2509fb84965aa8a83e4fe8519a95ffca824e407a9aa82ec7ecc81ed998d635c5.svg"
      break
    case "physical":
      suffix = "physical-f27382630aab60ee7c13992a1d8156aa28ee0d6b75f269cc19aacf8a81eb5cd7.svg"
      break
    case "psychological":
      suffix = "psychological-107068f42e03f063b76e6fba9cf00ac2d2d136023d2986e36f5c409740a46d85.svg"
      break
    case "social":
      suffix = "social-3e8bdcc53cf9f0bab7161c3bb280ef9b95bc315dc2aef6f4d88dd2a2ce8c3453.svg"
      break
    case "spiritual":
      suffix = "spiritual-db18d4c8a3e13b2f7209e42f0ac5bd0b25f55eda814efd5044278514da0cea46.svg"
      break

    default:
      break
  }
  return `/assets/icons/10dwc/icn-10dwc-${ suffix}`
}

const WellbeingCardHeading = ({ title }) => (
  <div>
    <img className="mr-3" src={srcLink(title)} width={48} />
    {/* <img className="mr-3" src={`/assets/icons/10dwc/icn-10dwc-${title}.svg`} width={48} /> */}
    {title.charAt(0).toUpperCase() + title.slice(1)}
    {" "}
    Wellbeing
    {" "}
    {title === "global" && "Scale"}
  </div>
)

export default WellbeingCardHeading