import React, { useEffect, useState } from "react"
import { animateScroll as scroll } from "react-scroll"
import { GLOBAL_SUB_HEADING } from "./constants"
import SliderQuestion from "./SliderQuestion"

const SurveyGlobalSection = ({
  currentSection,
  form,
  onChangeHandler,
  onSubmit,
  questionSet,
  setCurrentSection,
  totalQuestionLength,
}) => {
  const [inflight, setInflight] = useState(false)
  const { questions } = questionSet

  const onPreviousStep = () => {
    setCurrentSection(currentSection - 1)
  }

  useEffect(() => {
    if (currentSection === questionSet.page_index) {
      scroll.scrollToTop()
    }
  }, [currentSection])

  const onClickSubmit = e => {
    setInflight(true)
    onSubmit(e)
  }

  if (currentSection !== questionSet.page_index) return null
  return (
    <>
      <h1>Global Wellbeing Scale</h1>
      <p>{GLOBAL_SUB_HEADING}</p>
      <hr />
      {questions.map(question => (
        <SliderQuestion
          label={question.label}
          onChangeHandler={onChangeHandler}
          selectedAnswer={form.find(e => e?.answer_id === question.answers[0].id)}
          sortingIndex={question.sorting_index}
          answer={question.answers[0]}
          section={question.section}
          key={`question-${question.id}`} />
      ))}
      {inflight ? (
        <div className="bottom-cta d-flex justify-content-between">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="bottom-cta d-flex justify-content-between">
          <button
            className="btn btn-light"
            type="button"
            onClick={onPreviousStep}>
            Previous
          </button>
          <button
            className="btn btn-main"
            disabled={totalQuestionLength !== form.filter(e => e).length || inflight}
            type="submit"
            onClick={onClickSubmit}>
            Done
          </button>
        </div>
      )}
    </>
  )
}

export default SurveyGlobalSection