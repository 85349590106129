import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { getCalculatorResult, updateCalculatorResult } from "../../../requesters/CalculatorResultsRequester"
import featureFlags from "../../../util/featureFlags"
import Header from "./Header"

const makeProgressClass = progress => {
  if (progress === 2) {
    return "survey-tracker-label done"
  } if (progress === 1) {
    return "survey-tracker-label started"
  }
  return "survey-tracker-label"
}

const Intro = ({ baseUrl, currentUser }) => {
  const { addToast } = useToasts()
  const [form, setForm] = useState({})
  const [resultId, setResultId] = useState("")
  const [bodyProgress, setBodyProgress] = useState(0)
  const [movementProgress, setMovementProgress] = useState(0)
  const [physicalProgress, setPhysicalProgress] = useState(0)
  const [brainProgress, setBrainProgress] = useState(0)
  const [inflight, setInflight] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const fetchCalculatorResult = () => {
    getCalculatorResult(baseUrl, currentUser, "matchfit").then(res => {
      const {
        calc_responses: calculatorResponses,
        compulsory_question_slugs
      } = res.response.json?.calculator_results

      if (calculatorResponses?.length) {
        setForm(calculatorResponses)
        const questionSlugsArray = calculatorResponses.map(cr => cr.question_slug)
        setSubmitDisabled(_.difference(compulsory_question_slugs, questionSlugsArray).length > 0)

        // Handles progress state variables
        if (questionSlugsArray.includes("bag_preference")) {
          setBodyProgress(2)
        } else if (questionSlugsArray.includes("birthday") || questionSlugsArray.includes("sex")) {
          setBodyProgress(1)
        }
        if (questionSlugsArray.includes("heart_rate")) {
          setMovementProgress(2)
        } else if (questionSlugsArray.includes("wears_activity_monitor")) {
          setMovementProgress(1)
        }
        if (questionSlugsArray.includes("touch_toes")) {
          setPhysicalProgress(2)
        } else if (questionSlugsArray.includes("height") || questionSlugsArray.includes("weight")) {
          setPhysicalProgress(1)
        }
        if (questionSlugsArray.includes("fun_duration")) {
          setBrainProgress(2)
        } else if (questionSlugsArray.includes("cognitive1")) {
          setBrainProgress(1)
        }
      }
      setResultId(res.response.json?.calculator_results?.id)
      setInflight(false)
    })
  }

  useEffect(() => {
    if (currentUser?.active_matchfit_id && currentUser.active_enrolment?.sociodemographic_complete) {
      setInflight(true)
      fetchCalculatorResult()
    }
  }, [])

  // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_matchfit_id) {
    window.location.href = "/overview"
    return null
  }

  // Route to sociodemographic questions when they are not complete
  if (!currentUser.active_enrolment?.sociodemographic_complete) {
    window.location.href = "/demographic/matchfit"
    return null
  }

  const onSubmit = () => {
    setInflight(true)
    const payload = form
    updateCalculatorResult(baseUrl, resultId, payload, true, currentUser).then(res => {
      if (res.response?.status === "ok") {
        // window.location.href = `/matchfit/questions`
        window.location.href = `/matchfit/thank-you`
      }
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }

  return (
    <>
      <Header />
      <div className="container pb-5">
        <h1>MatchFit Calculator</h1>
        <p>Welcome to the MatchFit Calculator. This survey will take approximately 30 minutes to complete.</p>
        <p>Please note: We do not recommend undertaking the MatchFit Calculator Survey if you:</p>
        <ul>- are currently pregnant,</ul>
        <ul>- are within 6-month postnatal, or</ul>
        <ul>- have a pre-existing medical condition, or any injury that can be made worse by strenuous physical activity.</ul>
        <p>Individual data remains confidential and will never be shared with your organisation. We only report anonymised and aggregated data to share insights with your employer and for research purposes.</p>
        <p>Please complete all sections of this survey and click the &apos;Complete Survey&apos; button when all sections are done.</p>
        <p>You may save your progress at any time by clicking on the &apos;Save Draft&apos; button at the top right of the screen.</p>
        <ul className="list-group survey-tracker">
          <li className="list-group-item">
            <div className={makeProgressClass(bodyProgress)}>
              <div className="survey-tracker-count"><div className="h5">1</div></div>
              <h5>Functional Energy</h5>
            </div>
            <Link className="btn-next btn" to="/matchfit/questions/body" />
          </li>
          <li className="list-group-item">
            <div className={makeProgressClass(movementProgress)}>
              <div className="survey-tracker-count"><div className="h5">2</div></div>
              <h5>Heart Health</h5>
            </div>
            {bodyProgress === 2 && <Link className="btn-next btn" to="/matchfit/questions/movement" />}
          </li>
          <li className="list-group-item">
            <div className={makeProgressClass(physicalProgress)}>
              <div className="survey-tracker-count"><div className="h5">3</div></div>
              <div>
                <h5>Physical Functioning</h5>
                <div className="small">Please have a tape measure (e.g. tailor tape) and stopwatch (e.g. smartphone app) available for this section.</div>
              </div>
            </div>
            {movementProgress === 2 && <Link className="btn-next btn" to="/matchfit/questions/physical-functioning" />}
          </li>
          <li className="list-group-item">
            <div className={makeProgressClass(brainProgress)}>
              <div className="survey-tracker-count"><div className="h5">4</div></div>
              <h5>Brain Fitness</h5>
            </div>
            {physicalProgress === 2 && <Link className="btn-next btn" to="/matchfit/questions/brain" />}
          </li>
        </ul>
        {inflight ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (<button className="btn btn-main mt-4" disabled={submitDisabled} onClick={onSubmit} type="button">Complete Survey</button>)}
        {featureFlags("STAGING") && <Link className="btn btn-link" to="/matchfit/simple">MFC Simple Fillout (Staging only)</Link>}
      </div>
    </>
  )
}

export default Intro
