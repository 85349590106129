import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import SurveyHeader from "./SurveyHeader"
import SurveyFormSection from "./SurveyFormSection"
import SurveyGlobalSection from "./SurveyGlobalSection"
import { getTenDwcCalculator } from "../../requesters/CalculatorRequester"
import { getCalculatorResult, updateCalculatorResult } from "../../requesters/CalculatorResultsRequester"

const SurveyFormPage = ({ baseUrl, currentUser }) => {
  const [questions, setQuestions] = useState()
  const [form, setForm] = useState([])
  const [currentSection, setCurrentSection] = useState(0)
  const [totalQuestionLength, setTotalQuestionLength] = useState(0)
  const [lastPage, setLastPage] = useState(9)
  const [resultId, setResultId] = useState("")
  const { addToast } = useToasts()

  useEffect(() => {
    document.title = "iStrive | 10DWC Survey Questions"
  }, [])

  const fetchQuestionSet = () => {
    getTenDwcCalculator(baseUrl, currentUser.active_ten_dwc_id, currentUser).then(res => {
      setQuestions(res.response.json.calc.grouped_questions)
      setTotalQuestionLength(res.response.json.calc.question_length)
      setLastPage(res.response.json.calc.last_page)
    })
  }

  const fetchCalculatorResult = () => {
    getCalculatorResult(baseUrl, currentUser, "10DWC").then(res => {
      if (res.response.json?.calculator_results?.calc_responses) {
        const retrievedAnswers = res.response.json.calculator_results.calc_responses.map(cr => ({ answer_id: cr.answer_id, number_field: cr.answer_number, sorting_index: cr.question_sorting_index }))
        setResultId(res.response.json?.calculator_results?.id)
        setForm(retrievedAnswers)
        setCurrentSection(retrievedAnswers.length === 110 ? 10 : Math.floor(retrievedAnswers.length / 10))
      }
    })
  }

  useEffect(() => {
    // Route to linking code page when User not enrolled in a program
    if (!currentUser?.active_ten_dwc_id) {
      window.location.href = "/overview"
    } else {
      fetchQuestionSet()
      fetchCalculatorResult()
    }
  }, [])

  const onChangeHandler = e => {
    const { name, id } = e.target
    const formCopy = [...form]
    formCopy[name] = { answer_id: id }
    setForm(formCopy)
  }

  const onSlideHandler = e => {
    const { name, id, value } = e.target
    const formCopy = [...form]
    formCopy[name] = { answer_id: id, number_field: value }
    setForm(formCopy)
  }

  const onSubmit = () => {
    const payload = form.filter(e => e)
    updateCalculatorResult(baseUrl, resultId, payload, true, currentUser).then(res => {
      if (res.response?.status === "ok") {
        window.location.href = `/survey/thank-you`
      }
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }

  const onSaveHandler = () => {
    const payload = form.filter(e => e)
    updateCalculatorResult(baseUrl, resultId, payload, false, currentUser).then(res => {
      if (res.response?.status === "ok") {
        window.location.href = `/ten-dwc-dashboard`
      }
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }

  return (
    <div className="container">
      <SurveyHeader form={form} onSaveHandler={onSaveHandler} />
      {/* Get the first 10 question groups */}
      {!!questions && questions.slice(0, 10).map(question => (
        <SurveyFormSection
          currentSection={currentSection}
          form={form}
          key={question.page_index}
          lastPage={lastPage}
          onChangeHandler={onChangeHandler}
          onSubmit={onSubmit}
          questionSet={question}
          setCurrentSection={setCurrentSection}
          totalQuestionLength={totalQuestionLength} />
      ))}
      {/* The 11th grouped question is global wellbeing */}
      {!!questions && questions[10] && (
        <SurveyGlobalSection
          currentSection={currentSection}
          form={form}
          lastPage={lastPage}
          onChangeHandler={onSlideHandler}
          onSubmit={onSubmit}
          questionSet={questions[10]}
          setCurrentSection={setCurrentSection}
          totalQuestionLength={totalQuestionLength} />
      )}
    </div>
  )
}

export default SurveyFormPage
