/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { checkEmailAvailability } from "../../requesters"
import { emailRegex, passwordRegex } from "../../util/regex"

const SignUpPage = ({ baseUrl, location }) => {
  const [form, setForm] = useState({})
  const [error, setError] = useState("")
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [showErrors, setShowErrors] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(true)
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true)
  const linkingCode = location.search.split("=")[1]

  const handleSubmit = e => {
    e.preventDefault()
    const path = "/users"
    const token = document.querySelector("[name=csrf-token]").content
    const user = _.pick(form, ["firstname", "surname", "email", "password"])
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": token
    }

    axios.post(path, { headers, user, linkingCode })
      .then(response => {
        if (response.status !== 200) {
          console.log(response.data)
          setError(response.data.errors)
          return null
        }
        window.location.href = "/verify-email"
        return null
      })
      .catch(error => {
        console.log(error, Object.keys(error), _.get(error, "response.data.message.0"))
        setError(_.get(error, "response.data.message.0"))
      })
  }

  const validateEmail = () => {
    // Check if valid email according to regex first
    const validEmail = emailRegex.test(form.email)
    if (validEmail) {
      // Then run API request to check email is available
      checkEmailAvailability(baseUrl, form.email)
        .then(res => {
          setIsValidEmail(res.response.json.available)
        })
        .catch(err => console.log(err))
    } else {
      setIsValidEmail(false)
    }
  }

  const validatePassword = () => {
    setIsValidPassword(passwordRegex.test(form.password))
    if (form.password?.length) { setShowErrors(true) }
  }

  const validateConfirmPassword = () => {
    setIsValidConfirmPassword(form.password === form.confirmPassword)
    if (form.confirmPassword?.length) { setShowErrors(true) }
  }

  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
    setShowErrors(false)
  }

  return (
    <div className="bg-white">
      <div className="session-box">
        <h2>Sign Up</h2>
        <p>Sign up with the form below.</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="row">
              <div className="col-6">
                <input
                  className="form-control"
                  placeholder="First Name"
                  type="text"
                  name="firstname"
                  onChange={onChange}
                  autoFocus />
              </div>
              <div className="col-6">
                <input
                  className="form-control"
                  placeholder="Last Name"
                  name="surname"
                  onChange={onChange}
                  type="text" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Email"
              name="email"
              onChange={onChange}
              onBlur={validateEmail}
              type="email" />
            {!isValidEmail && <div className="err-msg text-danger">Email address is either invalid or already exists</div>}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Password"
              name="password"
              onChange={onChange}
              onBlur={validatePassword}
              type="password" />
            {showErrors && !isValidPassword && <div className="err-msg text-danger">Passwords must be between 6 and 25 characters and include a number, a symbol, a lowercase letter and an uppercase letter.</div>}
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={onChange}
              onBlur={validateConfirmPassword}
              type="password" />
            {showErrors && !isValidConfirmPassword && <div className="err-msg text-danger">Password Confirmation must match Password.</div>}
          </div>

          {error && <div className="err-msg text-danger">{error}</div>}
          <div className="small my-5">
            By signing up, I am agreeing to Strive Stronger’s&nbsp;
            <span><a target="_blank" href="https://www.strivestronger.com/pages/terms" rel="noreferrer">terms of use</a></span>
            {" "}
            and&nbsp;
            <span><a target="_blank" href="https://www.strivestronger.com/pages/privacy-policy" rel="noreferrer">privacy policy</a></span>
            .
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-main"
              onClick={handleSubmit}
              disabled={
                !(form.firstname
                  && form.surname
                  && form.email
                  && form.password
                  && form.confirmPassword
                  && isValidEmail
                  && isValidPassword
                  && isValidConfirmPassword)}>
              Get Started
            </button>
          </div>
        </form>
        Already have an account?
        <Link to="/" className="text-main">&nbsp;Login</Link>
      </div>
    </div>
  )
}

export default SignUpPage
