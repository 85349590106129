import React from "react"
import { Link } from "react-router-dom"
import MentalSkillsHeader from "./MentalSkillsHeader"

const MentalSkillsIntro = ({ currentUser }) => {
  const unfinishedSurveyExists = currentUser.active_enrolment.is_new_attempt_mental_skills

  // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_mental_skills_id) {
    window.location.href = "/overview"
    return null
  }

  // Route to sociodemographic questions when they are not complete
  if (!currentUser.active_enrolment?.sociodemographic_complete) {
    window.location.href = "/demographic/mental-skills"
    return null
  }

  return (
    <>
      <MentalSkillsHeader />
      <div className="container pb-5">
        <h1>Mental Skills Calculator</h1>
        <p>The Mental Skills Calculator (MSC) measures behaviours and perceptions specific to mindset, cognitive function and decision making under pressure. The questions in this survey are broken down into 3 categories: Self-Discovery, Foundation Skills and Performance Psychology.</p>
        <p>
          All questions must be answered. Please read the statements in the survey and rate how each applies to you,
          {" "}
          <b>reflecting on the past 4 weeks</b>
          .
        </p>
        <p>Note: Individual data remains confidential and will never be shared with your organisation. We only report anonymised and aggregated data to share insights with your employer and for research purposes.</p>
        <Link className="btn btn-main mt-4" to="/mental-skills/questions">{`${unfinishedSurveyExists ? "Start" : "Continue"} Survey`}</Link>
      </div>
    </>
  )
}

export default MentalSkillsIntro
