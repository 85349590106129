import React from "react"
import Answer from "../Answer"

const BurnoutProofFormSection = ({
  question: {
    answers, label, question_type
  },
  questionId,
  selectedAnswer,
  onSelectHandler,
}) => {
  const buttonGroupClass = answers.length > 5 ? "btn-group btn-group-toggle horizontal" : "btn-group btn-group-toggle"
  const surveyTypeSuffix = question_type === "Overall" ? "-arm-back" : ""
  return (
    <div className={`q-group${surveyTypeSuffix}`}>
      <p>{label}</p>
      <div className={buttonGroupClass} data-toggle="buttons">
        {answers && answers.map(answer => (
          <Answer
            answer={answer}
            isLongText={question_type === "Overall"}
            formIndex={questionId}
            key={answer.id}
            onChangeHandler={onSelectHandler}
            selectedAnswer={selectedAnswer} />
      ))}
      </div>
    </div>
  )
}

export default BurnoutProofFormSection