import _ from "lodash"
import React, { useEffect, useState } from "react"
import { animateScroll as scroll } from "react-scroll"
import { checkIfDuplicatesInArray } from "../../../../util"
import { MFC_INSTRUCTIONS_ICN } from "../../../Dashboard/Matchfit/constants"
import MatchfitQuestionElement from "../MatchfitQuestionElement"

const MAX_HEART_RATE = 160
const INVALID_SYMBOLS = ["e", "E", "+", "-", "."]

const FormSection = ({
  currentSection,
  form,
  lastPage,
  onChangeHandler,
  onInputChangeHandler,
  onSubmit,
  questionSet,
  setCurrentSection,
  title,
}) => {
  const [subform, setSubform] = useState([])
  const [inflight, setInflight] = useState(false)
  const [userInputDetected, setUserInputDetected] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [invalidHeartRate, setInvalidHeartRate] = useState(false)
  const {
    compulsory_question_slugs: compulsoryQuestionSlugs,
    input_question_slugs: inputQuestionSlugs,
    questions,
    section_title: sectionTitle,
  } = questionSet
  const heartRateQuestion = _.get(questions, "1")
  const heartRateAnswerObject = _.get(questions, "1.answers.0")
  const manualHeartRate = _.get(form, "wears_activity_monitor.answer_slug") === "wears_activity_monitor_1"
  const heartRateQuestionDescriptor =  _.toArray(form).findIndex(x => x.answer_slug === "monitor_tracks_heartbeat_0") !== -1
    ? "Be seated calmly for at least 3-minutes. Use your activity tracker to measure your current heart rate, and enter this value. DO NOT enter the 'average resting heart rate'."
    : "Be seated calmly for at least 3-minutes. Place ﬁrst 2 ﬁngers on thumb-side of opposite wrist (as shown in diagram). When you feel a pulse count the number of beats for 60 seconds."
  const heartRateAnswerLabel = _.toArray(form).findIndex(x => x.answer_slug === "monitor_tracks_heartbeat_0") !== -1 ? heartRateAnswerObject?.label : "Enter the number of beats counted in 60 seconds."

  const onNextStep = () => {
    if (showWarning || userInputDetected) {
      setCurrentSection(currentSection + 1)
    } else {
      setShowWarning(true)
    }
  }

  const onPreviousStep = () => {
    setCurrentSection(currentSection - 2)
  }

  // OnClick handler for the answers
  const onCheckHandler = e => {
    const { name, id } = e.target
    const formCopy = [...subform]
    formCopy[name] = { answer_id: id }
    setSubform(formCopy)
    onChangeHandler(e)

    // Scroll to next question
    const { innerWidth } = window
    scroll.scrollMore(innerWidth <= 770 ? 428 : 498)
  }

  useEffect(() => {
    if (currentSection === questionSet.page_index) {
      // If all questions on this page are Likert, then Warning is skipped
      if (!inputQuestionSlugs.length) {
        setUserInputDetected(true)
      } else if (sectionTitle === "Activity Monitor" && _.get(form, "wears_activity_monitor.answer_slug") === "wears_activity_monitor_1") {
        setUserInputDetected(true)
      // If any input questions on this page are answered, then Warning is skipped
      } else if (checkIfDuplicatesInArray([...Object.keys(form), ...inputQuestionSlugs])) {
        setUserInputDetected(true)
      } else {
        setUserInputDetected(false)
      }
    } else {
      setShowWarning(false)
    }
  }, [currentSection, form])

  useEffect(() => {
    if (currentSection === questionSet.page_index) {
      scroll.scrollToTop()
    }
  }, [currentSection])

  useEffect(() => {
    if (form.heart_rate) {
      setInvalidHeartRate(form.heart_rate?.number_field < 0 || form.heart_rate?.number_field > MAX_HEART_RATE)
    }
  }, [form.heart_rate])

  const onClickSubmit = e => {
    if (userInputDetected && !invalidHeartRate) {
      setInflight(true)
      onSubmit(e)
    } else {
      setShowWarning(true)
    }
  }

  const handleInputChange = e => {
    setUserInputDetected(true)
    onInputChangeHandler(e)
  }

  if (currentSection !== questionSet.page_index) return null
  return (
    <>
      <h1>{title}</h1>
      <hr />
      <MatchfitQuestionElement
        form={form}
        key={`question-${questions[0].id}`}
        onChangeHandler={onCheckHandler}
        onInputChangeHandler={handleInputChange}
        question={questions[0]}
        selectedAnswer={form[questions[0].question_slug]}
        surveyType="matchfit" />

      {(form[questions[0].question_slug] || manualHeartRate) && (
        <>
          <div className="q-group no-scroll">
            <p>{heartRateQuestion?.label}</p>
            {_.toArray(form).findIndex(x => x.answer_slug === "monitor_tracks_heartbeat_0") === -1 && (
              <div>
                <img src={MFC_INSTRUCTIONS_ICN.heart_rate_manually} className="instructions-img" />
              </div>
            )}
            <div className="small">
              <i>{heartRateQuestionDescriptor}</i>
            </div>
            <div className="form-group row">
              <label className="col-5 col-md-3 col-form-label strong">{heartRateAnswerLabel}</label>
              <div className="col-6 col-sm-5">
                <input
                  className="form-control"
                  id={heartRateAnswerObject?.id}
                  inputMode="decimal"
                  name={heartRateQuestion?.question_slug}
                  onChange={onInputChangeHandler}
                  onKeyDown={e => INVALID_SYMBOLS.includes(e.key) && e.preventDefault()}
                  onWheel={e => e.target.blur()}
                  pattern="[0-9]*"
                  type="number"
                  value={_.get(form, ["heart_rate", "number_field"], "")} />
              </div>
            </div>
          </div>
        </>
      )}

      {inflight ? (
        <div className="bottom-cta d-flex justify-content-between">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          {showWarning && !userInputDetected && (
            <div className="bottom-cta mb-5 pb-3 d-flex justify-content-center bg-warning">Note: You have not answered any questions on this page. Please double check before proceeding.</div>
          )}
          {showWarning && invalidHeartRate && (
          <div className="bottom-cta mb-5 pb-3 d-flex justify-content-center bg-warning">Please enter a valid Resting Heart Rate below 160bpm</div>
              )}
          <div className="bottom-cta d-flex justify-content-between">
            {(currentSection !== 0) ? (
              <button
                className="btn btn-light"
                type="button"
                onClick={onPreviousStep}>
                Previous
              </button>
          ) : (<span />)}
            {questionSet.page_index === lastPage ? (
              <button
                className="btn btn-main"
                disabled={_.difference(compulsoryQuestionSlugs, Object.keys(form)).length > 0 || inflight}
                type="button"
                onClick={onClickSubmit}>
                Done
              </button>
          ) : (
            <button
              className="btn btn-main"
              type="button"
              disabled={_.difference(compulsoryQuestionSlugs, Object.keys(form)).length > 0}
              onClick={onNextStep}>
              Next
            </button>
          )}
          </div>
        </div>
      )}
    </>
  )
}

export default FormSection