/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Link } from "react-router-dom"
import { OVERVIEW_PAGE_CONTENT } from "../constants"
import { DashboardBody } from "../styles"

const LiveLifeDashboardEmptyState = ({ unfinishedSurveyExists }) => (
  <DashboardBody>
    <div className="container dashboard" id="dashboard-10dwc">
      <div className="d-flex align-items-center justify-content-between my-4">
        <img src={OVERVIEW_PAGE_CONTENT.live_life.logoSrc} />
      </div>
      <div className="row text-center justify-content-center">
        <div className="col-lg-8">
          <h1 className="mb-4">
            Welcome to the LiveLife Score
          </h1>
          <p>
            An evidence-based physical and psychological wellbeing metric, incorporating both Biological Age and a Mental Fitness Gauge.
          </p>
          <Link className="btn btn-link" to="/about-livelife">What is LiveLife?</Link>
          <div className="text-center mt-5">
            <Link className="btn btn-main" to="/livelife/intro">{`${unfinishedSurveyExists ? "Start" : "Continue"} Survey`}</Link>
          </div>
        </div>
      </div>
    </div>
  </DashboardBody>
)
export default LiveLifeDashboardEmptyState
