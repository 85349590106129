import _ from "lodash"
import React from "react"
import { Link } from "react-router-dom"
import { WELLBEING_CARD_DESC } from "./constants"
import { DashboardBody } from "./styles"

const GlobalDetailPage = ({
  completedAt,
  data,
  responsesList
}) => {
  const latestResult = data[0]
  const previousResult = data[1]
  return (
    <DashboardBody className="content">
      <div className="container dashboard" id="ditem">
        <div className="d-flex align-items-center justify-content-between py-4">
          <Link className="btn btn-outline-main mr-2" to="/ten-dwc-dashboard">Back</Link>
          <div className="start-survey">
            {!!completedAt && <div className="small">{`Last completed on ${completedAt}`}</div>}
          </div>
        </div>
        <div className="dash-row card">
          <div className="card-body">
            <div className="pre-text">10DWC</div>
            <div className="row">
              <div className="col-lg-6">
                <div className="ditem-header">
                  <h1>Global Wellbeing Scale</h1>
                  <p className="mb-0">
                    {WELLBEING_CARD_DESC.global}
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex align-items-center justify-content-lg-end">
                  <div className="ditem-score text-center">
                    <div className="current-score">{latestResult.net_result}</div>
                    <h6>{latestResult.category}</h6>
                    <div className="small">{previousResult ? `Was ${previousResult.net_result}` : ""}</div>
                  </div>
                  <img
                    width={120}
                    src="/assets/icons/10dwc/icn-10dwc-global-f40a82ce9dd05809e59ded2425550bc9409cc699995259d7a5d0cdac311ee8df.png" />
                </div>
              </div>
            </div>
            <ul className="legend list-inline">
              <li className="list-inline-item">Previous</li>
              <li className="list-inline-item">Latest</li>
            </ul>
            <div className="ditem-grid-container global-wellbeing-grid">
              <div id="ditem-grid" />
              <div className="ditem-questions">
                {!!responsesList.length && responsesList.map(res => (
                  <div className="row ditem-questions-item no-gutters" key={res.id}>
                    <div className="col-lg-6 col-xl-5 dimension-question">
                      <div className="text-lg-right">
                        {res.question_label}
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 dimension-graph">
                      <div className="progress" style={{ height: 1, display: "grid" }}>
                        <div
                          // aria-valuemax={100}
                          // aria-valuemin={0}
                          // aria-valuenow={(res.answer_number)}
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${(_.get(res, ["responses", "0", "wellbeing_index"]))}%` }} />
                        {!!_.get(res, ["responses", "1", "wellbeing_index"]) && (
                          <div
                            // aria-valuemax={100}
                            // aria-valuemin={0}
                            // aria-valuenow={(_.get(res, ["responses", "1", "wellbeing_index"]))}
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${(_.get(res, ["responses", "1", "wellbeing_index"]))}%` }} />
                        )}
                      </div>
                    </div>
                  </div>
              ))}
              </div>
            </div>
            <ul className="nav nav-fill ditem-weight">
              <li className="nav-item">1</li>
              <li className="nav-item">25</li>
              <li className="nav-item">50</li>
              <li className="nav-item">75</li>
              <li className="nav-item">100</li>
            </ul>
          </div>
        </div>
      </div>
    </DashboardBody>
  )
}

export default GlobalDetailPage
