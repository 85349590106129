import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { getSocioQuestionSet, createCalculatorResult } from "../../requesters"
import { DashboardBody } from "./styles"

const DemographicsForm = ({ baseUrl, currentUser, match, }) => {
  const [demographicSet, setDemographicSet] = useState([])
  const [responses, setResponses] = useState([])
  const [showJobOther, setShowJobOther] = useState(false)
  const [showGenderOther, setShowGenderOther] = useState(false)
  const { addToast } = useToasts()

  const onChangeHandler = (eventTarget, index) => {
    const newResponseItem = { question_id: eventTarget.name, free_text: eventTarget.value }
    const newResponseArray = [...responses]
    newResponseArray[index] = newResponseItem
    setResponses(newResponseArray)
  }

  const onSelectHandler = (eventTarget, index) => {
    if (eventTarget.value === "Other" && index === 1) {
      setShowJobOther(true)
    } else if (eventTarget.value === "Prefer to self-describe" && index === 2) {
      setShowGenderOther(true)
    } else {
      onChangeHandler(eventTarget, index)
    }
  }

  const onSaveHandler = () => {
    createCalculatorResult(baseUrl, responses.filter(e => e), currentUser).then(res => {
      if (res?.status === 201) {
        // TODO: redirect to intro page for Matchfit
        window.location.href = !match.params.rerouteId ? "/survey/intro" : `/${match.params.rerouteId}/intro`
        addToast("Sociodemographic answers saved successfully", { appearance: "success" })
      } else {
        addToast("Something went wrong", { appearance: "error" })
      }
    })
  }

  const fetchDemographicSet = () => {
    getSocioQuestionSet(baseUrl, currentUser?.active_enrolment?.sociodemographic_id, currentUser).then(res => {
      setDemographicSet(res.response.json.socio_question_set.questions)
    })
  }

  useEffect(() => {
    if (!currentUser.active_enrolment?.sociodemographic_id) {
      window.location.href = "/"
    } else if (currentUser.active_enrolment?.sociodemographic_complete) {
      // TODO: redirect to intro page for Matchfit
      window.location.href = !match.params.rerouteId ? "/survey/intro" : `/${match.params.rerouteId}/intro`
    } else {
      fetchDemographicSet()
    }
  }, [])

  const parseQuestionSet = set => {
    switch (set.question_type) {
      case "select":
        return (
          <div className="form-group" key={set.id}>
            <label>{set.label}</label>
            <select
              className="form-control"
              defaultValue=""
              name={set.id}
              onChange={e => onChangeHandler(e.target, set.sorting_index)}>
              <option value="" disabled>Select</option>
              {set.answers.map(a => (
                <option key={a.id} value={a.label}>{a.label}</option>
              ))}
            </select>
          </div>
        )
      case "job_title_select":
        return (
          <div className="form-group" key={set.id}>
            <label>{set.label}</label>
            <select
              className="form-control"
              defaultValue=""
              name={set.id}
              onChange={e => onSelectHandler(e.target, set.sorting_index)}>
              <option value="" disabled>Select</option>
              {set.answers.map(a => (
                <option key={a.id} value={a.label}>{a.label}</option>
              ))}
            </select>
            {showJobOther && (
              <input
                className="form-control mt-4"
                placeholder="Please specify"
                type="search"
                name={set.id}
                index={set.sorting_index}
                maxLength="50"
                onChange={e => onChangeHandler(e.target, set.sorting_index)} />
            )}
          </div>
        )
      case "gender_select":
        return (
          <div className="form-group" key={set.id}>
            <label>{set.label}</label>
            <select
              className="form-control"
              defaultValue=""
              name={set.id}
              onChange={e => onSelectHandler(e.target, set.sorting_index)}>
              <option value="" disabled>Select</option>
              {set.answers.map(a => (
                <option key={a.id} value={a.label}>{a.label}</option>
              ))}
            </select>
            {showGenderOther && (
              <input
                className="form-control mt-4"
                placeholder="Please specify"
                type="search"
                name={set.id}
                index={set.sorting_index}
                maxLength="50"
                onChange={e => onChangeHandler(e.target, set.sorting_index)} />
            )}
          </div>
        )
      case "binary":
        return (
          <div className="form-group" key={set.id}>
            <label>{set.label}</label>
            <div className="btn-group btn-group-toggle" data-toggle="buttons">
              {set.answers.map(a => (
                <button
                  className="btn btn-survey"
                  type="button"
                  key={a.id}
                  name={set.id}
                  onClick={e => onChangeHandler(e.target, set.sorting_index)}
                  value={a.label}>
                  <input type="radio" />
                  {a.label}
                </button>
              ))}
            </div>
          </div>
        )
      default:
        return (
          <div className="form-group" key={set.id}>
            <label>{set.label}</label>
            <input
              className="form-control"
              placeholder={set.placeholder}
              type="search"
              name={set.id}
              index={set.sorting_index}
              maxLength="50"
              onChange={e => onChangeHandler(e.target, set.sorting_index)} />
          </div>
        )
    }
  }

  const aboutYouSection = demographicSet?.map(q => ([parseQuestionSet(q)]))

  return (
    <DashboardBody>
      <div className="container form-container">
        <h1>Tell us About You</h1>
        {!!aboutYouSection.length && (
          <div className="card">
            <div className="card-header"><h5>About You</h5></div>
            <div className="card-body">
              {aboutYouSection}
            </div>
          </div>
        )}
        <div className="form-container-cta bg-transparent shadow-none p-0 mb-5" disabled={demographicSet.length !== responses.filter(e => e).length}>
          <button
            className="btn btn-main"
            type="submit"
            onClick={onSaveHandler}
            disabled={demographicSet.length !== responses.filter(e => e).length}>
            Save
          </button>
        </div>
      </div>
    </DashboardBody>
  )
}

export default DemographicsForm
