import React from "react"
import _ from "lodash"
import LikertQuestion from "../Question"
import { InputQuestion, MultipleInputQuestion } from "../../Common"
import TableInputQuestion from "../../Common/TableInputQuestion"
// import featureFlags from "../../../util/featureFlags"
import { LLC_INSTRUCTIONS_ICN, MFC_INSTRUCTIONS_ICN } from "../../Dashboard/Matchfit/constants"
import QuestionsWithInstructions from "./QuestionsWithInstructions"

const MatchfitQuestionElement = ({
  form, integerOnly, question, onChangeHandler, onInputChangeHandler, selectedAnswer, surveyType
}) => {
  const {
    answers,
    descriptor,
    dependent_on: dependentOn,
    label,
    question_slug: questionSlug,
    question_type: questionType,
    questions,
    min_value: minValue,
    max_value: maxValue,
    // sorting_index: sortingIndex
  } = question

  if (dependentOn && !(_.toArray(form).map(x => x?.answer_slug)).includes(dependentOn)) {
    return null
  }

  const QUESTIONS_THAT_HAVE_DIAGRAMS = ["waist", "sit_to_stands", "heart_rate", "pushups"]
  const userSex = _.get(form, "sex.free_text_field", "female")

  const imageElement = slug => {
    let iconKeyName = slug
    if (slug === "pushups" && userSex === "male") {
        iconKeyName = "pushups_m"
      }
    let imageSrc = MFC_INSTRUCTIONS_ICN[iconKeyName]
    if (surveyType === "livelife") {
      imageSrc = LLC_INSTRUCTIONS_ICN[`${iconKeyName}`]
    }

    return (
      <div>
        <img src={imageSrc} className="instructions-img" />
      </div>
    )
  }

  const questionElement = () => {
    if (questionSlug === "right_leg_balance") {
      return (
        <MultipleInputQuestion
          label={label}
          answer={answers}
          form={form}
          descriptor={descriptor}
          imageElement={imageElement}
          onChangeHandler={onInputChangeHandler}
          questions={questions}
          questionSlug={questionSlug}
          selectedAnswer={selectedAnswer} />
      )
    }
    if (QUESTIONS_THAT_HAVE_DIAGRAMS.includes(questionSlug)) {
      return (
        <InputQuestion
          answer={answers[0]}
          minValue={minValue}
          maxValue={maxValue}
          descriptor={descriptor}
          formIndex={questionSlug}
          imageElement={imageElement}
          label={label}
          onChangeHandler={onInputChangeHandler}
          questionType={questionType}
          selectedAnswer={selectedAnswer} />
      )
    }
    if (["right_arm_back", "left_arm_back", "touch_toes"].includes(questionSlug)) {
      return (
        <div className="mb-5">
          <QuestionsWithInstructions
            answers={answers}
            descriptor={descriptor}
            formIndex={questionSlug}
            label={label}
            longType
            onChangeHandler={onChangeHandler}
            selectedAnswer={selectedAnswer} />
        </div>
      )
    }
    if (questionType === "multiple_input") {
      return (
        <MultipleInputQuestion
          label={label}
          answer={answers}
          descriptor={descriptor}
          form={form}
          integerOnly={integerOnly}
          // formIndex={id}
          onChangeHandler={onInputChangeHandler}
          questions={questions}
          selectedAnswer={selectedAnswer} />
      )
    }
    if (questionType === "table_input") {
      return (
        <TableInputQuestion
          answer={answers}
          descriptor={descriptor}
          form={form}
          label={label}
          onChangeHandler={onInputChangeHandler}
          questions={question}
          selectedAnswer={selectedAnswer} />
      )
    }
    if (questionType === "input") {
      return (
        <InputQuestion
          answer={answers[0]}
          minValue={minValue}
          maxValue={maxValue}
          descriptor={descriptor}
          formIndex={questionSlug}
          label={label}
          onChangeHandler={onInputChangeHandler}
          questionType={questionType}
          selectedAnswer={selectedAnswer} />
      )
    }
    if (questionType.includes("time_input")) {
      return (
        <InputQuestion
          answer={answers[0]}
          minValue={minValue}
          maxValue={maxValue}
          descriptor={descriptor}
          formIndex={questionSlug}
          label={label}
          onChangeHandler={onInputChangeHandler}
          questionType={questionType}
          selectedAnswer={selectedAnswer} />
      )
    }
    if (questionType === "custom") {
      return (
        <div className="mb-5">
          <LikertQuestion
            answers={answers}
            formIndex={questionSlug}
            label={label}
            onChangeHandler={onChangeHandler}
            selectedAnswer={selectedAnswer}
            surveyType={surveyType} />
        </div>

      )
    }
    if (questionType === "long_custom") {
      return (
        <div className="mb-5">
          <LikertQuestion
            answers={answers}
            descriptor={descriptor}
            formIndex={questionSlug}
            label={label}
            longType
            onChangeHandler={onChangeHandler}
            selectedAnswer={selectedAnswer} />
        </div>
      )
    }
    return (
      <div>weird q type detected</div>
    )
  }

  return (
    <>
      {/* {featureFlags("STAGING") && (
        <>
          <small>{sortingIndex}</small>
          <small>{JSON.stringify(selectedAnswer)}</small>
        </>
      )} */}
      {questionElement()}
    </>
  )
}

export default MatchfitQuestionElement