import startApp from "apps/App/index"
// import startApp from "apps/App/components/App"
import BaseController from "./base"

class Controller extends BaseController {
  call() {
    super.call()
    const rootElem = document.getElementById("react-app")
    if (rootElem) {
      startApp(rootElem)
    }
  }
}

export default Controller
