import React from "react"

export const getMatchFitWellbeingCategory = displayScore => {
  if (displayScore <= 50) {
    return "Languishing"
  }

  if (displayScore <= 65) {
    return "Surviving"
  }

  if (displayScore < 85) {
    return "Striving"
  }

  return "MatchFit"
}

export const generateMatchFitParagraphs = categorySlug => {
  if (categorySlug === "physical_recovery_metric") {
    return (
      <>
        <p>
          Physical Recovery is an assessment of how well you
          {" "}
          <b>Sleep</b>
          , your level of
          {" "}
          <b>Energy</b>
          {" "}
          and time you dedicate to
          {" "}
          <b>Recovery Activities</b>
          , which effects parasympathetic modulation (relaxation) and is essential to sustaining energy and reducing the risk of burnout and fatigue.
        </p>
        <p>It is well accepted that the human body thrives in response to positive stressors as they result in motivation and accomplishment. Without adequate recovery stress reactions accumulate, resulting in decreased cognitive ability and increased risk of burnout and physical and mental morbidity (the condition of having a disease).  Finding the balance between stress and recovery can be a fine line for high performers yet is an essential component that underpins the ability to reach your optimal levels of performance and wellbeing.</p>
      </>
    )
  }

  if (categorySlug === "physical_functioning_metric") {
    return (
      <>
        <p>
          Physical Functioning is an assessment of
          {" "}
          <b>Posture, Balance</b>
          {" "}
          and
          {" "}
          <b>Flexibility</b>
          {" "}
          and refers to the ability to perform basic activities of daily living. Physical function is an important predictor of health outcomes in older adults. The capacity to perform basic physical functions is a central aspect of health-related quality of life and a key predictor of hospitalisation, surgical outcomes, and mortality.
        </p>
        <p>Physical Functioning refers to mobility, balance, and postural alignment.  These influence the positions adopted during daily tasks, the ability to move freely, and stability throughout the day. Better functional abilities relate to lower risk of injury, falls, and bodily pain, as well as greater movement control and movement competency.</p>
      </>
    )
  }

  if (categorySlug === "nutrition_performance_metric") {
    return (
      <>
        <p>Nutrition underpins your cellular function, metabolism, energy levels and brain function. Many people do not understand how nutrition affects all aspects of the body and brain. A healthy eating pattern is fundamental to brain and body wellbeing, as optimal nutrition benefits almost every aspect of your health.</p>
        <p>The Nutrition Barometer assesses your knowledge and modifiable behaviours associated with high performance and wellbeing. These factors include hydration, alcohol and caffeine consumption, intake of vegetables, protein, fats, carbohydrates, sugar and processed foods, as well as eating and cooking habits. Even if you believe you have a varied and healthy diet, there is always room for improvement and/or variety.</p>
      </>
    )
  }

  if (categorySlug === "movement_metric") {
    return (
      <>
        <p>
          Movement is an assessment of
          {" "}
          <b>Cardio-Respiratory Fitness, Daily Steps, Resistance Training</b>
          {" "}
          and
          {" "}
          <b>Flexibility</b>
          {" "}
          Physical activity can take many different forms and occur in multiple locations. The MatchFit Calculator doesn’t only monitor formal exercise, it accounts for movement across the day including mobility, flexibility and strength.
        </p>
        <p>Regular formal (structured) exercise does not guarantee a high score in this category. Some people fall into the category of ‘the active couch potato’ exercising hard a few times each week then sitting around the rest of the time. The objective is to include both formal exercise and incidental movement throughout the day.</p>
      </>
    )
  }

  if (categorySlug === "social_connectedness_metric") {
    return (
      <>
        <p>
          Social Connectedness is an assessment of your sense of
          {" "}
          <b>Isolation/Loneliness, Relationship Satisfaction</b>
          {" "}
          and sense of
          {" "}
          <b>Social Support.</b>
        </p>
        <p>Humans crave connection. For thousands of years, we have lived and worked in communities - connection is fundamental to the way we experience the world. Being truly connected provides purpose and gives our lives meaning and pleasure.</p>
        <p>Social connectedness measures aspects of loneliness, relationship satisfaction and perceived social support. Mean- ingful relationships and a sense of belonging are critical for wellbeing in an age of constant mobility and connectivity. Although technology has made people more digitally connected than ever, more people in society are reporting that they feel alone. Loneliness activates physical and psychological stress responses, depresses the immune system, increases risk of cardiovascular disease and represents as great a risk for long-term health and longevity as smoking. Conversely, social support and relationship satisfaction, especially a good network of friends and a healthy marriage/stable relationship are associated with better health, lower premature mortality and longevity.</p>
      </>
    )
  }

  if (categorySlug === "psychological_performance_metric") {
    return (
      <>
        <p>
          Psychological Performance incorporates
          {" "}
          <b>Flow, Self Actualisation/Mastery</b>
          {" "}
          , sense of
          {" "}
          <b>Focus</b>
          {" "}
          sense of
          {" "}
          <b>Control.</b>
        </p>
        <p>People with a greater sense of purpose and direction are more likely to outlive their peers, have more positive feelings and lower physical symptoms, and earn higher incomes compared to those with a low sense of life purpose. Focus is considered most important during stressful or anxiety-inducing tasks where pressure/anxiety creates a distraction and shifts attention to worrying about the situation and its consequences, a cognitive load that can decrease performance. Perceived control relates to the ability to decide for yourself what to do and the ability to take time to contemplate and reflect, gaining greater insight into feelings. Greater perceived con- trol is associated with high levels of optimism, lower psychological stress and decreased risk of professional burnout.</p>
      </>
    )
  }

  if (categorySlug === "emotional_wellbeing_metric") {
    return (
      <>
        <p>
          Emotional Wellbeing is an assessment of
          {" "}
          <b>Satisfaction with Life and Mood, Happiness, Depression and Anxiety Symptoms</b>
          {" "}
          and your
          {" "}
          <b>Stress Coping.</b>
        </p>
        <p>Emotional wellbeing is the ability to produce positive emotions, moods, thoughts, and feelings, and adapt when con- fronted with adversity and stressful situations. Resilience and the ability to bounce back/reframe after challenging life events is a core foundation of Emotional Wellbeing.</p>
        <p>Emotional wellbeing is a very important part of holistic wellbeing and impacts outlook on life, relationships, and mental health. Emotional Wellbeing is learning to respond in a calm and measured way, rather than immediately reacting or flying off the handle. This involves thinking through what you want to happen in an interaction involving a challenging conversation or conflict. High levels of emotional wellbeing enable you to be measured, thoughtful, and provides a foundation for creativity and adaptability.</p>
      </>
    )
  }

  if (categorySlug === "cognitive_flex_metric") {
    return (
      <>
        <p>
          Cognitive flexibility is the ability to achieve goals/tasks by adapting mental models and behaviours to new envi- ronments. This includes the ability to switch between different tasks and corresponding behavioural responses. Cognitive Flexibility includes
          {" "}
          <b>Growth Mindset, Openness and Adaptability to Change</b>
          {" "}
          and
          {" "}
          <b>Grit.</b>
        </p>
        <p>Cognitive Flexibility provides the ability to see that what we are doing that is/is not leading to success and to sustain/make appropriate changes. Cognitive flexibility can have a positive effect on inherent biases and is associated with higher resilience to negative life events, as well as better quality of life as we get older. Cognitive flexibility expands sense of awareness, increases ability to quickly adapt to new situations and increases your likelihood of success.</p>
      </>
    )
  }

  if (categorySlug === "psychological_detachment_metric") {
    return (
      <>
        <p>
          Psychological Detachment is an assessment of your ability to
          {" "}
          <b>Switch Off</b>
          {" "}
          from work out of hours, allocate time for
          {" "}
          <b>Play</b>
          {" "}
          activities and spending time in
          {" "}
          <b>Nature.</b>
        </p>
        <p>Psychological detachment refers to not constantly working or thinking about job-related issues after work. Lower levels of psychological detachment from work are associated with a higher proneness to exhaustion and reduced levels of work engagement the next day. Psychological detachment is essential to achieving sustainable high performance at work and to maintaining optimum health and wellbeing. Regular doses of nature enhance mental health, reduce stress levels and improve relaxation and wellbeing.</p>
      </>
    )
  }

  return null
}

export const MFC_CATEGORIES = {
  biological_age_metric: {
    heading: "Biological Age",
    categoryType: "Body",
    srcLink: "/assets/icons/matchfit/MFC-Bio-age-4670c95be4d056e1ba67ef5024b9604ecd3124464020eb05384513f29067ec6b.svg",
    goal: "Biological Age at least five years below Chronological Age i.e. 5 years younger biologically",
  },
  physical_functioning_metric: {
    heading: "Physical Functioning",
    categoryType: "Body",
    srcLink: "/assets/icons/matchfit/MFC-Physical-following-fcc95d1c5ff859936d98cb34ef6ac7c720f2dab4fd4d9d3e6952fe2b7e40e714.svg",
    goal: "Scores above 85 percent. You have a strong physical foundation.",
  },
  physical_recovery_metric: {
    heading: "Physical Recovery",
    categoryType: "Body",
    srcLink: "/assets/icons/matchfit/MFC-Physical-recovery-bb76a688bc38e059f95afdeecbc8537caf159a8478cbc368dcd5dd0b0057841c.svg",
    goal: "Scores above 85 percent. Ensure you wake feeling refreshed and allocate specific time to recovery activities.",
  },
  nutrition_performance_metric: {
    heading: "Nutrition Performance",
    categoryType: "Body",
    srcLink: "/assets/icons/matchfit/MFC-Nutrition-performance-bbf30a692779963b31d5cefee8b5ab6c0e0a00483fc2d06497a2ba0aaeb5e6ee.svg",
    goal: "Scores above 85 percent. You are eating a healthy balance diet.",
  },
  movement_metric: {
    heading: "Movement",
    categoryType: "Body",
    srcLink: "/assets/icons/matchfit/MFC-Movement-64261814a73c7824eff55a797c01324b998be27eab9fc149a6fa6e56a0e1fc85.svg",
    goal: "Scores above 85 percent. At least: 10,000 steps per day; 150 minutes of exercise per week; two sessions of resistance training per week; three sessions of flexibility training per week.",
  },
  social_connectedness_metric: {
    heading: "Social Connectedness",
    categoryType: "Brain",
    srcLink: "/assets/icons/matchfit/MFC-Social-connectedness-f069e25c1d2986ffe3e7ff17c1bf899d31459f75eeb81874c0c415f757a2adb7.svg",
    goal: "Scores above 85 percent. You are satisfied with life and cope well with stress.",
  },
  psychological_performance_metric: {
    heading: "Psychological Performance",
    categoryType: "Brain",
    srcLink: "/assets/icons/matchfit/MFC-Psychological-performance-ed336445a0359a13d0097f28a53122be40fd5261d592814a93af8d67843ae88d.svg",
    goal: "Scores above 85 percent. You have a clear sense of purpose and are able to focus and control your peak performance.",
  },
  emotional_wellbeing_metric: {
    heading: "Emotional Wellbeing",
    categoryType: "Brain",
    srcLink: "/assets/icons/matchfit/MFC-Emotional-wellbeing-b104c3c11b1ad388c56d70608685191e6e3f72eef9ea3c85f481c0f444a4459a.svg",
    goal: "Scores above 85 percent. You are satisfied with life and cope well with stress.",
  },
  cognitive_flex_metric: {
    heading: "Cognitive Flexibility",
    categoryType: "Brain",
    srcLink: "/assets/icons/matchfit/MFC-Cognitive-flexibility-5ffa447c29b012be61050c2ca73bb4ea5047205c4fdfd665e558d79d1aa40dc5.svg",
    goal: "Scores above 85 percent. You are open to change, learning and adaptation.",
  },
  psychological_detachment_metric: {
    heading: "Psychological Detachment",
    categoryType: "Brain",
    srcLink: "/assets/icons/matchfit/MFC-Psychological-Detachment-e540e3cc366a53915a0a777cd0dd1d75d57e303e37f52f002f82932f16915059.svg",
    goal: "Scores above 85 percent. You make time to relax and switch of from thinking about work.",
  },
}

export const MFC_INSTRUCTIONS_ICN = {
  waist: "/assets/icons/matchfit/Measure@2x-b32a286712264d7bdb256cd0b3114c7be81e79707c1e152e29b7b258eb2cb737.png",
  pushups_m: "/assets/icons/matchfit/Push-Ups-M@2x-b62d5912a0d6bad7289370b9cf96833e9bb661a25cedad026f12ddf6ca38fc98.png",
  pushups: "/assets/icons/matchfit/Push-Ups-F@2x-18e012f018473d59901d2181519b19e7d76cd3d7515825408aabb2ce8f3eb623.png",
  sit_to_stands: "/assets/icons/matchfit/Sit-to-Stand@2x-f0bac4b0fb7ac61ae965a2dd740ad2b483eced17775b428cb3c25c1869044ba4.png",
  right_leg_balance: "/assets/icons/matchfit/Balance@2x-9ffb156e8f31a622a8ee33f2a1bad75bab6cd5f5667b3b0c41ad9c860d7cb232.png",
  right_arm_back: "/assets/icons/matchfit/Reach@2x-Flipped-2f4cccb6628a4b4d9837edd2239b11ed696e7e5727993f829dc9c5fa9aafc59a.png",
  left_arm_back: "/assets/icons/matchfit/Reach@2x-be6c3de2ec30f48e7449412a7678c802fd1223a10f9362faf9794f06b4bccf75.png",
  touch_toes: "/assets/icons/matchfit/Stretch@2x-44026661d232263c5fbe3f4f26b467bd52e4654804b703a2a27febfc337c20f1.png",
  heart_rate_manually: "/assets/icons/matchfit/Heartrate@2x-539169e0fa12d16ac6174cb8918a2bf432d445301adcdde0a10e2ba516916998.png"
}

export const LLC_INSTRUCTIONS_ICN = {
  ...MFC_INSTRUCTIONS_ICN,
  pushups: "/assets/icons/livelife/Pushup_f@2x-446efff523c11c531e9fe8dd6b68037efe3166e642c3935cac6c82e21f7f84e4.png",
  pushups_m: "/assets/icons/livelife/Pushup_m@2x-2fcae2ca5a524e7d90eec5f5475ee149aa090521d2c1250601b685419a68123e.png"
}