import React from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"
import { OVERVIEW_PAGE_CONTENT } from "../../Dashboard/constants"

const StyledNav = styled.nav`
  img:first-child {
    width: 64px;
    margin-right: 1em;
  }
  img:last-child {
    display: none;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`

const Header = ({ form, onSaveHandler }) => (
  <div className="fixed-top">
    <StyledNav className="navbar navbar-light bg-white">
      <div>
        <Link to="/overview">
          <img src="/assets/icons/icn-back-009e79b5747b122738def7d3b12743f2fc8a033afbefde260aa458eb38a9f2db.svg" style={{ width: "30px" }} />
          <img style={{ width: "0px" }} />
        </Link>
        <Link to="/matchfit-dashboard">
          <img src={OVERVIEW_PAGE_CONTENT.matchfit.logoSrc} width={64} height={64} />
          <img src={OVERVIEW_PAGE_CONTENT.matchfit.textSrc} width={240} height={36} />
        </Link>
      </div>
      {onSaveHandler && <button className="btn btn-outline-main btn-sm" type="button" onClick={onSaveHandler}>Save Draft</button>}
    </StyledNav>
    <div className="progress survey-progress">
      <div
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={0}
        style={{ width: `${form?.length / 48 * 100}%` }}
        className="progress-bar"
        role="progressbar" />
    </div>
  </div>
)

export default withRouter(Header)