/* eslint-disable no-unused-expressions */
import axios from "axios"
import headers from "../../util/headers"

// export const getOrganisations = (baseUrl, currentUser) => {
//   const path = `${baseUrl}/backend/organisations`
//   return axios.get(path, headers(currentUser))
//     .then(({ data, status }) => ({ response: data, status }))
//     .catch(error => { error })
// }

export const createOrganisation = (baseUrl, organisation, currentUser) => {
  const path = `${baseUrl}/backend/organisations`
  return axios.post(path, organisation, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getOrganisationDetail = (baseUrl, organisation_id, currentUser) => {
  const path = `${baseUrl}/backend/organisations/details`
  return axios.post(path, { params: { id: organisation_id } }, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

// export const updateOrganisationDetail = (baseUrl, form, currentUser) => {
//   const path = `${baseUrl}/backend/organisations/details`
//   return axios.put(path, form, headers(currentUser))
//     .then(({ data, status }) => ({ response: data, status }))
//     .catch(error => { error })
// }