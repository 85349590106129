import React, { useState } from "react"
import styled from "styled-components"
import Answer from "../Answer"

const Slider = styled.input`
  width: 80%;

  ::-webkit-slider-thumb {
    ${props => (!props.showThumb && "opacity: 0")};
  }
`

const PulseCheckQuestion = ({
  invalidInputId,
  question: {
    answers, label, question_type, question_slug, slider_label_one, slider_label_two
  },
  questionId,
  selectedAnswer,
  onInputChangeHandler,
  onSliderChangeHandler,
  onSelectHandler,
}) => {
  const value = question_type === "input" ? selectedAnswer?.float_field : selectedAnswer?.number_field
  const [showThumb, setShowThumb] = useState(false)
  const buttonGroupClass = answers.length > 5 ? "btn-group btn-group-toggle horizontal" : "btn-group btn-group-toggle"

  const handleSliderThumbShow = () => {
    if (!showThumb) {
      setShowThumb(true)
      onSliderChangeHandler({
        target: {
          id: answers[0]?.id,
          name: questionId,
          value: 0,
        },
      })
    }
  }

  const handleReversedSliderThumbShow = () => {
    if (!showThumb) {
      setShowThumb(true)
      onSliderChangeHandler({
        target: {
          id: answers[0]?.id,
          name: questionId,
          value: 10,
        },
      })
    }
  }

  switch (question_type) {
    case "slider_7":
      return (
        <div className="q-slider-group" onMouseEnter={handleSliderThumbShow}>
          <p className="slider-label">{label}</p>
          <div className="slider-container">
            <Slider
              id={answers[0]?.id}
              min="0"
              max="7"
              className="custom-range"
              name={questionId}
              onChange={onSliderChangeHandler}
              showThumb={showThumb}
              type="range"
              value={value || 0} />
            <div>{value}</div>
          </div>
          <div className="slider-bottom-labels">
            <div>{question_slug === "alcohol_free_days" ? "0 AFDs" : "Strongly Disagree"}</div>
            <div>{question_slug === "alcohol_free_days" ? "7 AFDs" : "Strongly Agree"}</div>
          </div>
        </div>
      )
      case "slider_10":
        return (
          <div className="q-slider-group" onMouseEnter={handleSliderThumbShow}>
            <p className="slider-label">{label}</p>
            <div className="slider-container">
              <Slider
                id={answers[0]?.id}
                min="0"
                max="10"
                className="custom-range"
                name={questionId}
                onChange={onSliderChangeHandler}
                showThumb={showThumb}
                type="range"
                value={value || 0} />
              <div>{value}</div>
            </div>
            <div className="slider-bottom-labels">
              <div>{slider_label_one}</div>
              <div>{slider_label_two}</div>
            </div>
          </div>
        )
    case "slider_10_reversed":
      return (
        <div className="q-slider-group" onMouseEnter={handleReversedSliderThumbShow}>
          <p className="slider-label">{label}</p>
          <div className="slider-container">
            <Slider
              id={answers[0]?.id}
              min="0"
              max="10"
              className="custom-range"
              name={questionId}
              onChange={onSliderChangeHandler}
              showThumb={showThumb}
              type="range"
              value={value || 10} />
            <div>{value}</div>
          </div>
          <div className="slider-bottom-labels">
            <div>{slider_label_one}</div>
            <div>{slider_label_two}</div>
          </div>
        </div>
      )
    case "input":
      return (
        <div className="q-group pulse-input">
          <p>{label}</p>
          {/* {descriptor && (
            <div className="mb-3 small">
              <i>{descriptor}</i>
            </div>
          )} */}
          <div className="form-group row">
            <label className="col-5 col-md-3 col-form-label strong">{answers[0].label}</label>
            <div className="col-6 col-sm-5">
              <input
                className="form-control"
                id={answers[0]?.id}
                inputMode="decimal"
                name={questionId}
                onChange={onInputChangeHandler}
                onWheel={e => e.target.blur()}
                pattern="[0-9]*"
                type="number"
                value={value} />
              {invalidInputId === answers[0]?.id && <div className="err-msg text-danger">The value must be numeric.</div>}
            </div>
          </div>
        </div>
      )
      case "custom":
        return (
          <div className={answers?.length > 6 ? "q-group no-scroll" : "q-group"}>
            <p>{label}</p>
            {/* {descriptor && (
                        <div className="mb-3 small">
                          <i>{descriptor}</i>
                        </div>
                      )} */}
            <div className={buttonGroupClass} data-toggle="buttons">
              {answers && answers.map(answer => (
                <Answer
                  answer={answer}
                  formIndex={questionId}
                  key={answer.id}
                  onChangeHandler={onSelectHandler}
                  selectedAnswer={selectedAnswer} />
                        ))}
            </div>
          </div>
        )
    default:
      return (
        <div className="q-group">
          <p>{label}</p>
          {/* {descriptor && (
            <div className="mb-3 small">
              <i>{descriptor}</i>
            </div>
          )} */}
          <div className={buttonGroupClass} data-toggle="buttons">
            {answers && answers.map(answer => (
              <Answer
                answer={answer}
                formIndex={questionId}
                key={answer.id}
                onChangeHandler={onSelectHandler}
                selectedAnswer={selectedAnswer} />
            ))}
          </div>
        </div>
      )
  }
}

export default PulseCheckQuestion
