import React from "react"

const SystemQuestionCard = ({
  currentQuestion,
  handleChange,
  index,
  systemQuestionList,
}) => (
  <>
    <div className="form-group">
      <label>{`Question ${index + 1}`}</label>
      <textarea
        className="form-control form-control-lg"
        disabled
        name="label"
        type="text"
        value={currentQuestion.label} />
    </div>
    <div className="form-group">
      <label>Dashboard Description</label>
      <textarea
        className="form-control form-control-lg"
        disabled
        name="descriptor"
        type="text"
        value={currentQuestion.descriptor} />
    </div>
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <label>Dashboard Label</label>
          <input
            className="form-control"
            disabled
            name="question_label"
            type="text"
            defaultValue={currentQuestion.question_label} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label>Target Score Label</label>
          <input
            className="form-control"
            disabled
            type="text"
            value={currentQuestion.target_score} />
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="form-group">
          <label>System Question</label>
          <select
            className="form-control"
            value={currentQuestion.question_slug || "steps_per_day"}
            onChange={handleChange}>
            {systemQuestionList.map(q => (
              <option
                key={q.question_slug}
                value={q.question_slug}>
                {q.question_label}
              </option>
              ))}
          </select>
        </div>
      </div>
    </div>
    {/* DEVNOTE: workaround to fix the weird width display issues on system Q's */}
    <div className="row" style={{ width: 640 }} />
  </>
)

export default SystemQuestionCard