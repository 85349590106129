import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { animateScroll as scroll } from "react-scroll"

import PulseCheckHeader from "./PulseCheckHeader"
import PulseCheckQuestion from "./PulseCheckQuestion"
import { getPulseCheck } from "../../../requesters/CalculatorRequester"
import { submitPulseCheckSurvey } from "../../../requesters/CalculatorResultsRequester"

const PulseCheckFormPage = ({ baseUrl, currentUser }) => {
  const [questions, setQuestions] = useState()
  const [form, setForm] = useState({})
  const [totalQuestionLength, setTotalQuestionLength] = useState(0)
  const [inflight, setInflight] = useState(false)
  const [invalidInputId, setInvalidInputId] = useState(false)
  const { addToast } = useToasts()

  const fetchQuestionSet = () => {
    if (!currentUser?.active_pulse_id) {
      window.location.href = "/overview"
    } else {
      getPulseCheck(baseUrl, currentUser.active_pulse_id, currentUser).then(res => {
        setQuestions(res.response.json.calc.questions)
        setTotalQuestionLength(res.response.json.calc.question_length)
      })
    }
  }

  useEffect(() => {
    // Route to linking code page when User not enrolled in an active Pulse Check
    if (!currentUser?.active_pulse_id) {
      window.location.href = "/overview"
    } else {
      fetchQuestionSet()
      scroll.scrollTo(15)
    }
  }, [])

  useEffect(() => {
    const invalidInputDetected = _.toArray(form).find(item => item.float_field === "")
    setInvalidInputId(invalidInputDetected?.answer_id)
  }, [form])

  // Route to sociodemographic questions when they are not complete
  if (!currentUser.active_enrolment?.sociodemographic_complete) {
    window.location.href = "/demographic/pulse-check"
    return null
  }

  const onInputChangeHandler = e => {
    const { id, name } = e.target
    const formCopy = { ...form }
    formCopy[name] = { answer_id: id, float_field: e.target.value }
    setForm(formCopy)
  }

  const onSelectHandler = e => {
    const { id, name } = e.target
    const formCopy = { ...form }
    formCopy[name] = { answer_id: id }
    setForm(formCopy)

    // Scroll to next question
    const { innerWidth } = window
    scroll.scrollMore(innerWidth <= 770 ? 572 : 270)
  }

  const onSliderChangeHandler = e => {
    const { id, name, value } = e.target
    const formCopy = { ...form }
    formCopy[name] = { answer_id: id, number_field: value }
    setForm(formCopy)
  }

  const onSubmit = () => {
    setInflight(true)
    const payload = _.toArray(form)
    submitPulseCheckSurvey(baseUrl, { payload }, currentUser).then(res => {
      if (res.response?.status === "ok") {
        window.location.href = `/pulse-check/thank-you`
      }
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }

  return (
    <div className="container">
      <PulseCheckHeader
        form={form}
        inflight={inflight}
        invalidInput={!!invalidInputId}
        totalQuestionLength={totalQuestionLength}
        onSubmit={onSubmit} />
      <h1>Pulse Check</h1>
      <p className="mb-0">
        Pulse Check is a short survey to help you track your progress throughout the program.
      </p>
      <p className="mt-0">
        Please answer all questions. Click on the &quot;Done&quot; button on the top right-hand corner when complete.
      </p>
      <hr />
      {!!questions && questions.map(question => (
        <PulseCheckQuestion
          key={`question-${question.id}`}
          invalidInputId={invalidInputId}
          onInputChangeHandler={onInputChangeHandler}
          onSelectHandler={onSelectHandler}
          onSliderChangeHandler={onSliderChangeHandler}
          question={question}
          questionId={question.id}
          selectedAnswer={form[question.id]} />
      ))}
    </div>
  )
}

export default PulseCheckFormPage
