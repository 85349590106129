/* eslint-disable no-unused-expressions */
import axios from "axios"
import headers from "../util/headers"

export const checkEmailAvailability = (baseUrl, emailAddress) => {
  const path = `${baseUrl}/users/email_availability`
  return axios.get(path, { params: { email: emailAddress } })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const updateProgramLink = (baseUrl, unique_id, currentUser) => {
  const path = `${baseUrl}/users/link_to_program`
  return axios.post(path, { unique_id }, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const sendKajabiAccessRequest = (baseUrl, currentUser) => {
  const path = `${baseUrl}/users/request_kajabi_access`
  return axios.post(path, {}, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const sendLearnWorldAccessRequest = (baseUrl, program_id, currentUser) => {
  const path = `${baseUrl}/users/request_learnworld_access`
  return axios.post(path, { program_id }, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    // .catch(error => { error })
}

export const updateUserPassword = (baseUrl, data, currentUser) => {
  const path = `${baseUrl}/users/update`
  return axios.patch(path, data, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}

export const resendEmailConfirmation = (baseUrl, currentUser) => {
  const path = `${baseUrl}/users/resend_verification`
  return axios.post(path, {}, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}

export const getUserOverview = (baseUrl, currentUser) => {
  const path = `${baseUrl}/users/overview`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const updateUser = (baseUrl, data, currentUser) => {
  const path = `${baseUrl}/users`
  return axios.put(path, data, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}
