import React from "react"
import { MFC_CATEGORIES } from "./constants"

const MatchfitBiologicalAgeDetails = ({ categoryDetails }) => {
  const convertToPercentage = score => {
    if (score < 0) {
      return (Math.abs(score) * 10)
    }
    if (score > 0) {
      return ((score / 15) * 100)
    }

    return score
  }

  return (
    <>
      <div className="pre-text">MatchFit - Body</div>
      <div className="row">
        <div className="col-lg-7">
          <div className="ditem-header">
            <h1>Biological Age</h1>
            <p>While date of birth tells how old you are chronologically, Biological Age indicates how well your body is functioning right now. Biological Age calculates physical fitness, nutrition, metabolic health and lifestyle factors that have been identified in scientific studies as major contributors to morbidity (chronic disease) and longevity. The Biological Age assessment ranks you against expected values (adjusted for age and sex), based on the best available scientific evidence. If you rank well, your Biological Age will be lower than your chronological age, with the overall goal to be 5 years younger. And it will be higher than your chronological age if you have known health and longevity behavioral risk factors.</p>
            <p>The chart below shows the age increment for each of the sub-measures that are used to calculate your total Biological Age increment, either positive (not good) or negative (good). You simply add the total age change to your chronological age to provide an estimate of your Biological Age.</p>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="d-flex align-items-center justify-content-lg-end">
            <div className="ditem-score text-center">
              <div className="current-score">{`${categoryDetails?.biological_age} years`}</div>
              <div className="mt-2">
                <hr />
                <table className="table table-sm table-borderless">
                  <tbody>
                    <tr>
                      <td className="text-left"><strong>Biological Age</strong></td>
                      <td className="text-right"><strong>{categoryDetails?.biological_age}</strong></td>
                    </tr>
                    <tr>
                      <td className="text-left">Chronological Age</td>
                      <td className="text-right">{categoryDetails?.age}</td>
                    </tr>
                    <tr>
                      <td className="text-left">Difference</td>
                      <td className="text-right">{categoryDetails?.age_change}</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <div className="goal">
            <strong>Goal:</strong>
            {" "}
            <i>{MFC_CATEGORIES.biological_age_metric.goal}</i>
          </div>
        </div>
      </div>
      <ul className="legend list-inline">
        {/* TODO: check the dot color */}
        <li className="list-inline-item">Biological Age Increment in Years</li>
      </ul>
      <div className="ditem-grid-container mental-skills bio-age">
        <div id="ditem-grid" />
        <div className="ditem-questions">
          {!!categoryDetails?.subcategories.length && categoryDetails?.subcategories.map(sub => (
            <div className="row ditem-questions-item no-gutters" key={sub.id}>
              <div className="col-lg-6 col-xl-5 dimension-question">
                <div className="mr-4 d-inline-flex align-items-center">
                  <h6 className="mb-0">{sub.label}</h6>
                </div>
              </div>
              <div className="col-lg-6 col-xl-7 dimension-graph">
                <div className={`progress ${sub.score < 0 ? "progress negative" : null}`} style={{ height: "1px", display: "grid" }}>
                  <div className="progress-bar" role="progressbar" style={{ width: `${convertToPercentage(sub.score)}%` }} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="nav nav-fill ditem-weight">
        <li className="nav-item">-10</li>
        <li className="nav-item">-5</li>
        <li className="nav-item">0</li>
        <li className="nav-item">5</li>
        <li className="nav-item">10</li>
        <li className="nav-item">15</li>
      </ul>
    </>
  )
}

export default MatchfitBiologicalAgeDetails
