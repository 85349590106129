/* eslint-disable no-unused-expressions */
import axios from "axios"
import headers from "../../util/headers"

// Not working for some reason
// export const getProgramDetail = (baseUrl, program_id) => {
//   const path = `${baseUrl}/backend/programs/${program_id}`
//   return axios.get(path)
//     .then(({ data, status }) => ({ response: data, status }))
//     .catch(error => { error })
// }

export const getExportsList = (baseUrl, currentUser) => {
  const path = `${baseUrl}/backend/programs/exports_list`
  return axios.get(path, { ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getProgramDetail = (baseUrl, program_id, currentUser) => {
  const path = `${baseUrl}/backend/programs/details`
  return axios.get(path, { params: { id: program_id }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getProgramStats = (baseUrl, params, currentUser) => {
  const path = `${baseUrl}/backend/programs/stats`
  return axios.get(path, { params, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

// Not working for some reason
// export const updateProgramDetail = (baseUrl, program_id, form) => {
//   const path = `${baseUrl}/backend/programs/${program_id}`
//   return axios.patch(path, form)
//     .then(({ data, status }) => ({ response: data, status }))
//     .catch(error => { error })
// }

export const updateProgramDetail = (baseUrl, form, currentUser) => {
  const path = `${baseUrl}/backend/programs/details`
  return axios.put(path, form, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getPrograms = (baseUrl, currentUser, status) => {
  const path = `${baseUrl}/backend/programs${status ? `?status=${status}` : ""}`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const createProgram = (baseUrl, program, currentUser) => {
  const path = `${baseUrl}/backend/programs`
  return axios.post(path, program, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const archiveProgram = (baseUrl, id, new_status, currentUser) => {
  const path = `${baseUrl}/backend/programs/archive`
  return axios.post(path, { id, new_status }, headers(currentUser))
    // .then(({ data, status }) => ({ response: data, status }))
    // .catch(error => { error })
}

export const addCalculator = (baseUrl, params, currentUser) => {
  const path = `${baseUrl}/backend/programs/add_calculator`
  return axios.post(path, params, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getProgramsWithStats = (baseUrl, currentUser) => {
  const path = `${baseUrl}/backend/programs/stats`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
