/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { Link } from "react-router-dom"

const BackendHeader = ({ currentUser }) => (
  <nav className="navbar navbar-light bg-light">
    <div className="d-flex align-items-center">
      <Link
        className="navbar-brand"
        to="/backend">
        <img src="/assets/ss-logo-8839d9e24fca0b324b5083eb6616dc26fa5bd8dd92c8072bbe29439db5ecb553.svg" className="ss-logo" />
      </Link>
      <div className="header-link-container pt-3">
        <Link
          className="header-link ml-5"
          to="/backend">
          Organisation & Programs
        </Link>
        <Link
          className="header-link ml-5"
          to="/backend/users">
          Users
        </Link>
        <Link
          className="header-link ml-5"
          to="/backend/templates">
          Templates
        </Link>
        <Link
          className="header-link ml-5"
          to="/backend/exports">
          Exports
        </Link>
        <Link
          className="header-link ml-5"
          to="/backend/tags">
          Tags
        </Link>
      </div>
    </div>
    <div className="dropdown">
      <a aria-expanded="false" aria-haspopup="true" className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" id="navbarDropdown" role="button"><span>{currentUser.firstname}</span></a>
      <div aria-labelledby="navbarDropdown" className="dropdown-menu dropdown-menu-right">
        <Link
          className="dropdown-item"
          to="/">
          Home
        </Link>
        {/* <div className="dropdown-divider" />
        <Link
          className="dropdown-item"
          to="/backend">
          Programs
        </Link>
        <Link
          className="dropdown-item"
          to="/backend/templates">
          Templates
        </Link>
        <Link
          className="dropdown-item"
          to="/backend/users">
          Users
        </Link>
        <Link
          className="dropdown-item"
          to="/backend/mis">
          MIS Stats
        </Link>
        <Link
          className="dropdown-item"
          to="/backend/exports">
          Recent Exports
        </Link> */}
        {/* <a className="dropdown-item" href="/10DWC-design">Home</a>
          <a className="dropdown-item" href="settings">Settings</a> */}
        <div className="dropdown-divider" />
        <Link
          className="dropdown-item"
          to="/logout"
          onClick={() => { window.location.href = "/logout" }}>
          Log out
        </Link>
      </div>
    </div>
  </nav>
)

export default BackendHeader