import React from "react"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"
import styled from "styled-components"

const Container = styled.div`
  margin: auto;
  max-height: 180px;
  max-width: 180px;
  width: 100%;
`

const IndexNumber = styled.div`
  font-size: 3rem;
  font-weight: bold;

  @media (max-width: 420px){
    font-size: 1.8rem;
    text-align: center;
  }
`

const IndexScore = styled.div`
  margin-top: -16px;
  font-size: 1.5rem;
  font-weight: bold;

  @media (max-width: 420px){
    font-size: 1.3rem;
  }
`

const IndexGaugeSmallLlsBioAge = ({
  barIndex, index, color
}) => (
  <Container className="gauge-container">
    <CircularProgressbarWithChildren
      value={barIndex}
      // ratio sets the limits of the circle gauge
      circleRatio={0.80}
      styles={buildStyles({
        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
        strokeLinecap: "butt",
        // rotates the gauge so ends sit on the bottom
        rotation: 0.6,
        pathColor: `#${color}`,
        trailColor: "#d6d6d6",
      })}>
      <div className="gauge-text">
        <IndexNumber>{index}</IndexNumber>
        <IndexScore className="text-center">Yrs</IndexScore>
      </div>
    </CircularProgressbarWithChildren>
  </Container>
  )

export default IndexGaugeSmallLlsBioAge
