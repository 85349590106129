/* eslint-disable no-unused-expressions */
import axios from "axios"
import headers from "../util/headers"

export const getCalculatorResult = (baseUrl, currentUser, calculatorType) => {
  const path = `${baseUrl}/calculator_results`
  return axios.get(path, { params: { calculator_type: calculatorType }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getDashboardResults = (baseUrl, currentUser) => {
  const path = `${baseUrl}/calculator_results/ten_dwc_dashboard`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getDimensionResults = (baseUrl, currentUser) => {
  const path = `${baseUrl}/calculator_results/dimension`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getPulseDashboardResults = (baseUrl, currentUser) => {
  const path = `${baseUrl}/calculator_results/pulse_dashboard`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getMentalSkillsDashboardResults = (baseUrl, currentUser) => {
  const path = `${baseUrl}/calculator_results/mental_skills_dashboard`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getBurnoutProofDashboardResults = (baseUrl, currentUser) => {
  const path = `${baseUrl}/calculator_results/burnout_proof_dashboard`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getMatchfitDashboardResults = (baseUrl, currentUser) => {
  const path = `${baseUrl}/calculator_results/matchfit_dashboard`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getLiveLifeDashboardResults = (baseUrl, currentUser) => {
  const path = `${baseUrl}/calculator_results/live_life_dashboard`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const createCalculatorResult = (baseUrl, socio_responses, currentUser) => {
  const path = `${baseUrl}/calculator_results`
  return axios.post(path, { socio_responses }, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const updateCalculatorResult = (baseUrl, id, payload, is_submitted, currentUser, current_module, current_page) => {
  const path = `${baseUrl}/calculator_results/details`
  return axios.patch(path, {
    id, payload, is_submitted, current_module, current_page
  }, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const submitPulseCheckSurvey = (baseUrl, payload, currentUser) => {
  const path = `${baseUrl}/calculator_results/pulse_check_submit`
  return axios.post(path, payload, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const submitBurnoutProof = (baseUrl, payload, currentUser) => {
  const path = `${baseUrl}/calculator_results/burnout_proof_submit`
  return axios.post(path, payload, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}
