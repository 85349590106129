/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { getOrganisationDetail } from "../../requesters/Backend/OrganisationRequester"
import { archiveUnit } from "../../requesters/Backend/UnitRequester"
import UpdateOrgPage from "./UpdateOrgPage"

const OrgDetailPage = ({ baseUrl, currentUser, match }) => {
  const [organisationDetail, setOrganisationDetail] = useState({})
  const [editable, setEditable] = useState(false)
  const { addToast } = useToasts()

  const fetchOrganisationDetail = () => {
    getOrganisationDetail(baseUrl, match?.params?.organisationId, currentUser).then(res => {
      setOrganisationDetail(res.response.json.organisation)
      return null
    })
  }

  useEffect(() => {
    fetchOrganisationDetail()
  }, [])

  const onChangeEditable = () => {
    setEditable(!editable)
  }

  const onChangeArchived = e => {
    const newStatus = e.target.checked ? "active" : "inactive"
    archiveUnit(baseUrl, e.target.name, newStatus, currentUser).then(res => {
      if (res.status === 201) {
        addToast(`Unit Status successfully updated to: ${newStatus}`, { appearance: "success" })
      } else {
        addToast(`Unit Status not updated`, { appearance: "error" })
      }
    }).catch(err => {
      addToast(`${err}`, { appearance: "error" })
    })
  }

  if (editable) {
    return (
      <UpdateOrgPage
        baseUrl={baseUrl}
        currentUser={currentUser}
        fetchOrganisationDetail={fetchOrganisationDetail}
        onChangeEditable={onChangeEditable}
        organisationDetail={organisationDetail} />
    )
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="form-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/backend">Organisation</Link>
              </li>
              <li aria-current="page" className="breadcrumb-item active">{organisationDetail.name}</li>
            </ol>
          </nav>
          <h1>{organisationDetail.name}</h1>
          <div className="my-4">
            <div className="btn btn-outline-main btn-sm">More </div>
          </div>
          <div className="card-section">
            <div className="card">
              <div className="card-header"><h5>Units</h5></div>
              <div className="card-body">
                <table className="table">
                  <tbody>
                    {organisationDetail.units && organisationDetail.units.map((unit, index) => (
                      <tr key={unit.id}>
                        <td>
                          <Link to={`/backend/units/${unit.id}`}>{unit.name}</Link>
                        </td>
                        <td className="text-right">
                          <div className="custom-control custom-switch">
                            <input
                              onChange={onChangeArchived}
                              defaultChecked={unit.status === "active"}
                              className="custom-control-input"
                              name={unit.id}
                              id={`customSwitch${index}`}
                              type="checkbox" />
                            <label className="custom-control-label" htmlFor={`customSwitch${index}`} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button type="button" className="btn btn-outline-main btn-sm" onClick={onChangeEditable}>+ Add Unit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrgDetailPage
