import React from "react"
import { connect } from "react-redux"
import { withRouter, Switch, Route } from "react-router-dom"
import { ToastProvider } from "react-toast-notifications"
import { getCurrentUser, getBaseUrl } from "../reducers"
import LoginPage from "./Onboarding/LoginPage"
import SettingsPage from "./Onboarding/SettingsPage"
import ForgotPasswordPage from "./Onboarding/ForgotPasswordPage"
import SignUpPage from "./Onboarding/SignUpPage"
import Dashboard from "./Dashboard"
import PulseCheckDashboard from "./Dashboard/Pulse/PulseCheckDashboard"
import ProtectedRoute from "./ProtectedRoute"
import DemographicsForm from "./Dashboard/DemographicsForm"
import DimensionDetailPage from "./Dashboard/DimensionDetailPage"
import {
  BackendDashboard,
  BackendStatsDashboard,
  BackendTemplatesDash,
  BackendUsersDashboard,
  CreateOrgPage,
  CreateTemplatePage,
  OrgDetailPage,
  ProgramDetailPage,
  TemplateDetailPage,
  TemplatePreviewPage,
  UnitDetailPage,
  UserDetailPage
} from "./Backend"
import SurveyFormPage from "./Survey/SurveyFormPage"
import SurveyIntro from "./Survey/SurveyIntro"
import SurveyOverviewPage from "./Dashboard/SurveyOverviewPage"
import SurveyEnd from "./Survey/SurveyEnd"
import AboutTenDwc from "./Dashboard/AboutTenDwc"
import LinkingCodePage from "./Dashboard/LinkingCodePage"
import EmailVerification from "./Onboarding/EmailVerification"
import PulseCheckFormPage from "./Survey/Pulse/PulseCheckFormPage"
import PulseCheckEnd from "./Survey/Pulse/PulseCheckEnd"
import { MentalSkillsIntro, MentalSkillsEnd, MentalSkillsFormPage } from "./Survey/MentalSkills"
import MentalSkillsDashboard from "./Dashboard/MentalSkills/MentalSkillsDashboard"
import AboutMentalSkills from "./Dashboard/MentalSkills/AboutMentalSkills"
import MatchfitDashboard from "./Dashboard/Matchfit/MatchfitDashboard"
import AboutMatchfit from "./Dashboard/Matchfit/AboutMatchfit"
import CategoryDetailPage from "./Dashboard/MentalSkills/CategoryDetailPage"
import MatchfitCategoryDetailPage from "./Dashboard/Matchfit/MatchfitCategoryDetailPage"
import {
  MatchfitIntro, MatchfitFormPage, MatchfitEnd, MatchfitSimple
} from "./Survey/Matchfit"
import { LiveLifeIntro, LiveLifeFormPage } from "./Survey/Livelife"
import featureFlags from "../util/featureFlags"
import BackendExportsDashboard from "./Backend/BackendExportsDashboard"
import BackendTagsDashboard from "./Backend/BackendTagsDashboard"
import {
  BurnoutProofFormPage, BurnoutProofIntro
} from "./Survey/BurnoutProof"
import BurnoutProofDashboard from "./Dashboard/BurnoutProof/BurnoutProofDashboard"
import AboutBurnoutProof from "./Dashboard/BurnoutProof/AboutBurnoutProof"
import LiveLifeDashboard from "./Dashboard/LiveLife/LiveLifeDashboard"
import AboutLiveLife from "./Dashboard/LiveLife/AboutLiveLife"

const Main = ({ currentUser, baseUrl }) => (
  <ToastProvider autoDismiss>
    <Switch>
      {/* 10DWC DASHBOARD PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | 10DWC Dashboard"
        currentUser={currentUser}
        path={[
          "/ten-dwc-dashboard"
        ]}
        redirectedRoute="/login"
        component={props => <Dashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/about-10dwc",
        ]}
        redirectedRoute="/"
        component={() => <AboutTenDwc />} />
      <ProtectedRoute
        exact
        title="iStrive | Dimension Detail Page"
        currentUser={currentUser}
        path={[
          "/dimension/:dimensionId",
        ]}
        redirectedRoute="/login"
        component={props => <DimensionDetailPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />

      {/* PULSE CHECK DASHBOARD PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | Pulse Check Dashboard"
        currentUser={currentUser}
        path={[
          "/pulse-check-dashboard"
        ]}
        redirectedRoute="/login"
        component={props => <PulseCheckDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />

      {/* MENTAL SKILLS DASHBOARD PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | Mental Skills Dashboard"
        currentUser={currentUser}
        path={[
          "/mental-skills-dashboard"
        ]}
        redirectedRoute="/login"
        component={props => <MentalSkillsDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />

      <ProtectedRoute
        exact
        title="iStrive | Category Detail Page"
        currentUser={currentUser}
        path={[
          "/category/:categoryId",
        ]}
        redirectedRoute="/login"
        component={props => <CategoryDetailPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/about-mental-skills",
        ]}
        redirectedRoute="/"
        component={() => <AboutMentalSkills />} />

      {/* MATCHFIT DASHBOARD PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | MatchFit Dashboard"
        currentUser={currentUser}
        path={[
          "/matchfit-dashboard"
        ]}
        redirectedRoute="/login"
        component={props => <MatchfitDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | MatchFit Category Detail Page"
        currentUser={currentUser}
        path={[
          "/matchfit-category/:categoryId",
        ]}
        redirectedRoute="/login"
        component={props => <MatchfitCategoryDetailPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/about-matchfit",
        ]}
        redirectedRoute="/"
        component={() => <AboutMatchfit />} />

      {/* BURNOUT PROOF DASHBOARD PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | Burnout Proof Dashboard"
        currentUser={currentUser}
        path={[
          "/burnout-proof-dashboard"
        ]}
        redirectedRoute="/login"
        component={props => <BurnoutProofDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />

      {/* LIVE LIFE DASHBOARD PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | Live Life Dashboard"
        currentUser={currentUser}
        path={[
          "/livelife-dashboard"
        ]}
        redirectedRoute="/login"
        component={props => <LiveLifeDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />

      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/about-burnout-proof",
        ]}
        redirectedRoute="/"
        component={() => <AboutBurnoutProof />} />

      {/* SURVEY PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | Overview"
        currentUser={currentUser}
        path={[
          "/",
          "/overview",
        ]}
        redirectedRoute="/login"
        component={props => <SurveyOverviewPage currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Pulse Check Questions"
        currentUser={currentUser}
        path={[
          "/pulse-check/questions",
          "/pulse-check/intro",
        ]}
        redirectedRoute="/"
        component={props => <PulseCheckFormPage currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/pulse-check/thank-you",
        ]}
        redirectedRoute="/"
        component={props => <PulseCheckEnd currentUser={currentUser} baseUrl={baseUrl} {...props} />} />

      {/* MATCHFIT SURVEY PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | Matchfit Calculator Intro"
        currentUser={currentUser}
        path={[
          "/matchfit/intro",
          "/matchfit/questions",
        ]}
        redirectedRoute="/"
        component={props => <MatchfitIntro currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Matchfit Calculator Questions"
        currentUser={currentUser}
        path={[
          "/matchfit/questions/:moduleId",
        ]}
        redirectedRoute="/"
        component={props => <MatchfitFormPage currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/matchfit/thank-you",
        ]}
        redirectedRoute="/"
        component={props => <MatchfitEnd currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      {featureFlags("STAGING") && (
        <ProtectedRoute
          exact
          currentUser={currentUser}
          path={[
            "/matchfit/simple",
          ]}
          redirectedRoute="/"
          component={props => <MatchfitSimple currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      )}

      {/* LIVELIFE SURVEY PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | LiveLife Score Intro"
        currentUser={currentUser}
        path={[
          "/livelife/intro",
        ]}
        redirectedRoute="/"
        component={props => <LiveLifeIntro currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | LiveLife Score Questions"
        currentUser={currentUser}
        path={[
          "/livelife/questions",
        ]}
        redirectedRoute="/"
        component={props => <LiveLifeFormPage currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/about-livelife",
        ]}
        redirectedRoute="/"
        component={() => <AboutLiveLife />} />
      {/* <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/matchfit/thank-you",
        ]}
        redirectedRoute="/"
        component={props => <MatchfitEnd currentUser={currentUser} baseUrl={baseUrl} {...props} />} /> */}

      {/* 10DWC SURVEY PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | 10DWC Survey Intro"
        currentUser={currentUser}
        path={[
          "/survey/intro",
        ]}
        redirectedRoute="/"
        component={props => <SurveyIntro currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | 10DWC Survey Questions"
        currentUser={currentUser}
        path={[
          "/survey/questions",
        ]}
        redirectedRoute="/"
        component={props => <SurveyFormPage currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/survey/thank-you",
        ]}
        redirectedRoute="/"
        component={props => <SurveyEnd currentUser={currentUser} baseUrl={baseUrl} {...props} />} />

      {/* MENTAL SKILLS SURVEY PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | Mental Skills Intro"
        currentUser={currentUser}
        path={[
          "/mental-skills/intro",
        ]}
        redirectedRoute="/"
        component={props => <MentalSkillsIntro currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Mental Skills Questions"
        currentUser={currentUser}
        path={[
          "/mental-skills/questions",
        ]}
        redirectedRoute="/"
        component={props => <MentalSkillsFormPage currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/mental-skills/thank-you",
        ]}
        redirectedRoute="/"
        component={props => <MentalSkillsEnd currentUser={currentUser} baseUrl={baseUrl} {...props} />} />

      {/* BURNOUT PROOF SURVEY PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | Burnout Proof Intro"
        currentUser={currentUser}
        path={[
          "/burnout-proof/intro",
        ]}
        redirectedRoute="/"
        component={props => <BurnoutProofIntro currentUser={currentUser} baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Burnout Proof Questions"
        currentUser={currentUser}
        path={[
          "/burnout-proof/questions",
        ]}
        redirectedRoute="/"
        component={props => <BurnoutProofFormPage currentUser={currentUser} baseUrl={baseUrl} {...props} />} />

      {/* USER/SETTINGS PAGES */}
      <Route
        exact
        path={[
          "/login",
          "/logout"
        ]}
        render={props => <LoginPage {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Settings"
        currentUser={currentUser}
        path={[
          "/settings"
        ]}
        redirectedRoute="/"
        component={props => <SettingsPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Verify Email"
        currentUser={currentUser}
        path={[
          "/verify-email"
        ]}
        redirectedRoute="/login"
        component={props => <EmailVerification baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <Route
        exact
        path={[
          "/forgot-password"
        ]}
        render={props => <ForgotPasswordPage baseUrl={baseUrl} {...props} />} />
      <Route
        exact
        path="/signup"
        render={props => <SignUpPage baseUrl={baseUrl} {...props} />} />
      <ProtectedRoute
        exact
        currentUser={currentUser}
        path={[
          "/enrol"
        ]}
        redirectedRoute="/login"
        component={props => <LinkingCodePage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Sociodemographic Questions"
        currentUser={currentUser}
        path={[
          "/demographic/:rerouteId",
          "/demographic"
        ]}
        redirectedRoute="/login"
        component={props => <DemographicsForm baseUrl={baseUrl} currentUser={currentUser} {...props} />} />

      {/* BACKEND DASHBOARD PAGES */}
      <ProtectedRoute
        exact
        title="iStrive | Backend Dashboard"
        currentUser={currentUser}
        path={[
          "/backend",
        ]}
        redirectedRoute="/"
        component={props => <BackendDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Backend Users Dashboard"
        currentUser={currentUser}
        path={[
          "/backend/users",
        ]}
        redirectedRoute="/"
        component={props => <BackendUsersDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Backend Users Dashboard"
        currentUser={currentUser}
        path={[
          "/backend/users/:userId",
        ]}
        redirectedRoute="/"
        component={props => <UserDetailPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Backend MIS Stats"
        currentUser={currentUser}
        path={[
          "/backend/mis",
        ]}
        redirectedRoute="/"
        component={props => <BackendStatsDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Backend MIS Stats"
        currentUser={currentUser}
        path={[
          "/backend/exports",
        ]}
        redirectedRoute="/"
        component={props => <BackendExportsDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Backend Templates"
        currentUser={currentUser}
        path={[
          "/backend/templates",
        ]}
        redirectedRoute="/"
        component={props => <BackendTemplatesDash baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Unit Create"
        currentUser={currentUser}
        path={[
          "/backend/create-unit",
        ]}
        redirectedRoute="/"
        component={props => <CreateOrgPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Template Create"
        currentUser={currentUser}
        path={[
          "/backend/create-template",
        ]}
        redirectedRoute="/"
        component={props => <CreateTemplatePage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Tags"
        currentUser={currentUser}
        path={[
          "/backend/tags",
        ]}
        redirectedRoute="/"
        component={props => <BackendTagsDashboard baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Template Detail Page"
        currentUser={currentUser}
        path={[
          "/backend/templates/:templateId",
        ]}
        redirectedRoute="/"
        component={props => <TemplateDetailPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Template Detail Preview Page"
        currentUser={currentUser}
        path={[
          "/backend/templates/preview/:templateId",
        ]}
        redirectedRoute="/"
        component={props => <TemplatePreviewPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Organisation Detail Page"
        currentUser={currentUser}
        path={[
          "/backend/organisations/:organisationId",
        ]}
        redirectedRoute="/"
        component={props => <OrgDetailPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Unit Detail Page"
        currentUser={currentUser}
        path={[
          "/backend/units/:unitId",
        ]}
        redirectedRoute="/"
        component={props => <UnitDetailPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      <ProtectedRoute
        exact
        title="iStrive | Program Detail Page"
        currentUser={currentUser}
        path={[
          "/backend/programs/:programId",
        ]}
        redirectedRoute="/"
        component={props => <ProgramDetailPage baseUrl={baseUrl} currentUser={currentUser} {...props} />} />
      {/* <ProtectedRoute
        exact
        title="iStrive | abc"
        currentUser={currentUser}
        path={[
          "/backend/units/:unitId/edit",
        ]}
        redirectedRoute="/"
        component={props => <UpdateUnitPage baseUrl={baseUrl} {...props} />} /> */}
    </Switch>
  </ToastProvider>
)

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  baseUrl: getBaseUrl(state)
})

export default withRouter(connect(mapStateToProps)(Main))