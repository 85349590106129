/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import _ from "lodash"
import { useToasts } from "react-toast-notifications"
import { CSVLink } from "react-csv"
import { archiveCalculator, getExportCsv, getExportResponsesCsv } from "../../../requesters/Backend/CalculatorRequester"
import { addCalculator } from "../../../requesters/Backend/ProgramRequester"

const SwitchWithInflight = ({
  baseUrl, calculatorDetail, calculatorType, currentUser, programDetail, programId
}) => {
  const [csvData, setCsvData] = useState([])
  const [currrentCalculatorDetail, setCurrentCalculatorDetail] = useState({})
  // TODO: fix pulse name/selected pulse survey to be dynamic
  // eslint-disable-next-line no-unused-vars
  const [pulseName, setPulseName] = useState("")
  const [inflight, setInflight] = useState(false)
  const [switchOn, setSwitchOn] = useState(false)
  const [downloadSent, setDownloadSent] = useState(false)
  const [exportType, setExportType] = useState("")
  const [error, setError] = useState("")
  const { addToast } = useToasts()

  useEffect(() => {
    setCurrentCalculatorDetail(calculatorDetail)
    if (calculatorDetail?.status === "active") {
      setSwitchOn(true)
    } else {
      setSwitchOn(false)
    }
    const latestExport = _.get(calculatorDetail, "recent_exports.0.data")
    if (latestExport) {
      setCsvData(JSON.parse(latestExport))
    }
  }, [calculatorDetail])

  const fetchCsvExport = () => {
    setInflight(true)
    setDownloadSent(true)
    getExportCsv(baseUrl, currrentCalculatorDetail.id, currentUser)
      .then(res => {
        setCsvData(res.response.json.data)
        setInflight(false)
        return null
      })
      .catch(err => {
        const errorMessage = _.get(err, "response.data.errors[0].detail", "")
        setError(errorMessage)
        setInflight(false)
        addToast(`${errorMessage}`, { appearance: "error", autoDismiss: false })
      })
  }

  const fetchResponsesCsvExport = () => {
    setInflight(true)
    setDownloadSent(true)
    getExportResponsesCsv(baseUrl, currrentCalculatorDetail.id, currentUser)
      .then(res => {
        setCsvData(res.response.json.data)
        setInflight(false)
        setExportType("responses")
        return null
      })
      .catch(err => {
        const errorMessage = _.get(err, "response.data.errors[0].detail", "")
        setError(errorMessage)
        setInflight(false)
        addToast(`${errorMessage}`, { appearance: "error", autoDismiss: false })
      })
  }

  const onSwitchClick = () => {
    setInflight(true)
    if (currrentCalculatorDetail?.id) {
      archiveCalculator(baseUrl, currrentCalculatorDetail?.id, switchOn ? "archived" : "active", currentUser).then(res => {
        if (res.status === 201) {
          addToast(`Calculator ${switchOn ? "Deactivated" : "Activated"}`, { appearance: switchOn ? "error" : "success" })
          setSwitchOn(!switchOn)
          setInflight(false)
        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(typeof err, Object.keys(err))
      })
    } else {
      const params = { id: programId, calculator_type: calculatorType }
      addCalculator(baseUrl, params, currentUser).then(res => {
        if (res.status === 201) {
          addToast(`Added ${calculatorType} to this Program`, { appearance: "success" })
          // Do something with response
          setCurrentCalculatorDetail(res.response.json.calculator)
          setSwitchOn(true)
          setInflight(false)
        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(typeof err, Object.keys(err))
      })
    }
  }

  return (
    <div className="d-flex justify-content-end">
      {currrentCalculatorDetail?.id && !inflight && !error && calculatorType === "matchfit" && (
        <button
          className="btn btn-main btn-xs mr-3"
          disabled={downloadSent}
          onClick={fetchResponsesCsvExport}
          type="button">
          {/* Export CSV (MFC Raw Responses) */}
          {/* <img className="" src="/assets/icons/icn-down-fb56867ca128f33ff7661601e208c2c619b2148c1efbb857952c74e036cb2956.svg" alt="MFC Raw Answers" /> */}
          Export Responses
        </button>
      )}

      {currrentCalculatorDetail?.id && !inflight && !error && (
        <button
          className="btn btn-main btn-xs mr-3"
          disabled={downloadSent}
          onClick={fetchCsvExport}
          type="button">
          Export CSV
        </button>
      )}

      {inflight && (
        <div className="spinner-border text-primary mr-3" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      )}

      {/* {!!csvData.length && (
        <CSVLink
          className="btn btn-main btn-xs mr-3"
          data={csvData}
          filename={`${programDetail.organisation_name}-${programDetail.unit_name}-${programDetail.name}-${calculatorType}-${moment().format("YY-MM-DD-HH:mm:ss")}.csv`}
          target="_blank">
          {`Download ${exportType}`}
        </CSVLink>
      )} */}

      {!(calculatorType === "pulse_check" && !currrentCalculatorDetail?.id) && (
        <div className="custom-control custom-switch">
          {inflight ? (<span className="sr-only">Loading...</span>) : (
            <>
              <input
                className="custom-control-input"
                id={`customSwitch-${calculatorType}`}
                type="checkbox"
                onChange={onSwitchClick}
                checked={switchOn} />
              <label className="custom-control-label" htmlFor={`customSwitch-${calculatorType}`} />
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default SwitchWithInflight
