import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import MentalSkillsHeader from "./MentalSkillsHeader"
import MentalSkillsFormSection from "./MentalSkillsFormSection"

import { getMentalSkills } from "../../../requesters/CalculatorRequester"

import { updateCalculatorResult, getCalculatorResult } from "../../../requesters/CalculatorResultsRequester"

const MentalSkillsFormPage = ({ baseUrl, currentUser }) => {
  const [questions, setQuestions] = useState()
  const [form, setForm] = useState([])
  const [currentSection, setCurrentSection] = useState(0)
  const [totalQuestionLength, setTotalQuestionLength] = useState(0)
  const [lastPage, setLastPage] = useState(3)
  const [resultId, setResultId] = useState("")
  const { addToast } = useToasts()

  useEffect(() => {
    document.title = "iStrive | Mental Skills Questions"
  }, [])

  const fetchQuestionSet = () => {
    getMentalSkills(baseUrl, currentUser.active_mental_skills_id, currentUser).then(res => {
      setQuestions(res.response.json.calc.grouped_questions)
      setTotalQuestionLength(res.response.json.calc.question_length)
      setLastPage(res.response.json.calc.last_page)
    })
  }

  const fetchCalculatorResult = () => {
    getCalculatorResult(baseUrl, currentUser, "mental_skills").then(res => {
      if (res.response.json?.calculator_results?.calc_responses) {
        const retrievedAnswers = res.response.json.calculator_results.calc_responses.map(cr => ({ answer_id: cr.answer_id, number_field: cr.answer_number, sorting_index: cr.question_sorting_index }))
        setResultId(res.response.json?.calculator_results?.id)
        setForm(retrievedAnswers)
        setCurrentSection(retrievedAnswers.length === 48 ? 3 : Math.floor(retrievedAnswers.length / 12))
      }
    })
  }

  useEffect(() => {
    // Route to linking code page when User not enrolled in a program
    if (!currentUser?.active_mental_skills_id) {
      window.location.href = "/overview"
    } else {
      fetchQuestionSet()
      fetchCalculatorResult()
    }
  }, [])

  const onChangeHandler = e => {
    const { name, id } = e.target
    const formCopy = [...form]
    formCopy[name] = { answer_id: id }
    setForm(formCopy)
  }

  const onSubmit = () => {
    const payload = form.filter(e => e)
    updateCalculatorResult(baseUrl, resultId, payload, true, currentUser).then(res => {
      if (res.response?.status === "ok") {
        window.location.href = `/mental-skills/thank-you`
      }
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }

  const onSaveHandler = () => {
    const payload = form.filter(e => e)
    updateCalculatorResult(baseUrl, resultId, payload, false, currentUser).then(res => {
      if (res.response?.status === "ok") {
        window.location.href = `/mental-skills-dashboard`
      }
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }


  return (
    <div className="container">
      <MentalSkillsHeader form={form} onSaveHandler={onSaveHandler} />
      {!!questions && questions.slice(0, 12).map(question => (
        <MentalSkillsFormSection
          currentSection={currentSection}
          form={form}
          key={question.page_index}
          lastPage={lastPage}
          onChangeHandler={onChangeHandler}
          onSubmit={onSubmit}
          questionSet={question}
          setCurrentSection={setCurrentSection}
          totalQuestionLength={totalQuestionLength} />
      ))}
    </div>
  )
}

export default MentalSkillsFormPage
