export const SURVEY_HEADINGS = [
  "1. Physical Wellbeing",
  "2. Social",
  "3. Digital",
  "4. Intellectual",
  "5. Occupational",
  "6. Spiritual",
  "7. Cultural ",
  "8. Psychological",
  "9. Financial",
  "10. Environmental"
]

export const SURVEY_SUB_HEADINGS = [
  "Physical wellbeing is the ability to maintain a healthy quality of life that allows us to get the most out of our daily activities without undue fatigue or physical stress",
  "Social wellbeing is being satisfied with relationships, performing in social roles and feeling a sense of belonging to a group or tribe",
  "Digital wellbeing is the ability to craft and maintain a healthy relationship with technology",
  "Intellectual wellness is the ability to be open to new ideas, think critically, and a willingness to learn new skills, both technical and non-technical",
  "Occupational wellbeing is being intellectually stimulated with career fulfilment, and the experience of satisfaction and pleasure in our employment",
  "Spiritual wellbeing is being connected to oneself and the natural world with meaning and understanding",
  "Cultural wellbeing is the freedom to practice our own culture, being valued for our diversity and the sense of belonging to a group",
  "Psychological wellbeing is our ability to function well in the world and manage thoughts, feelings and emotions. Relates to sense of purpose and autonomy, personal mastery and the ability to grow and develop",
  "Financial wellbeing is  a sense of security and feeling as though you have enough money to meet your needs, are in control of your day-to-day finances and have financial freedom to make choices that allow you to enjoy life",
  "Environmental wellbeing is being adjusted to a pleasant, stimulating work and home environment and deriving pleasure and stimulation from natural surroundings"
]

export const GLOBAL_SUB_HEADING = "In relation to each of the following dimensions of wellbeing, please rate yourself on the a scale between 1 and 100, where 1 = very poor wellbeing and 100 = optimal wellbeing."
