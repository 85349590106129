import React from "react"

const Answer = ({
  answer, isLongText, formIndex, onChangeHandler, selectedAnswer
}) => {
  const btnClasses = () => {
    let classes = "btn btn-survey"
    if (isLongText) {
      classes += " long-text"
    }
    if (selectedAnswer?.answer_id === answer.id) {
      classes += " active"
    }
    return classes
  }

  return (
    <button
      className={btnClasses()}
      id={answer.id}
      name={formIndex}
      onClick={onChangeHandler}
      slug={answer.slug}
      type="button"
      value={answer.label}>
      <input type="radio" />
      {answer.label}
    </button>
  )
}

export default Answer