import React from "react"
import { Link } from "react-router-dom"
import { DashboardBody } from "./styles"

const AboutTenDwc = () => (
  <DashboardBody>
    <div className="container dashboard py-5">
      <div className="d-flex align-items-center my-4">
        <Link className="btn btn-outline-main" to="/ten-dwc-dashboard">Back</Link>
      </div>
      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <div className="pre-text">10DWC</div>
              <h1 className="mb-4">What is 10DWC?</h1>
              <p>The 10 Dimensions of Wellbeing Calculator (10DWC) is a science-based assessment designed by the StriveStronger Research Institute that brings together over 100 research-proven risk factors and has been adopted by leading organisations to assess and implement best-practice workplace wellbeing and performance programs.</p>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-9">
              <p>StriveStronger’s 10 Dimensions of Wellbeing illustrates the delicate balancing act between multiple factors in our personal and professional lives. Wellbeing is multi-dimensional, meaning when you enhance one area, other dimensions improve too. The 10 Dimensions are divided into the Inner World (areas people have control over) and the Outer World (areas people can influence, yet not necessarily control).</p>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-9">
              <img src="/assets/10dwc_chart-6ff383435e0494350ce4f2c85bb03400aeb072dcccae666e1659293b2e5e9e05.svg" alt="10DWC chart" width="100%" />
            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">Understanding Wellbeing</h2>
              <p>Wellbeing, or to be well, comes from the old English word ‘wiellan’ meaning ‘to spring, rise, flow.’ It is the act of looking at ourselves and looking at our lives with a holistic view that helps us to ‘spring into life’ or ‘to rise up.’</p>
              <p>In 1948, the World Health Organisation (WHO) defined health as not merely the absence of disease or infirmity, but a state of complete physical and mental wellbeing. More than seven decades later the world has dramatically changed, but the WHO definition remains similar. While there is no consensus around a single definition of ‘wellbeing’, there is an agreement it includes the presence of positive emotions and moods (contentment, happiness), the absence of negative emotions (depression, anxiety), healthy physiology, satisfaction with life, fulfillment and a feeling of inclusion.</p>

              <div className="mt-5">
                <h4>It Pays To Be Well</h4>
                <p>Wellbeing is associated with decreased risk of disease, illness, and injury; better immune functioning; speedier recovery; increased longevity; and people who report high levels of wellbeing are more productive at work and are more likely to contribute to their communities.</p>
                <p>Wellbeing is not just about being physically or psychologically fit. It is about being connected and in harmony across multiple parts of our lives. Wellbeing also fluctuates depending on circumstances and events and is not a static ‘set and forget’ measure. Which is why it is important to regularly assess wellbeing and to have a flexible approach to improving and sustaining your performance throughout the year.</p>
              </div>

              <div className="mt-5">
                <h4>Health and Wellbeing Are Not The Same</h4>
                <p>Our experience shows people who spend too much time or become obsessed with health, fitness, nutrition or other wellbeing domains often score low in relation to their overall wellbeing. An area becoming more recognised in sport psychology is the J-curve relationship between exercise and wellbeing, some is great, but too much can lead to significant decrements across other dimensions (relationships suffer, sleep quality is compromised, psychological stress symptoms occur, etc). It’s about finding the minimal most effective dose.</p>
              </div>

              <div className="mt-5">
                <h4>Changing Lives - Making It Stick</h4>
                <p>StriveStronger’s mission is to change people’s lives and make healthy habits stick, which means supporting you to achieve high scores across all 10 Dimensions of Wellbeing.</p>
                <p>Our goal is to have you energised, alive and flourishing in all parts of your life. To us, that is what optimal wellbeing is all about.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-5">Understanding Your Results</h2>
              <h4>Your Wellbeing Index</h4>

              <p>The Wellbeing Index is calculated out of 100 and the scores defined in the following categories:</p>
              <li>85 and above - Flourishing</li>
              <li>Between 65-85 - Striving</li>
              <li>Between 50-65 - Surviving</li>
              <li>Less than 50 - Languishing</li>

              <p>The Wellbeing Index brings together over 100 research-proven risk factors into a single measurement and has been adopted by leading organisations to assess and implement best-practice wellbeing and workplace performance programs. Over time, the Wellbeing Index acts as a benchmark for employee and organisation self-rated wellbeing.</p>
              <p>As you complete more than one 10DWC assessment, a trend of your recent Wellbeing Index scores is shown on your calculator dashboard.</p>

              <div className="mt-5">
                <h4>Your Dimension Scores</h4>
                <p>Your score for each of the 10 Dimensions is displayed below your Wellbeing Index, followed by your overall perception of your wellbeing. A description of each dimension can be viewed by clicking on the icon. You can see how you’ve responded to questions for that dimension by clicking the “View More” button.</p>
              </div>
              <div className="mt-5">
                <h4>Your Recommendations</h4>
                <p>A set of recommendations is tailored for you based on your overall Wellbeing Index score and your three lowest scoring dimensions.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">Accessing Your Latest Report</h2>
              <p>You can download a PDF report of your latest 10DWC assessment at any time by clicking on the “Download Latest Report” button. This report contains your latest results, information on 10DWC and your recommendations in one easy-to-read document. You are also sent a copy of this report via email each time you complete an assessment.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardBody>
)

export default AboutTenDwc
