export const convertSnakeToTitle = str => str
.split("_")
.filter(x => x.length > 0)
.map(x => (x.charAt(0).toUpperCase() + x.slice(1)))
.join(" ")

/**
 * Checks if any string elements in array are duplicated
 * @param {*} arr: array of strings
 * @returns true or false
 */
export const checkIfDuplicatesInArray = arr => {
  const unique = arr.filter((item, index) => arr.indexOf(item) === index)
  return unique.length !== arr.length
}