import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getDimensionResults } from "../../requesters"
import { getWellbeingScore, WELLBEING_CARD_DESC } from "./constants"
import { srcLink } from "./WellbeingCardHeading"
import GlobalDetailPage from "./GlobalDetailPage"
import { DashboardBody } from "./styles"

const DimensionDetailPage = ({ baseUrl, currentUser, match }) => {
  const [wellbeingScore, setWellbeingScore] = useState(0)
  const [prevWellbeingScore, setPrevWellbeingScore] = useState(0)
  const [completedAt, setCompletedAt] = useState("")
  const [globalData, setGlobalData] = useState([])
  const [responsesList, setResponsesList] = useState([])
  const dimensionType = match?.params?.dimensionId

  // Get the 10 relevant responses for the current dimensionType
  const extractResponses = responsesArray => {
    const relevantResponses = responsesArray.filter(response => response.section === dimensionType)
    return relevantResponses
  }

  const fetchCalculatorResults = () => {
    getDimensionResults(baseUrl, currentUser).then(res => {
      if (res.response.json.calculator_results) {
        const {
          calculator_results, completed_at, global_results, wellbeing_index
        } = res.response.json

        const relevantResponses = extractResponses(calculator_results)
        const currScore = wellbeing_index.find(result => result.section === dimensionType)
        setResponsesList(relevantResponses)
        setCompletedAt(completed_at)

        if (dimensionType === "global") {
          setGlobalData(global_results)
        } else {
          setWellbeingScore(_.get(currScore, ["net_result"]))
        }

        setWellbeingScore(wellbeing_index[0].find(result => result.section === dimensionType)?.net_result)
        if (wellbeing_index[1]) {
          setPrevWellbeingScore(wellbeing_index[1].find(result => result.section === dimensionType)?.net_result)
        }

        return res.response.json.calculator_results
      }
      return null
    })
  }

  const convertToPercentage = score => (score - 1) * 20

  useEffect(() => {
    fetchCalculatorResults()
  }, [])

  if (currentUser.role === "admin" || process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.log(responsesList)
  }

  if (dimensionType === "global" && globalData.length > 0) {
    return (
      <GlobalDetailPage
        completedAt={completedAt}
        data={globalData}
        responsesList={responsesList} />
    )
  }

  return (
    <DashboardBody className="content">
      <div className="container dashboard" id="ditem">
        <div className="d-flex align-items-center justify-content-between py-4">
          <Link className="btn btn-outline-main mr-2" to="/ten-dwc-dashboard">Back</Link>
          <div className="start-survey">
            {!!completedAt && <div className="small">{`Last completed on ${completedAt}`}</div>}
          </div>
        </div>
        <div className="dash-row card">
          <div className="card-body">
            <div className="pre-text">10DWC</div>
            <div className="row">
              <div className="col-lg-6">
                <div className="ditem-header">
                  <h1>{`${dimensionType.charAt(0).toUpperCase() + dimensionType.slice(1)} Wellbeing`}</h1>
                  <p className="mb-0">
                    {WELLBEING_CARD_DESC[dimensionType]}
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex align-items-center justify-content-lg-end">
                  <div className="ditem-score text-center">
                    <div className="current-score">{wellbeingScore}</div>
                    <h6>{getWellbeingScore(wellbeingScore)}</h6>
                    <div className="small">{prevWellbeingScore ? `Was ${prevWellbeingScore}` : ""}</div>
                  </div>
                  <img
                    width={120}
                    src={srcLink(dimensionType)} />
                </div>
              </div>
            </div>
            <ul className="legend list-inline">
              <li className="list-inline-item">Previous</li>
              <li className="list-inline-item">Latest</li>
            </ul>
            <div className="ditem-grid-container">
              <div id="ditem-grid" />
              <div className="ditem-questions">
                {!!responsesList.length && responsesList.map(response => (
                  <div className="row ditem-questions-item no-gutters" key={response.id}>
                    <div className="col-lg-6 col-xl-5 dimension-question">
                      <div className="text-lg-right">
                        {response.question_label}
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 dimension-graph">
                      <div className="progress" style={{ height: 1, display: "grid" }}>
                        <div
                          aria-valuemax={100}
                          aria-valuemin={0}
                          aria-valuenow={0}
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${convertToPercentage(_.get(response, ["responses", "0", "wellbeing_index"]))}%` }} />
                        {!!_.get(response, ["responses", "1", "wellbeing_index"]) && (
                          <div
                            aria-valuemax={100}
                            aria-valuemin={0}
                            aria-valuenow={0}
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${convertToPercentage(_.get(response, ["responses", "1", "wellbeing_index"]))}%` }} />
                        )}
                      </div>
                    </div>
                  </div>
              ))}
              </div>
            </div>
            <ul className="nav nav-fill ditem-weight">
              <li className="nav-item">1</li>
              <li className="nav-item">2</li>
              <li className="nav-item">3</li>
              <li className="nav-item">4</li>
              <li className="nav-item">5</li>
              <li className="nav-item">6</li>
            </ul>
          </div>
        </div>
      </div>
    </DashboardBody>
  )
}

export default DimensionDetailPage
