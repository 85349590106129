/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react"

const Pagination = ({ pageChangeHandler, totalRows, rowsPerPage }) => {
  const noOfPages = Math.ceil(totalRows / rowsPerPage)
  const pagesArr = [...new Array(noOfPages)]
  const [currentPage, setCurrentPage] = useState(1)
  const [canGoBack, setCanGoBack] = useState(false)
  const [canGoNext, setCanGoNext] = useState(true)

  const onNextPage = () => setCurrentPage(currentPage + 1)
  const onPrevPage = () => setCurrentPage(currentPage - 1)
  const onPageSelect = pageNo => setCurrentPage(pageNo)

  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false)
    } else {
      setCanGoNext(true)
    }
    if (currentPage === 1) {
      setCanGoBack(false)
    } else {
      setCanGoBack(true)
    }
  }, [noOfPages, currentPage])

  useEffect(() => {
    pageChangeHandler(currentPage)
  }, [currentPage])

  return (
    <div className="d-flex justify-content-center mt-5">
      {noOfPages > 1 ? (
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <button
                type="button"
                className="page-link"
                onClick={onPrevPage}
                disabled={!canGoBack}>
                &laquo;
              </button>
            </li>
            {pagesArr.map((_, index) => (
              <li className="page-item" key={index}>
                <button
                  type="button"
                  onClick={() => onPageSelect(index + 1)}
                  className={`page-link  ${
                    index + 1 === currentPage ? "active" : ""
                  }`}>
                  {index + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                type="button"
                className="page-link"
                onClick={onNextPage}
                disabled={!canGoNext}>
                &raquo;
              </button>
            </li>
          </ul>
        </nav>
    ) : null }
    </div>
  )
}

export default Pagination