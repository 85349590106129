/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { LinkingCodePageBody, HeaderContainer } from "./styles"
import { updateProgramLink } from "../../requesters/UsersRequester"
// import { ToastProvider } from "react-toast-notifications"
import { getCurrentUser, getBaseUrl } from "../../reducers"

const LinkingCodePage = ({ baseUrl, currentUser }) => {
  const [code, setCode] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    document.title = "iStrive"
  }, [])

  const onClick = () => {
    updateProgramLink(baseUrl, code, currentUser).then(res => {
      if (res.response.status === "ok") {
        window.location.href = `/overview`
      }
    }).catch(err => {
      setError(err)
    })
  }

  const onChange = e => {
    setCode(e.target.value)
  }

  const description = currentUser.signup_enrolment_failed
    ? "Your unique signup link was not valid. Please manually enter a program code if you have one or contact support@strivestronger.com for help."
    : "Please enter your unique program code to get started. If you do not have one, contact support@strivestronger.com."

  return (
    <LinkingCodePageBody>
      <div className="content">
        <div className="container form-container">
          <div className="card py-3">
            <div className="card-body">
              <HeaderContainer>
                <h2 className="mb-3">Unique Code</h2>
              </HeaderContainer>
              <p className="mb-5">{description}</p>
              <div className="form-group">
                <input className="form-control" placeholder="Code" type="text" onChange={onChange} />
                {error && <div className="err-msg text-danger mt-3">Invalid linking code provided</div>}
              </div>
              <button className="btn btn-main mt-3" type="button" onClick={onClick} disabled={code.length !== 8}>Continue</button>
            </div>
          </div>
        </div>
      </div>
    </LinkingCodePageBody>
  )
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  baseUrl: getBaseUrl(state)
})

export default withRouter(connect(mapStateToProps)(LinkingCodePage))
