/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { createProgram } from "../../requesters/Backend/ProgramRequester"

const AddProgramPage = ({
  baseUrl, currentUser, onChangeEditable, unitDetail
}) => {
  const [form, setForm] = useState({
    unit_id: unitDetail.id,
    name: "",
    ten_dwc: true,
    contact_info: true,
    age: true,
    job_level: true,
    sex: true,
    socialdemographic_lote: true,
    socialdemographic_aboriginal: true,
    socialdemographic_birth_country: true
  })
  const [showErrors, setShowErrors] = useState(false)
  const { addToast } = useToasts()
  const [inflight, setInflight] = useState(false)

  const onSubmit = () => {
    setShowErrors(true)
    if (form.name) {
      setInflight(true)
      const payload = form
      createProgram(baseUrl, payload, currentUser).then(res => {
        window.location.href = `/backend/programs/${res.response.json.program.id}`
      }).catch(err => {
        addToast(`Program not created: ${err.errors[0].detail}`, { appearance: "error" })
      })
    }
  }

  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const onSwitchClick = e => {
    setForm({ ...form, [e.target.name]: e.target.checked })
  }

  const onBreadCrumb = e => {
    e.preventDefault()
    onChangeEditable()
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="form-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/backend">{unitDetail.organisation_name}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link className="breadcrumb-item" onClick={onBreadCrumb} to="">{unitDetail.name}</Link>
              </li>
              <li aria-current="page" className="breadcrumb-item active">Add Program</li>
            </ol>
          </nav>
          <h1>Add Program</h1>
          <div className="card-section">
            <div className="card">
              <div className="card-header"><h5>Program Details</h5></div>
              <div className="card-body">
                <div className="form-group">
                  <label>Program name</label>
                  <input className="form-control" type="text" onChange={onChange} name="name" />
                  {showErrors && !form.name
                    && <div className="err-msg text-danger">Program Name is required</div>}
                </div>
                {/* <div className="form-group">
                  <label>Unique Code</label>
                  <div className="row align-items-center">
                    <div className="col-6"><h5>WFIBEFIEB</h5></div>
                    <div className="col-6"><div className="btn btn-outline-main btn-sm">Re-generate</div></div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="card">
              <div className="card-header"><h5>Demographic Questions</h5></div>
              <div className="card-body">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Age</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch1"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name="age"
                            checked={form.age} />
                          <label className="custom-control-label" htmlFor="customSwitch1" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Job Level</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch2"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name="job_level"
                            checked={form.job_level} />
                          <label className="custom-control-label" htmlFor="customSwitch2" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Sex</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch3"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name="sex"
                            checked={form.sex} />
                          <label className="custom-control-label" htmlFor="customSwitch3" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Socialdemographic - Language at home</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch4"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name="socialdemographic_lote"
                            checked={form.socialdemographic_lote} />
                          <label className="custom-control-label" htmlFor="customSwitch4" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Socialdemographic - Aboriginal/Torres Strait Islander</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch5"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name="socialdemographic_aboriginal"
                            checked={form.socialdemographic_aboriginal} />
                          <label className="custom-control-label" htmlFor="customSwitch5" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Socialdemographic - Country of Birth</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch6"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name="socialdemographic_birth_country"
                            checked={form.socialdemographic_birth_country} />
                          <label className="custom-control-label" htmlFor="customSwitch6" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {inflight ? (
            <div className="form-container-cta fixed-bottom">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="form-container-cta fixed-bottom">
              <button
                type="button"
                className="btn btn-outline-main"
                onClick={onChangeEditable}>
                Cancel
              </button>
              <button
                className="btn btn-main"
                onClick={onSubmit}
                disabled={inflight}
                type="button">
                Create
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AddProgramPage
