import React, { useState } from "react"
import { useToasts } from "react-toast-notifications"
import { Button, Modal } from "react-bootstrap"
import { sendEnrolUserProgram } from "../../../requesters/Backend/UserRequester"

const UpdateEnrolment = ({
  baseUrl, currentUser, refetch, userDetail: { id, role, program_code }
}) => {
  const [uniqueCode, setUniqueCode] = useState("")
  const [showModal, setShowModal] = useState(false)
  const { addToast } = useToasts()

  const handleToggleModal = () => {
    setShowModal(!showModal)
  }

  const handleEnrolUserProgram = () => {
    if (uniqueCode.length === 8) {
      const params = { user_id: id, unique_id: uniqueCode }
      sendEnrolUserProgram(baseUrl, params, currentUser)
        .then(res => {
          if (res.status === 201) {
            refetch()
            addToast("Enrolled in the program", { appearance: "success" })
            handleToggleModal()
          }
          return null
        })
        .catch(err => {
          const errorMessage = _.get(err, "response.data.errors[0].detail", "")
          addToast(`${errorMessage}`, { appearance: "error" })
        })
    }
  }
  const handleUniqueCode = e => setUniqueCode(e.target.value)
  if (role === "admin") return null
  return (
    <>
      <div className="dropdown-divider" />
      <button
        className="btn-outline-orange"
        onClick={handleToggleModal}
        type="button">
        Enrol User in another program
      </button>
      <Modal backdrop="static" onHide={handleToggleModal} show={showModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Unique code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>Please enter the unique program code.</p>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Code"
                type="text"
                onChange={handleUniqueCode} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleToggleModal} variant="secondary">Cancel</Button>
          <Button disabled={uniqueCode?.length !== 8 || program_code?.toLowerCase() === uniqueCode?.toLowerCase()} onClick={handleEnrolUserProgram} variant="primary">Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default UpdateEnrolment
