/* eslint-disable no-unused-expressions */
import axios from "axios"
import headers from "../../util/headers"

// Not working for some reason
// export const getUnitDetail = (baseUrl, unit_id) => {
//   const path = `${baseUrl}/backend/units/${unit_id}`
//   return axios.get(path)
//     .then(({ data, status }) => ({ response: data, status }))
//     .catch(error => { error })
// }

export const getUnitDetail = (baseUrl, unit_id, currentUser) => {
  const path = `${baseUrl}/backend/units/details`
  return axios.get(path, { params: { id: unit_id }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
}

export const getUnitList = (baseUrl, currentUser) => {
  const path = `${baseUrl}/backend/units`
  return axios.get(path, { ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
}

// Not working for some reason
// export const updateUnitDetail = (baseUrl, unit_id, form) => {
//   const path = `${baseUrl}/backend/units/${unit_id}`
//   return axios.patch(path, form)
//     .then(({ data, status }) => ({ response: data, status }))
//     .catch(error => { error })
// }

export const updateUnitDetail = (baseUrl, form, currentUser) => {
  const path = `${baseUrl}/backend/units/details`
  return axios.put(path, form, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const createUnits = (baseUrl, unit, currentUser) => {
  const path = `${baseUrl}/backend/units`
  return axios.post(path, unit, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const archiveUnit = (baseUrl, id, new_status, currentUser) => {
  const path = `${baseUrl}/backend/units/archive`
  return axios.post(path, { id, new_status }, headers(currentUser))
    // .then(({ data, status }) => ({ response: data, status }))
    // .catch(error => { error })
}