/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { archiveProgram } from "../../requesters/Backend/ProgramRequester"
import { getUnitDetail } from "../../requesters/Backend/UnitRequester"
import AddProgramPage from "./AddProgramPage"
import UpdateUnitPage from "./UpdateUnitPage"

const UnitDetailPage = ({ baseUrl, currentUser, match }) => {
  const [unitDetail, setUnitDetail] = useState({})
  const [editable, setEditable] = useState(false)
  const [editableProgram, setEditableProgram] = useState(false)
  const { addToast } = useToasts()

  const fetchUnitDetail = () => {
    getUnitDetail(baseUrl, match?.params?.unitId, currentUser)
      .then(res => {
        setUnitDetail(res.response.json.unit)
        return null
      })
      .catch(err => {
        addToast(`${err}, Unit not found`, { appearance: "error" })
      })
  }

  useEffect(() => {
    fetchUnitDetail()
  }, [])

  const onChangeEditable = () => {
    setEditable(!editable)
  }

  const onChangeEditablePrograms = () => {
    setEditableProgram(!editableProgram)
  }

  const onChangeArchived = e => {
    const newStatus = e.target.checked ? "active" : "inactive"
    archiveProgram(baseUrl, e.target.name, newStatus, currentUser).then(res => {
      if (res.status === 201) {
        addToast(`Program Status successfully updated to: ${newStatus}`, { appearance: "success" })
      } else {
        addToast(`Program Status not updated`, { appearance: "error" })
      }
    }).catch(err => {
      addToast(`${err}`, { appearance: "error" })
    })
  }

  if (editable) {
    return (
      <UpdateUnitPage
        baseUrl={baseUrl}
        fetchUnitDetail={fetchUnitDetail}
        onChangeEditable={onChangeEditable}
        unitDetail={unitDetail} />
    )
  }

  if (editableProgram) {
    return (
      <AddProgramPage
        baseUrl={baseUrl}
        currentUser={currentUser}
        fetchUnitDetail={fetchUnitDetail}
        onChangeEditable={onChangeEditablePrograms}
        unitDetail={unitDetail} />
    )
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="form-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/backend/organisations/${unitDetail.organisation_id}`}>{unitDetail.organisation_name}</Link>
              </li>
              <li aria-current="page" className="breadcrumb-item active">{unitDetail.name}</li>
            </ol>
          </nav>
          <h1>{unitDetail.name}</h1>
          <p>{unitDetail.joined_on}</p>
          <div className="card-section">
            <div className="card">
              <div className="card-header"><h5>Programs</h5></div>
              <div className="card-body">
                <table className="table">
                  <tbody>
                    {unitDetail.programs && unitDetail?.programs.map((program, index) => (
                      <tr key={program.id}>
                        <td>{moment(program.created_at).format("DD/MM/YYYY hh:mm")}</td>
                        <td></td>
                        <td><Link to={`/backend/programs/${program.id}`}>{program.name}</Link></td>
                        <td className="text-right">{program.unique_id}</td>
                        <td className="text-right">
                          <div className="custom-control custom-switch">
                            <input
                              onChange={onChangeArchived}
                              defaultChecked={program.status === "active"}
                              className="custom-control-input"
                              id={`customSwitch${index}`}
                              name={program.id}
                              type="checkbox" />
                            <label className="custom-control-label" htmlFor={`customSwitch${index}`} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button type="button" className="btn btn-outline-main btn-sm" onClick={onChangeEditablePrograms}>+ Add Program</button>
              </div>
            </div>
            <div className="card">
              <div className="card-header"><h5>Contact</h5></div>
              <div className="card-body">
                <ul className="list-unstyled">
                  <li>{unitDetail.contact_name}</li>
                  <li>{unitDetail.contact_email}</li>
                  <li>{unitDetail.contact_number}</li>
                </ul>
                <button
                  className="btn btn-outline-main btn-sm"
                  onClick={onChangeEditable}
                  type="button">
                  Edit
                </button>
                {/* TO DO: make link more elegant */}
                {/* <Link
                  className="btn btn-outline-main btn-sm"
                  to={`${location.pathname}/edit`}>
                  Edit
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnitDetailPage
