import React, { Fragment } from "react"

const INVALID_SYMBOLS = ["e", "E", "+", "-", "."]

const parseDescription = description => {
  if (description) {
    const [label, ...parsedDescription] = description.split("LINEBREAK")
    return (
      <td>
        <div className="strong mb-2">{label}</div>
        {!!parsedDescription.length && parsedDescription.map(line => (
          <Fragment key={line}>
            <br />
            <i>{line}</i>
          </Fragment>
        ))}
      </td>
    )
  }
  return null
}

const TableInputQuestion = ({
  descriptor, form, label, onChangeHandler, questions: { grid_content: gridContent, grid_headers: gridHeaders }
}) => (
  <div className="q-group no-scroll">
    <div>
      <div>{label}</div>
      <i>{descriptor}</i>
    </div>
    <table className="table table-striped survey-table">
      <thead>
        <tr>
          <td></td>
          {gridHeaders.map(header => (
            <td key={`table-header-${header}`}><div>{header}</div></td>
          ))}
        </tr>
      </thead>
      <tbody>
        {gridContent.map(row => (
          <tr key={`table-row-${row[0].row_label}`}>
            {/* <td>{row[0].row_label}</td> */}
            {parseDescription(row[0].row_label)}
            {row.slice(1, 3).map(element => (
              <td key={`table-answer-${element.answer_id}`}>
                <input
                  className="form-control"
                  id={element.answer_id}
                  name={element.question_slug}
                  onChange={onChangeHandler}
                  pattern="/d*"
                  step="1"
                  min={0}
                  max={999}
                  type="number"
                  inputMode="numeric"
                  onKeyDown={e => INVALID_SYMBOLS.includes(e.key) && e.preventDefault()}
                  onWheel={e => e.target.blur()}
                  value={_.get(form, [element.question_slug, "number_field"]) || ""} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default TableInputQuestion