import axios from "axios"
import headers from "../../util/headers"

export const getTagsForProgram = (baseUrl, query, currentUser) => {
  const path = `${baseUrl}/backend/tags/search`
  return axios.get(path, { params: { query }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
}

export const saveTag = (baseUrl, name, currentUser) => {
  const path = `${baseUrl}/backend/tags`
  return axios.post(path, { name }, headers(currentUser))
}

export const getTags = (baseUrl, filters, currentUser) => {
  const path = `${baseUrl}/backend/tags`
  return axios.get(path, { params: { ...filters }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
}

export const deleteTag = (baseUrl, id, currentUser) => {
  const path = `${baseUrl}/backend/tags`
  return axios.delete(path, { params: { id }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
}