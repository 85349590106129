/* eslint-disable no-unused-expressions */
import axios from "axios"
import headers from "../../util/headers"

// Currently unused but will be required in the future when more calcs are active
export const archiveCalculator = (baseUrl, id, new_status, currentUser) => {
  const path = `${baseUrl}/backend/calculators/archive`
  return axios.post(path, { id, new_status }, headers(currentUser))
    // .then(({ data, status }) => ({ response: data, status }))
}

export const createTemplate = (baseUrl, template, currentUser) => {
  const path = `${baseUrl}/backend/calculators/create_template`
  return axios.put(path, template, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getTemplates = (baseUrl, currentUser) => {
  const path = `${baseUrl}/backend/calculators/list_templates`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getTemplateDetails = (baseUrl, id, currentUser) => {
  const path = `${baseUrl}/backend/calculators/template_details`
  return axios.get(path, { params: { id } }, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getSystemQuestions = (baseUrl, currentUser) => {
  const path = `${baseUrl}/backend/calculators/system_questions`
  return axios.get(path, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const updateTemplateDetails = (baseUrl, template, currentUser) => {
  const path = `${baseUrl}/backend/calculators/update_template`
  return axios.post(path, template, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const setSelectedPulseSurvey = (baseUrl, form, currentUser) => {
  const path = `${baseUrl}/backend/calculators/set_pulse_survey`
  return axios.put(path, form, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const cloneTemplate = (baseUrl, template, currentUser) => {
  const path = `${baseUrl}/backend/calculators/clone_template`
  return axios.post(path, template, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const createReminders = (baseUrl, form, currentUser) => {
  const path = `${baseUrl}/backend/calculators/create_reminders`
  return axios.post(path, form, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const getExportCsv = (baseUrl, program_id, currentUser) => {
  const path = `${baseUrl}/backend/calculators/export`
  return axios.get(path, { params: { id: program_id }, ...headers(currentUser) })
    .then(({ data, errors, status }) => ({ error: _.get(errors, "response.data.0", errors), response: data, status }))
    // .catch(error => (_.get(error, "response.data.0", error)))
}

// Endpoint just for MFC raw responses export to CSV
export const getExportResponsesCsv = (baseUrl, program_id, currentUser) => {
  const path = `${baseUrl}/backend/calculators/export_mfc_responses`
  return axios.get(path, { params: { id: program_id }, ...headers(currentUser) })
    .then(({ data, errors, status }) => ({ error: _.get(errors, "response.data.0", errors), response: data, status }))
    // .catch(error => (_.get(error, "response.data.0", error)))
}

export const exportUsersCsv = (baseUrl, id, currentUser) => {
  const path = `${baseUrl}/backend/programs/export_users`
  return axios.post(path, { id }, headers(currentUser))
    .then(({ data, errors, status }) => ({ error: _.get(errors, "response.data.0", errors), response: data, status }))
}