import React from "react"
import { Link } from "react-router-dom"
import SurveyHeader from "./SurveyHeader"

const SurveyIntro = ({ currentUser }) => {
  const unfinishedSurveyExists = _.get(currentUser, "active_enrolment.is_new_attempt_ten_dwc", true)

  // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_ten_dwc_id) {
    window.location.href = "/overview"
    return null
  }

  // Route to sociodemographic questions when they are not complete
  if (!currentUser.active_enrolment?.sociodemographic_complete) {
    window.location.href = "/demographic"
    return null
  }

  return (
    <>
      <SurveyHeader />
      <div className="container pb-5">
        <h1>10 Dimensions of Wellbeing Calculator</h1>
        <p>The purpose of the 10DWC is to collect personal data to provide you with a snapshot of your current holistic wellbeing data for an Employee Wellbeing Index. </p>
        <p>Individual data remains confidential and will never be shared with your organisation. We only report anonymised and aggregated data for an Employee Wellbeing Index (to share insights with your employer) and for research purposes. </p>
        <p>Please read the statements in the survey and rate how each applies to you, reflecting on the past 4 weeks.</p>
        <Link className="btn btn-main mt-4" to="/survey/questions">{`${unfinishedSurveyExists ? "Start" : "Continue"} Survey`}</Link>
      </div>
    </>
  )
}

export default SurveyIntro
