import React from "react"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import WellbeingIndexChartTiny from "../WellbeingIndexChartTiny"
import { CATEGORIES_TOOLTIP } from "./constants"


const MentalSkillsBreakdownCard = ({
  surveyResults
}) => {
  const chartTinyHandler = section => surveyResults?.map(res => ({
    wellbeing_index: (res.section_results.filter(sr => sr.section_name === section)[0].section_net_result)
  }))

  const redirectHandler = link => {
    window.location.href = `/category/${link}`
  }

  return (
    <div className="dash-row card" id="ditem">
      <div className="card-body">
        <div className="pre-text">MSC</div>
        <h3>Your Mental Skills Breakdown</h3>
        <ul className="legend list-inline">
          <li className="list-inline-item">Effectiveness Score</li>
          <li className="list-inline-item">Skill Score</li>
        </ul>
        <div className="ditem-grid-container global-wellbeing-grid mental-skills">
          <div id="ditem-grid" />
          <div className="ditem-questions">
            {!!surveyResults.length && surveyResults[0]?.section_results.map((section, index) => (
              <div className="row ditem-questions-item no-gutters" key={section.section_name}>

                <div className="col-lg-6 col-xl-5 dimension-question pointer">
                  <div className="link-wrapper" onClick={() => redirectHandler(section.section_link)}>
                    <div className="mr-4 d-inline-flex align-items-center">
                      <h6 className="mb-0">{section.section_name_titlized}</h6>
                      <img
                        data-tip
                        data-for={`sectionDescriptor${index}`}
                        className="ml-2 d-none d-lg-block"
                        src="/assets/icons/icn-info-b20ac7be94a46a48d108384fba227519562e4edd8457d223011e783ad10f0c2f.svg" />
                      <ReactTooltip backgroundColor="#4b4b8b" className="tooltip" effect="solid" id={`sectionDescriptor${index}`} place="right" type="info">
                        <span>{CATEGORIES_TOOLTIP[section.section_name]}</span>
                      </ReactTooltip>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="recharts-wrapper">
                        {surveyResults.length > 1 && <WellbeingIndexChartTiny results={chartTinyHandler(section.section_name)} />}
                      </div>
                      <Link
                        to={`/category/${section.section_link}`}>
                        <div className="btn pr-1 pl-3">
                          <img src="/assets/icons/chevron-orange-07f3dc7491929d7a8a002471c3a664d3e7ec780575641e35c111b6f996566027.svg" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-xl-7 dimension-graph">
                  <div className="progress" style={{ height: 1, display: "grid" }}>
                    <div className="progress-bar" role="progressbar" style={{ width: `${section.section_net_result}%` }} />
                    <div className="progress-bar" role="progressbar" style={{ width: `${section.section_effectiveness}%` }} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ul className="nav nav-fill ditem-weight">
          <li className="nav-item">0</li>
          <li className="nav-item">25</li>
          <li className="nav-item">50</li>
          <li className="nav-item">75</li>
          <li className="nav-item">100</li>
        </ul>
      </div>
    </div>
    )
}


export default MentalSkillsBreakdownCard
