import React from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"
import { OVERVIEW_PAGE_CONTENT } from "../../Dashboard/constants"

const StyledNav = styled.nav`
  img:first-child {
    width: 64px;
    margin-right: 1em;
  }
  img:last-child {
    display: none;
    @media (min-width: 768px) {
      display: inline-block;
    }
  }
`

const MentalSkillsHeader = ({ form, onSaveHandler }) => (
  <div className="fixed-top">
    <StyledNav className="navbar navbar-light bg-white">
      <Link to="/mental-skills-dashboard">
        <img src={OVERVIEW_PAGE_CONTENT.mental_skills.logoSrc} width={54} height={54} />
        <img src="/assets/msc-logo-text-3ebcba9df75232bdf74abebf3b441a464f0868989a5d25eed7259688689c4628.svg" width={240} height={36} />
      </Link>
      {onSaveHandler && <button className="btn btn-outline-main btn-sm" type="button" onClick={onSaveHandler}>Save Draft</button>}
    </StyledNav>
    <div className="progress survey-progress">
      <div
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={0}
        style={{ width: `${form?.length / 48 * 100}%` }}
        className="progress-bar"
        role="progressbar" />
    </div>
  </div>
)

export default withRouter(MentalSkillsHeader)