import React from "react"
import {
  LineChart, Line, ResponsiveContainer
} from "recharts"

const WellbeingIndexChartTiny = ({ results }) => {
  const datas = [...results.map((result, index) => ({ name: index, value: result.wellbeing_index }))].reverse()

  return (
    <ResponsiveContainer>
      <LineChart width={300} height={300} data={datas}>
        <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default WellbeingIndexChartTiny
