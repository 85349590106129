import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { OVERVIEW_PAGE_CONTENT } from "../../Dashboard/constants"

const Header = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
`

const End = ({ currentUser }) => {
  // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_matchfit_id) {
    window.location.href = "/overview"
  }

  return (
    <>
      <Header>
        <nav className="navbar navbar-light bg-white">
          <Link className="navbar-brand" to="/">
            <img src={OVERVIEW_PAGE_CONTENT.matchfit.logoSrc} width={64} height={64} />
            <img src={OVERVIEW_PAGE_CONTENT.matchfit.textSrc} width={240} height={36} />
          </Link>
        </nav>
        <div className="progress survey-progress"><div aria-valuemax={100} aria-valuemin={0} aria-valuenow={100} style={{ width: `100%` }} className="progress-bar" role="progressbar" /></div>
      </Header>
      <div className="container">
        <h1>Thank you!</h1>
        <p>Thank you for completing the MatchFit Calculator. An individual report of your results based on this survey has been prepared. You can access this report by clicking on the &apos;Download Individual Report&apos; link below.</p>
        <p>{`The report will also be sent to your email address ${currentUser.email}`}</p>
        <div>
          If you do not see it in your inbox, please check your junk mail. If you still do not receive this, then you can contact us at
          {" "}
          <a href="mailto:support@strivestronger.com">support@strivestronger.com</a>
        </div>
        <div className="my-5">
          <a
            className="btn btn-main"
            target="_blank"
            rel="noreferrer"
            href={`/calculator_results/${currentUser.active_enrolment?.matchfit_latest_submitted_id}.pdf`}>
            Download Individual Report
          </a>
        </div>
        <hr />
        <Link className="btn btn-outline-main my-5" to="/matchfit-dashboard">Back to Dashboard</Link>
      </div>
    </>
  )
}

export default End
