import React from "react"
import Answer from "../Answer"
import { MFC_INSTRUCTIONS_ICN } from "../../Dashboard/Matchfit/constants"

const QuestionsWithInstructions = ({
  answers, descriptor, formIndex, label, longType, onChangeHandler, selectedAnswer, surveyType
}) => {
  let surveyTypeSuffix = surveyType ? `-${surveyType}` : ""
  if (longType) {
    surveyTypeSuffix = `-long`
  }

  const requiredInstructionsFor = ["right_arm_back", "left_arm_back", "touch_toes"]

  if (formIndex === "touch_toes") {
    surveyTypeSuffix = `-touch-toes`
  }

  if (["right_arm_back", "left_arm_back"].includes(formIndex)) {
    surveyTypeSuffix = `-arm-back`
  }

  return (
    <div className={`q-group${surveyTypeSuffix}`}>
      <p>{label}</p>
      { requiredInstructionsFor.includes(formIndex) && (
        <div>
          <img src={MFC_INSTRUCTIONS_ICN[formIndex]} className="instructions-img" />
        </div>
      )}
      {descriptor && (
        <div className="mb-3 small">
          <i>{descriptor}</i>
        </div>
      )}
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        {answers && answers.map(answer => (
          <Answer
            answer={answer}
            onChangeHandler={onChangeHandler}
            key={answer.id}
            selectedAnswer={selectedAnswer}
            formIndex={formIndex} />
        ))}
      </div>
    </div>
  )
}

export default QuestionsWithInstructions