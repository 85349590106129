import _ from "lodash"
import React, { useEffect, useState } from "react"
import MatchfitQuestionElement from "../MatchfitQuestionElement"

const SittingTimeFormSection = ({
  currentSection,
  form,
  onInputChangeHandler,
  questionSet,
  setCurrentSection,
  title,
}) => {
  const [invalidInput, setInvalidInput] = useState(true)
  const [userInputDetected, setUserInputDetected] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const question = questionSet.questions[0]

  const onNextStep = () => {
    if (userInputDetected && !invalidInput) {
      setCurrentSection(currentSection + 1)
    } else {
      setShowWarning(true)
    }
  }

  const onPreviousStep = () => {
    setCurrentSection(currentSection - 1)
  }

  useEffect(() => {
    if (currentSection === questionSet.page_index) {
      const weekdayHours = _.get(form, "time_sitting_weekday_hours.number_field")
      const weekendHours = _.get(form, "time_sitting_weekend_hours.number_field")
      const weekdayMinutes = _.get(form, "time_sitting_weekday_minutes.number_field")
      const weekendMinutes = _.get(form, "time_sitting_weekend_minutes.number_field")

      const isInvalidInput = weekdayHours > 23
        || weekendHours > 23
        || weekdayMinutes > 59
        || weekendMinutes > 59
      setInvalidInput(isInvalidInput)

      // If any hours questions on this page are answered, then Warning is skipped
      if (weekendHours >= 0 && weekdayHours >= 0) {
        setUserInputDetected(true)
      } else {
        setUserInputDetected(false)
      }
    } else {
      setShowWarning(false)
    }
  }, [currentSection, form])

  const handleInputChange = e => {
    setUserInputDetected(true)
    onInputChangeHandler(e)
  }

  if (currentSection !== questionSet.page_index) return null
  return (
    <>
      <h1>{title}</h1>
      <hr />
      {question && (
        <MatchfitQuestionElement
          form={form}
          key={`question-${question.id}`}
          onInputChangeHandler={handleInputChange}
          question={question}
          selectedAnswer={form[question.question_slug]}
          surveyType="matchfit" />
      )}
      <div>
        {showWarning && !userInputDetected && (
          <div className="bottom-cta mb-5 pb-3 d-flex justify-content-center bg-warning">Note: Please enter a value for sitting time (hours).</div>
        )}
        {showWarning && invalidInput && (
          <div className="bottom-cta mb-5 pb-3 d-flex justify-content-center bg-warning">Note: Please enter a maximum of 23 hours or 59 minutes.</div>
        )}
        <div className="bottom-cta d-flex justify-content-between">
          <button
            className="btn btn-light"
            type="button"
            onClick={onPreviousStep}>
            Previous
          </button>
          <button
            className="btn btn-main"
            type="button"
            onClick={onNextStep}>
            Next
          </button>
        </div>
      </div>
    </>
  )
}

export default SittingTimeFormSection