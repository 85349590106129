import React from "react"
import { Link } from "react-router-dom"
import IndexGaugeSmall from "../IndexGaugeSmall"
import WellbeingIndexChartTiny from "../WellbeingIndexChartTiny"

const MatchfitBreakdownCard = ({
  surveyHistory,
  surveyResults
}) => (
  <div className="dash-row card matchfit-breakdown" id="ditem">
    <div className="card-body">
      <div className="pre-text">MatchFit</div>
      <h3>Your MatchFit Breakdown</h3>
      <div className="row">
        <div className="col-lg-6">
          <div className="breakdown-section">
            <div className="col-lg-7">
              <div className="d-flex align-items-center mb-3">
                <img src="/assets/icons/10dwc/icn-10dwc-spiritual-db18d4c8a3e13b2f7209e42f0ac5bd0b25f55eda814efd5044278514da0cea46.svg" />
                <h4 className="mb-0 ml-3">Body</h4>
              </div>
              <small>Your Body score is drawn from your Physical Recovery, Physical Functioning, Nutrition Barometer, Movement and Biological Age.</small>
            </div>
            <div className="col-lg-5 p-0">
              <IndexGaugeSmall
                category="Body"
                color={surveyResults[0]?.body_wellbeing_index_color}
                index={surveyResults[0]?.body_overall_score} />
            </div>
          </div>
          <div className="ditem-grid-container global-wellbeing-grid">
            <div id="ditem-grid" />
            {!!surveyResults[0]?.body_categories.length && surveyResults[0].body_categories.map(category => (
              <div className="ditem-questions" key={category.category_slug}>
                <div className="row ditem-questions-item no-gutters">
                  <div className="col-lg-6 dimension-question">
                    <div className="link-wrapper">
                      <div className="mr-4 d-inline-flex align-items-center">
                        <h6 className="mb-0">{category.category_name}</h6>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="recharts-wrapper">
                          {surveyHistory[category.category_slug]?.history.length > 1 && <WellbeingIndexChartTiny results={surveyHistory[category.category_slug]?.history} />}
                        </div>
                        <Link
                          to={category.category_url}
                          className="stretched-link">
                          <div className="btn pr-1 pl-3">
                            <img src="/assets/icons/chevron-orange-07f3dc7491929d7a8a002471c3a664d3e7ec780575641e35c111b6f996566027.svg" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 dimension-graph">
                    <div className="progress" style={{ height: "1px", display: "grid" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: `${category.score}%` }} />
                    </div>
                  </div>
                </div>
              </div>
              ))}
          </div>
          <ul className="nav nav-fill ditem-weight">
            <li className="nav-item">0</li>
            <li className="nav-item">25</li>
            <li className="nav-item">50</li>
            <li className="nav-item">75</li>
            <li className="nav-item">100</li>
          </ul>
        </div>
        <div className="col-lg-6 mt-5 mt-lg-0">
          <div className="breakdown-section">
            <div className="col-lg-7">
              <div className="d-flex align-items-center mb-3">
                <img src="/assets/icons/10dwc/icn-10dwc-intellectual-b38193d798fc24390d87d6e05d2a175ff9898c2e2cdc5c220751d437b760d258.svg" />
                <h4 className="mb-0 ml-3">Brain</h4>
              </div>
              <small>Your Brain score is drawn from measures relating to Psychological Detachment, Social Connectedness, Psychological Performance, Emotional Wellbeing and Cognitive Flexibility.</small>
            </div>
            <div className="col-lg-5 p-0">
              <IndexGaugeSmall
                category="Brain"
                color={surveyResults[0]?.brain_wellbeing_index_color}
                index={surveyResults[0]?.brain_overall_score} />
            </div>
          </div>
          <div className="ditem-grid-container global-wellbeing-grid">
            <div id="ditem-grid" />
            {!!surveyResults[0]?.brain_categories.length && surveyResults[0]?.brain_categories.map(category => (
              <div className="ditem-questions" key={category.category_slug}>
                <div className="row ditem-questions-item no-gutters">
                  <div className="col-lg-6 dimension-question">
                    <div className="link-wrapper">
                      <div className="mr-4 d-inline-flex align-items-center"><h6 className="mb-0">{category.category_name}</h6></div>
                      <div className="d-flex align-items-center">
                        <div className="recharts-wrapper">
                          {surveyHistory[category.category_slug]?.history.length > 1 && <WellbeingIndexChartTiny results={surveyHistory[category.category_slug]?.history} />}
                        </div>
                        <Link
                          to={category.category_url}
                          className="stretched-link">
                          <div className="btn pr-1 pl-3">
                            <img src="/assets/icons/chevron-orange-07f3dc7491929d7a8a002471c3a664d3e7ec780575641e35c111b6f996566027.svg" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 dimension-graph">
                    <div className="progress" style={{ height: "1px", display: "grid" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: `${category.score}%` }} />
                    </div>
                  </div>
                </div>
              </div>
              ))}
          </div>
          <ul className="nav nav-fill ditem-weight">
            <li className="nav-item">0</li>
            <li className="nav-item">25</li>
            <li className="nav-item">50</li>
            <li className="nav-item">75</li>
            <li className="nav-item">100</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default MatchfitBreakdownCard
