import { isNaN } from "lodash"
import React from "react"
import ReactTooltip from "react-tooltip"
import WellbeingIndexChartTiny from "../WellbeingIndexChartTiny"

// const convertToPercentage = score => (score) * 20

const PulseBreakdownCard = ({
  surveyResults, previousHistory
}) => (
  <div className="dash-row card pulse-breakdown" id="ditem">
    <div className="card-body">
      <div className="pre-text">Pulse</div>
      <h3>Your Pulse Breakdown</h3>
      <ul className="legend list-inline">
        <li className="list-inline-item">Target</li>
        <li className="list-inline-item">Latest</li>
      </ul>
      <div className="ditem-grid-container global-wellbeing-grid">
        <div id="ditem-grid" />
        <div className="ditem-questions">
          {surveyResults[0]?.calc_responses.map((response, index) => (
            <div className="row ditem-questions-item no-gutters" key={response.id}>
              <div className="col-lg-4 col-xl-3 dimension-question">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="mr-4 d-inline-flex align-items-center justify-content-space-between">
                    <h6 className="mb-0">{response.question_label}</h6>
                    <img
                      data-tip
                      data-for={`questionDescriptor${index}`}
                      className="ml-2 d-none d-lg-block"
                      src="/assets/icons/icn-info-b20ac7be94a46a48d108384fba227519562e4edd8457d223011e783ad10f0c2f.svg" />
                  </div>
                  <div className="pulse-summary recharts">
                    {/* <h6 className="mr-2 d-lg-none">{convertToPercentage(response.net_result)}</h6> */}
                    <h6 className="mr-2 d-lg-none">{response.net_result}</h6>
                    <ReactTooltip backgroundColor="#4b4b8b" className="tooltip" effect="solid" id={`questionDescriptor${index}`} place="right" type="info">
                      <span>{response.question_descriptor}</span>
                    </ReactTooltip>
                    <div className="recharts-wrapper">
                      {previousHistory[index]?.responses.length > 1 && <WellbeingIndexChartTiny results={previousHistory[index]?.responses} />}
                    </div>
                  </div>
                </div>
                <div className="d-lg-none mt-3">{response.question_descriptor}</div>
              </div>
              <div className="col-lg-4 col-xl-5 dimension-graph">
                <div className="progress" style={{ height: 1, display: "grid" }}>
                  {/* <div className="progress-bar" role="progressbar" style={{ width: `${convertToPercentage(response.net_result)}%` }} /> */}
                  <div className="progress-bar" role="progressbar" style={{ width: `${response.net_result}%` }} />
                  <div className="progress-bar" role="progressbar" style={{ width: `${isNaN(response.question_target_percent) ? "80" : response.question_target_percent}%` }} />
                </div>
              </div>
              <div className="col-lg-2">
                <div className="target-value">
                  <span className="d-lg-none">Target: </span>
                  <span>{response.question_target_label}</span>
                </div>
              </div>
              {/* <div className="col-lg-2"><div className="actual-value">{response.answer_label}</div></div> */}
              <div className="col-lg-2">
                <div className="actual-value">
                  <span className="d-lg-none">Latest: </span>
                  <span>{response.answer_raw}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="nav nav-fill ditem-weight">
        <li className="nav-item">0</li>
        <li className="nav-item">25</li>
        <li className="nav-item">50</li>
        <li className="nav-item">75</li>
        <li className="nav-item">100</li>
      </ul>
    </div>
  </div>
)

export default PulseBreakdownCard
