import React, { useEffect, useState } from "react"
import { animateScroll as scroll } from "react-scroll"
import { SURVEY_HEADINGS, SURVEY_SUB_HEADINGS } from "./constants"
import Question from "./Question"

const generateSubform = (currentSection, form) => form.slice((currentSection) * 10, (currentSection + 1) * 10)

const SurveyFormSection = ({
  currentSection,
  form,
  lastPage,
  onChangeHandler,
  onSubmit,
  questionSet,
  setCurrentSection,
  totalQuestionLength,
}) => {
  const [subform, setSubform] = useState([])
  const [inflight, setInflight] = useState(false)
  const { questions } = questionSet

  const onNextStep = () => {
    setCurrentSection(currentSection + 1)
  }

  const onPreviousStep = () => {
    setCurrentSection(currentSection - 1)
  }

  // OnClick handler for the answers
  const onCheckHandler = e => {
    const { name, id } = e.target
    const formCopy = [...subform]
    formCopy[name] = { answer_id: id }
    setSubform(formCopy)
    onChangeHandler(e)

    // Scroll to next question
    const { innerWidth } = window
    scroll.scrollMore(innerWidth <= 770 ? 572 : 270)
  }

  useEffect(() => {
    setSubform(generateSubform(questionSet.page_index, form))
  }, [form])

  useEffect(() => {
    if (currentSection === questionSet.page_index) {
      scroll.scrollToTop()
    }
  }, [currentSection])

  const onClickSubmit = e => {
    setInflight(true)
    onSubmit(e)
  }

  if (currentSection !== questionSet.page_index) return null
  return (
    <>
      <h1>{SURVEY_HEADINGS[currentSection]}</h1>
      <p>{SURVEY_SUB_HEADINGS[currentSection]}</p>
      <hr />
      {questions.map(question => (
        <Question
          label={question.label}
          onChangeHandler={onCheckHandler}
          selectedAnswer={form[question.sorting_index]}
          formIndex={question.sorting_index}
          answers={question.answers}
          section={question.section}
          key={`question-${question.id}`} />
        ))}
      {inflight ? (
        <div className="bottom-cta d-flex justify-content-between">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
        ) : (
          <div className="bottom-cta d-flex justify-content-between">
            {(currentSection !== 0) && (
              <button
                className="btn btn-light"
                type="button"
                onClick={onPreviousStep}>
                Previous
              </button>
            )}
            {questionSet.page_index === lastPage ? (
              <button
                className="btn btn-main"
                disabled={totalQuestionLength !== form.filter(e => e).length || inflight}
                type="submit"
                onClick={onClickSubmit}>
                Done
              </button>
            ) : (
              <button
                className="btn btn-main"
                type="submit"
                disabled={questions.length !== subform.filter(e => e).length || subform.length === 0}
                onClick={onNextStep}>
                Next
              </button>
            )}
          </div>
      )}
    </>
  )
}

export default SurveyFormSection