import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { getTemplateDetails } from "../../requesters/Backend/CalculatorRequester"
import PulseTemplateQuestion from "./Templates/PulseTemplateQuestion"

const TemplatePreviewPage = ({ baseUrl, currentUser, match }) => {
  const [form, setForm] = useState({})
  const [questionList, setQuestionList] = useState([])
  const templateId = match?.params?.templateId

  const fetchTemplateDetails = () => {
    getTemplateDetails(baseUrl, templateId, currentUser)
      .then(res => {
        setForm(res.response.json.template)
        setQuestionList(res.response.json.template?.questions)
        return null
      })
  }

  useEffect(() => {
    fetchTemplateDetails()
  }, [])

  return (
    <div className="content">
      <div className="container-fluid bg-white p-3">
        <div className="form-container">
          <div className="container mb-5">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/backend/templates">Template</Link>
                </li>
                <li aria-current="page" className="breadcrumb-item active">{form.name}</li>
              </ol>
            </nav>
            <h1>
              Custom Pulse Check:
              {" "}
              {form.name}
            </h1>
            <p className="mb-0">
              Pulse Check is a short survey to help you track your progress throughout the program.
            </p>
            <p className="mt-0">
              Please answer all questions. Click on the &quot;Done&quot; button on the top right-hand corner when complete.
            </p>
            <hr />
            {!!questionList && questionList.map(question => (
              <PulseTemplateQuestion
                key={`question-${question.id}`}
                questionType={question.question_type}
                question={question} />
            ))}
          </div>
          <div className="form-container-cta">
            <Link
              to={`/backend/templates/${templateId}`}
              className="btn btn-outline-main">
              Back
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TemplatePreviewPage
