import React, { useEffect, useState } from "react"
import { FaTrash } from "react-icons/fa"
import { useToasts } from "react-toast-notifications"
import { getTags, deleteTag } from "../../requesters/Backend/TagRequester"
import Pagination from "../Pagination"

const BackendTagsDashboard = ({ baseUrl, currentUser }) => {
  const { addToast } = useToasts()
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalTags: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const perPage = 25

  const fetchTags = () => {
    setPageData(prevState => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }))
    getTags(baseUrl, { page: currentPage, per_page: perPage }, currentUser).then(res => {
      const { total_pages, total_tags, tags } = res.response.json
      setPageData({
        isLoading: false,
        rowData: tags,
        totalPages: total_pages,
        totalTags: total_tags,
        })
      })
  }

  const handleDelete = id => {
    deleteTag(baseUrl, id, currentUser).then(res => {
      if (res.status === 200) {
        fetchTags()
        addToast("Tag deleted successfully", { appearance: "success" })
      }
      return null
    })
  }

  useEffect(() => {
    fetchTags()
  }, [currentPage])

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Tags</h2>
          </div>
        </div>
        { pageData.isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
          ) : (
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Tag</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {pageData.rowData.length !== 0 ? (
                    pageData.rowData.map(tag => (
                      <tr key={tag.id}>
                        <td>{tag.name}</td>
                        <td className="d-flex justify-content-end">
                          <button className="btn btn-xs btn-danger" type="button" data-toggle="modal" data-target={`#deleteTagModal-${tag.id}`} title="Delete Tag">
                            <FaTrash />
                          </button>
                          <DeleteModal tag={tag} onDelete={() => handleDelete(tag.id)} modalId={`deleteTagModal-${tag.id}`} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" className="text-center">No tags found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        <Pagination
          totalRows={pageData.totalTags}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={perPage} />
      </div>
    </div>
  )
}

const DeleteModal = ({ tag, onDelete, modalId }) => (
  <div className="modal fade" id={modalId} tabIndex="-1" role="dialog" aria-labelledby={`${modalId}Label`} aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="deleteTagModalLabel">Delete Tag</h5>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          Are you sure you want to delete tag:
          {" "}
          <strong>{tag.name}</strong>
          ?
          {" "}
          This action cannot be undone. Programes with this tag will be affected.
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button
            onClick={() => onDelete(tag.id)}
            type="button"
            className="btn btn-danger"
            data-dismiss="modal">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  )

export default BackendTagsDashboard