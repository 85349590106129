import styled from "styled-components"

export const PROGRESS_ORANGE = "#f8643c"

export const HeaderContainer = styled.div`
  display: flex;
  
  > *:nth-child(2) {
    margin-left: auto
  }
`

export const LinkingCodePageBody = styled.div`
  background: #F1F1F2;
  height: calc(100vh - 72px);
`

export const DashboardBody = styled.div`
  background: #F1F1F2;
  min-height: calc(100vh - 72px);

  @media (max-width: 991.98px) {
    .navbar-brand img {
      height: 1.5em;
    }
  }
  .dash-row {
    margin-bottom: 2rem;
    .card, &.card {
      border: 0;
      border-radius: 0;
    }
    .card-body {
      padding: 1.5rem;
      @media (min-width: 992px) {
        padding: 2rem;
      }
    }
    h3 {
      margin-bottom: 2rem;
    }
  }
  .pre-text {
    color: $main;
    margin-bottom: 0.5rem;
  }

  .dashboard {
    @media (min-width: 1400px) {
      max-width: 1380px;
    }
  }
`