import React from "react"
import { DASHBOARD_RECOMMENDATIONS } from "./constants"
import Recommendation from "./Recommendation"

const RecommendationSection = ({ results }) => {
  const orderedResults = _.sortBy(results, ["net_result"])
  const worstResults = orderedResults.slice(0, 3)
  const worstResultsKeys = _.map(worstResults, "section")
  const recommendations = DASHBOARD_RECOMMENDATIONS.filter(
    recommendation => worstResultsKeys.includes(recommendation.key)
  )

  return (
    <>
      {
        recommendations.map(recommendation => (
          <Recommendation
            key={recommendation.heading}
            recommendation={recommendation} />
        ))
      }
    </>
  )
}

export default RecommendationSection
