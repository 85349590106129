import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getMatchfitDashboardResults } from "../../../requesters/CalculatorResultsRequester"
import { DashboardBody } from "../styles"
import { MFC_CATEGORIES, getMatchFitWellbeingCategory, generateMatchFitParagraphs } from "./constants"
import MatchfitBiologicalAgeDetails from "./MatchfitBiologicalAgeDetails"

const MatchfitCategoryDetailPage = ({ baseUrl, currentUser, match }) => {
  const sectionNameSlug = match?.params?.categoryId.replace(/-/g, "_")
  const [inflight, setInflight] = useState(true)
  const [categoryDetails, setCategoryDetails] = useState({})
  const [currentCategoryResult, setCurrentCategoryResult] = useState({})
  const [currentScore, setCurrentScore] = useState(null)
  const [previousScore, setPreviousScore] = useState(null)

  const fetchResults = () => {
    getMatchfitDashboardResults(baseUrl, currentUser).then(res => {
      if (res.response.json?.results_history) {
        const resultsHistory = res.response.json?.results_history[sectionNameSlug]
        const latestResult = res.response.json?.calculator_results[0]
        setCategoryDetails(resultsHistory)
        setCurrentScore(_.get(resultsHistory, "history.0.wellbeing_index", null))
        setPreviousScore(_.get(resultsHistory, "history.1.wellbeing_index", null))
        setCurrentCategoryResult(latestResult)
      }
      setInflight(false)
      return null
    }).catch(err => {
      setInflight(false)
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  useEffect(() => {
    fetchResults()
  }, [])

  if (inflight) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <DashboardBody className="content">
      <div className="container dashboard" id="ditem">
        <div className="d-flex align-items-center justify-content-between py-4">
          <Link className="btn btn-outline-main mr-2" to="/matchfit-dashboard">Back</Link>
          <div className="start-survey">
            {currentCategoryResult?.completed_at && <div className="small">{`Last completed on ${currentCategoryResult?.completed_at}`}</div>}
          </div>
        </div>
        <div className="dash-row card">
          <div className="card-body">
            {sectionNameSlug === "biological_age_metric" ? (
              <MatchfitBiologicalAgeDetails categoryDetails={categoryDetails} />
            ) : (
              <div className="card-body">
                <div className="pre-text">{`MatchFit - ${MFC_CATEGORIES[sectionNameSlug].categoryType}`}</div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="ditem-header">
                      <h1>{MFC_CATEGORIES[sectionNameSlug].heading}</h1>
                      {generateMatchFitParagraphs(sectionNameSlug)}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex align-items-center justify-content-lg-end">
                      <div className="ditem-score text-center">
                        <div className="current-score">{currentScore}</div>
                        <h6>{getMatchFitWellbeingCategory(currentScore)}</h6>
                        <div className="small">{previousScore ? `Was ${previousScore}` : ""}</div>
                      </div>
                      <img
                        width={120}
                        src={MFC_CATEGORIES[sectionNameSlug].srcLink} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-9">
                    <div className="goal">
                      <strong>Goal: </strong>
                      <i>{MFC_CATEGORIES[sectionNameSlug].goal}</i>
                    </div>
                  </div>
                </div>
                <ul className="legend list-inline">
                  <li className="list-inline-item">Previous</li>
                  <li className="list-inline-item">Latest</li>
                </ul>
                <div className="ditem-grid-container global-wellbeing-grid mental-skills">
                  <div id="ditem-grid" />
                  <div className="ditem-questions">
                    {!!categoryDetails?.subcategories.length && categoryDetails?.subcategories.map(sub => {
                  const previousSubcategories = _.get(categoryDetails, ["subcategories_previous"], [])
                  const previousSubcategory = previousSubcategories.find(prevSub => prevSub.label === sub.label)
                  const previousScore = previousSubcategory?.score
                  return (
                    <div className="row ditem-questions-item no-gutters" key={sub.id}>
                      <div className="col-lg-6 col-xl-5 dimension-question">
                        <div className="mr-4 d-inline-flex align-items-center">
                          <h6 className="mb-0">{sub.label}</h6>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-7 dimension-graph">
                        <div className="progress" style={{ height: "1px", display: "grid" }}>
                          <div className="progress-bar" role="progressbar" style={{ width: `${sub.score}%` }} />
                          {!!previousScore && <div className="progress-bar" role="progressbar" style={{ width: `${previousScore}%` }} />}
                        </div>
                      </div>
                    </div>
                  )
                })}
                  </div>
                </div>
                <ul className="nav nav-fill ditem-weight mt-0">
                  <li className="nav-item">0</li>
                  <li className="nav-item">25</li>
                  <li className="nav-item">50</li>
                  <li className="nav-item">75</li>
                  <li className="nav-item">100</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </DashboardBody>
  )
}

export default MatchfitCategoryDetailPage
