/* eslint-disable no-unused-expressions */
import axios from "axios"
import headers from "../util/headers"

export const getTenDwcCalculator = (baseUrl, id, currentUser) => {
  const path = `${baseUrl}/calculators/details`
  return axios.get(path, { params: { id }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getPulseCheck = (baseUrl, id, currentUser) => {
  const path = `${baseUrl}/calculators/pulse_check_details`
  return axios.get(path, { params: { id }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getMentalSkills = (baseUrl, id, currentUser) => {
  const path = `${baseUrl}/calculators/mental_skills_details`
  return axios.get(path, { params: { id }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getBurnoutProof = (baseUrl, id, currentUser) => {
  const path = `${baseUrl}/calculators/burnout_proof_details`
  return axios.get(path, { params: { id }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getCalculatorQuestions = (baseUrl, params, currentUser) => {
  const path = `${baseUrl}/calculators/`
  return axios.get(path, { params, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getMatchfitSimple = (baseUrl, params, currentUser) => {
  const path = `${baseUrl}/calculators/get_matchfit_simple`
  return axios.get(path, { params, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}