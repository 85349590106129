/* eslint-disable no-unused-expressions */
import axios from "axios"
import headers from "../util/headers"

export const getSocioQuestionSet = (baseUrl, set_id, currentUser) => {
  const path = `${baseUrl}/socio_question_sets/questions`
  return axios.get(path, { params: { set_id } }, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
