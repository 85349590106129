import React from "react"

 import { srcLink } from "./WellbeingCardHeading"

const Recommendation = ({ recommendation }) => (
  <div className="col-xl-3">
    <div className="card recommendations-item">
      <div className="card-body">
        <div className="row mb-4">
          <img className="mx-3" src={srcLink(recommendation.key)} width={48} />
          <h5 className="mb-4 pt-4 recommendations-header">{recommendation.heading}</h5>
        </div>
        <ul>
          {recommendation.content.map((text, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={`${text.slice(0, 3)}-${index}`}>{text}</li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)

export default Recommendation
