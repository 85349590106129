/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import { emailRegex } from "../../util/regex"

const ForgotPasswordPage = ({ baseUrl }) => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const [confirmationSent, setConfirmationSent] = useState(false)

  const onResetPasswordHandler = () => {
    const path = `${baseUrl}/users/send_reset_password_email`
    const token = document.querySelector("[name=csrf-token]").content
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": token
    }

    axios.post(path, { headers, email }).then(() => {
      setConfirmationSent(true)
    })
    .catch(error => {
      setError(_.get(error, "response.data.errors.0.detail"))
    })
  }

  const onChangeEmail = e => {
    setEmail(e.target.value)
    setError(false)
  }

  if (confirmationSent) {
    return (
      <div className="content">
        <div className="container form-container">
          <div className="card">
            <div className="card-body">
              <p>
                A reset confirmation email has been sent to
                {" "}
                <strong>{email}</strong>
                . Please follow the instructions in the email to reset your password. If you do not see it in your inbox, please check your junk mail. If you still do not receive this, you can contact us at support@strivestronger.com
              </p>
            </div>
            <div className="form-group text-center">
              <Link className="btn btn-outline-main" to="/login">Close</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const validateEmail = () => {
    // Check if valid email according to regex first
    const validEmail = emailRegex.test(email)
    if (!validEmail) {
      setError("Invalid email address")
    }
  }

  return (
    <div className="content">
      <div className="container form-container">
        <h1>Forgot Password</h1>
        <div className="card">
          <div className="card-body">
            <div className="form-group">
              <div className="mb-5 pt-3">Enter your email address you use for iStrive and we will send you instructions to reset your password.</div>
              <input
                autoFocus
                autoComplete="email"
                className="form-control"
                type="email"
                name="email"
                id="user_email"
                onChange={onChangeEmail}
                onBlur={validateEmail}
                placeholder="Enter your email..." />
              {error && <div className="err-msg text-danger my-3">{error}</div>}
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-outline-main"
                onClick={onResetPasswordHandler}
                disabled={!email || error}>
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
