import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { updateUnitDetail } from "../../requesters/Backend/UnitRequester"
import { emailRegex } from "../../util/regex"

const UnitDetailPage = ({
  baseUrl, currentUser, fetchUnitDetail, onChangeEditable, unitDetail
}) => {
  const [form, setForm] = useState(unitDetail)
  const [showErrors, setShowErrors] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const requiredValues = [
    "id", "name", "contact_name", "contact_email", "contact_number"
  ]

  const onSubmit = () => {
    setShowErrors(true)
    if (requiredValues.every(k => k in form) && isValidEmail) {
      const payload = _.pick(form, ["id", "name", "contact_name", "contact_email", "contact_number"])
      updateUnitDetail(baseUrl, payload, currentUser).then(() => {
        fetchUnitDetail()
        onChangeEditable()
      })
    }
  }

  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const validateEmail = () => {
    // Check if valid email according to regex
    setIsValidEmail(emailRegex.test(form.contact_email))
  }

  useEffect(() => {
    validateEmail()
  }, [form.contact_email])

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="form-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <Link className="breadcrumb-item" to={`/backend/organisations/${unitDetail.organisation_id}`}>{unitDetail.organisation_name}</Link>
              <li aria-current="page" className="breadcrumb-item active"> Edit Unit</li>
            </ol>
          </nav>
          <h1>Edit Unit</h1>
          <div className="card-section">
            <div className="card">
              <div className="card-header"><h5>Unit</h5></div>
              <div className="card-body">
                <div className="form-group">
                  <label>Unit</label>
                  <input className="form-control" name="name" onChange={onChange} type="text" defaultValue={form.name} />
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header"><h5>Contact</h5></div>
              <div className="card-body">
                <div className="form-group">
                  <label>Contact name</label>
                  <input className="form-control" name="contact_name" onChange={onChange} type="text" defaultValue={form.contact_name} />
                </div>
                <div className="form-group">
                  <label>Contact email</label>
                  <input className="form-control" name="contact_email" onChange={onChange} type="email" defaultValue={form.contact_email} />
                  {showErrors && !isValidEmail
                    && <div className="err-msg text-danger">Contact email is invalid</div>}
                </div>
                <div className="form-group">
                  <label>Contact number</label>
                  <input className="form-control" name="contact_number" onChange={onChange} type="text" defaultValue={form.contact_number} maxLength="10" pattern="[0-9]{10}" />
                </div>
              </div>
            </div>
          </div>
          <div className="form-container-cta fixed-bottom">
            <button
              type="button"
              className="btn btn-outline-main"
              onClick={onChangeEditable}>
              Cancel
            </button>
            <button
              className="btn btn-main"
              disabled={_.isEqual(unitDetail, form)}
              onClick={onSubmit}
              type="button">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnitDetailPage
