const token = document.querySelector("[name=csrf-token]").content
const headers = currentUser => ({
  headers: {
    "Device-Type": "web",
    "App-Version": "1",
    "X-CSRF-Token": token,
    "X-User-Identification-Id": currentUser.id,
    "X-User-Auth-Token": currentUser.authentication_token
  }
})

export default headers