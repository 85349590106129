/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { DashboardBody } from "../styles"
import { getMentalSkillsDashboardResults } from "../../../requesters/CalculatorResultsRequester"
import WellbeingIndexChart from "../WellbeingIndexChart"
import IndexGauge from "../IndexGauge"
import MentalSkillsBreakdownCard from "./MentalSkillsBreakdownCard"
import { getMentalSkillsOverallDesc } from "./constants"
import { OVERVIEW_PAGE_CONTENT } from "../constants"

const Dashboard = ({ baseUrl, currentUser }) => {
  const [surveyResults, setSurveyResults] = useState([])
  const [wellbeingIndexes, setWellbeingIndexes] = useState([])
  const [recommendations, setRecommendations] = useState({})
  const [inflight, setInflight] = useState(true)
  const unfinishedSurveyExists = currentUser.active_enrolment.is_new_attempt_mental_skills

  useEffect(() => {
    document.title = "iStrive | Mental Skills Dashboard"
  }, [])

  const fetchResults = () => {
    getMentalSkillsDashboardResults(baseUrl, currentUser).then(res => {
      if (res.response.json?.calculator_results) {
        const recentResults = res.response.json?.calculator_results
        setSurveyResults(res.response.json?.calculator_results)
        setRecommendations(res.response.json?.calculator_results[0]?.recommendations)
        setWellbeingIndexes(recentResults.map(result => result.wellbeing_index))
      }
      setInflight(false)
      return null
    }).catch(err => {
      setInflight(false)
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  useEffect(() => {
    fetchResults()
  }, [])

  if (inflight) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  // // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_enrolment) {
    window.location.href = "/overview"
  } else if (!surveyResults?.length && !inflight) {
    window.location.href = "/overview"
  }

  return (
    <DashboardBody>
      <div className="content">
        <div className="container dashboard" id="dashboard-10dwc">
          <div className="d-flex align-items-center justify-content-between py-4">
            <div>
              <Link
                to="/overview"
                className="back-to-overview">
                <img src="/assets/icons/icn-back-009e79b5747b122738def7d3b12743f2fc8a033afbefde260aa458eb38a9f2db.svg" />
              </Link>
              <img src={OVERVIEW_PAGE_CONTENT.mental_skills.logoSrc} />
            </div>
            <div className="start-survey">
              <Link className="btn btn-main ml-4" to="/mental-skills/intro">{`${unfinishedSurveyExists ? "Start" : "Continue"} Survey`}</Link>
              <div className="small">{`Last completed on ${surveyResults[0]?.completed_at}`}</div>
            </div>
          </div>
          <div className="dash-row row wellness-index">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="pre-text">Your Mental Skills Score</div>
                  <div className="gauge">
                    <IndexGauge
                      category={surveyResults[0]?.wellbeing_index_category}
                      color={surveyResults[0]?.wellbeing_index_color}
                      index={surveyResults[0]?.wellbeing_index} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <div className="pre-text">Your Mental Skills Score Trend</div>
                  <WellbeingIndexChart results={surveyResults} />
                </div>
              </div>
            </div>
          </div>
          <div className="dash-row row">
            <div className="col-lg-4"><Link className="btn btn-lg-block bg-main-120" to="/about-mental-skills">What is MSC?</Link></div>
            {currentUser.active_enrolment?.mental_skills_latest_submitted_id && (
              <div className="col-lg-8">
                <a
                  className="btn btn-lg-block btn-outline-main"
                  target="_blank"
                  rel="noreferrer"
                  href={`/calculator_results/${currentUser.active_enrolment?.mental_skills_latest_submitted_id}.pdf`}>
                  {/* TODO: add the download icon */}
                  Download Latest Report
                </a>
              </div>
            )}
          </div>
          <MentalSkillsBreakdownCard surveyResults={surveyResults} />

          <div className="dash-row recommendations">
            <div className="row">
              <div className="col-xl-3">
                <div className="pre-text">How to improve</div>
                <h3>Recommendations</h3>
                <div className="mb-3">
                  {getMentalSkillsOverallDesc(wellbeingIndexes[0] || 0)}
                </div>
              </div>
              <>
                {
                  !!recommendations.length && recommendations.map(recommendation => (
                    <div className="col-xl-3" key={recommendation.heading}>
                      <div className="card recommendations-item">
                        <div className="card-body">
                          <div className="mb-1">
                            <small>{recommendation.section}</small>
                          </div>
                          <h6 className="mb-3 recommendations-header">{recommendation.heading}</h6>
                          <ul>
                            {recommendation.content.map((text, index) => (
                              // eslint-disable-next-line react/no-array-index-key
                              <li key={`${text.slice(0, 3)}-${index}`}>{text}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </>

            </div>
          </div>
        </div>
      </div>
    </DashboardBody>
  )
}

export default Dashboard
