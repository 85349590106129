/* eslint-disable no-unused-expressions */
import axios from "axios"
import headers from "../../util/headers"

export const getUserDetail = (baseUrl, unit_id, currentUser) => {
  const path = `${baseUrl}/backend/users/details`
  return axios.get(path, { params: { id: unit_id }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
}

export const searchForUsers = (baseUrl, query, currentUser) => {
  const path = `${baseUrl}/backend/users/search`
  return axios.get(path, { params: { ...query }, ...headers(currentUser) })
    .then(({ data, status }) => ({ response: data, status }))
}

export const sendAdminResetPassword = (baseUrl, id, currentUser) => {
  const path = `${baseUrl}/backend/users/send_reset_password_email`
  return axios.post(path, { id }, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const sendResetPassword = (baseUrl, email, currentUser) => {
  const path = `${baseUrl}/users/send_reset_password_email`
  return axios.post(path, { email }, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const sendEnrolUserProgram = (baseUrl, params, currentUser) => {
  const path = `${baseUrl}/backend/users/enrol_user_in_program`
  return axios.post(path, params, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}

export const sendUnenrolUserProgram = (baseUrl, params, currentUser) => {
  const path = `${baseUrl}/backend/users/unenrol_user_from_program`
  return axios.patch(path, params, headers(currentUser))
    .then(({ data, status }) => ({ response: data, status }))
}