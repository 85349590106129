import React, { Fragment } from "react"

const MultipleInputQuestion = ({
  descriptor, form, imageElement, integerOnly, questionSlug, label, onChangeHandler, questions
}) => {
  const parseLabel = fullLabel => {
    if (fullLabel) {
      const [label, ...parsedLabel] = fullLabel.split("LINEBREAK")
      return (
        <>
          <div>{label}</div>
          {!!imageElement && (imageElement(questionSlug))}
          {!!parsedLabel.length && parsedLabel.map(line => (
            <Fragment key={line}>
              <br />
              <i>{line}</i>
            </Fragment>
          ))}
        </>
      )
    }
    return null
  }
  const parseDescription = description => {
    if (description) {
      const [label, ...parsedDescription] = description.split("LINEBREAK")
      return (
        <td>
          <div className="strong mb-2">{label}</div>
          {!!parsedDescription.length && parsedDescription.map(line => (
            <div className="mb-2" key={line}><i>{line}</i></div>
          ))}
        </td>
      )
    }
    return null
  }
  const invalidSymbols = ["e", "E", "+", "-"]
  if (integerOnly) {
    invalidSymbols.push(".")
  }

  return (
    <div className="q-group no-scroll">
      <div>
        {parseLabel(label)}
      </div>

      <table className="table table-striped survey-table">
        <thead>
          <tr>
            <td />
            <td className="small"><div>{descriptor}</div></td>
          </tr>
        </thead>
        <tbody>
          {questions.length && questions.map(question => {
            const {
              answer_id, answer_label, id, question_slug
            } = question

            return (
              <tr key={`question-${id}`}>
                {parseDescription(answer_label)}
                <td>
                  <input
                    className="form-control"
                    id={answer_id}
                    name={question_slug}
                    onChange={onChangeHandler}
                    // pattern="/d+"
                    pattern="[0-9]*"
                    step="1"
                    min={0}
                    max={999}
                    type="number"
                    inputMode="decimal"
                    onKeyDown={e => invalidSymbols.includes(e.key) && e.preventDefault()}
                    onWheel={e => e.target.blur()}
                    value={_.get(form, `${question_slug}.number_field`, "")} />
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default MultipleInputQuestion