import React, { useState } from "react"
import { animateScroll as scroll } from "react-scroll"
import styled from "styled-components"

const SliderContainer = styled.div`
  display: flex;
  
  > *:nth-child(2) {
    margin-left: 12px;
  }

  @media (max-width: 550px) {
    margin-left: 10px;
  }
`

const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;

  > *:nth-child(2) {
    text-align: right;
  }

  @media (max-width: 550px) {
    margin-left: 10px;
  }
`

const Slider = styled.input`
  width: 80%;
`

const SliderQuestion = ({
  label, onChangeHandler, answer, selectedAnswer, sortingIndex
}) => {
  const [value, setValue] = useState(selectedAnswer?.number_field || 1)

  return (
    <div className="q-slider-group">
      <p className="slider-label">{label}</p>
      <SliderContainer>
        <Slider
          min="1"
          max="100"
          className="custom-range"
          name={sortingIndex}
          id={answer.id}
          // Scroll to next question
          onMouseUp={() => scroll.scrollMore(window.innerWidth < 770 ? 270 : 270)}
          onChange={e => {
            onChangeHandler(e)
            setValue(e.target.value)
          }}
          type="range"
          value={value} />
        <div>{value}</div>
      </SliderContainer>
      <LabelsContainer>
        <div>1 = Very Poor</div>
        <div>100 = Optimal</div>
      </LabelsContainer>
    </div>
  )
}

export default SliderQuestion