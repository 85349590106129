import React, { useState, useEffect } from "react"

const CustomAnswer = ({
  answer, answerlist, handleDelete, handleUpdateAnswers, index
}) => {
  const [currentCustomAnswer, setCurrentCustomAnswer] = useState(answer)

  const onChange = e => {
    setCurrentCustomAnswer({ ...currentCustomAnswer, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    handleUpdateAnswers({
      target: {
            name: index,
            value: currentCustomAnswer
          }
      })
  }, [currentCustomAnswer])

  return (
    <>
      <div className="d-flex align-items-center custom-qns-items">
        <div className="custom-control custom-radio">
          <input className="custom-control-input" id={`customRadio${index}`} name="customRadio" type="radio" />
          <label className="custom-control-label" htmlFor={`customRadio${index}`} />
        </div>
        <input
          className="form-control"
          name="label"
          onBlur={onChange}
          type="text"
          defaultValue={currentCustomAnswer.label} />
        <input
          className="form-control"
          name="net_result"
          onChange={onChange}
          placeholder={`Score e.g. ${index * 20}`}
          type="number"
          min={0}
          max={100}
          onWheel={e => e.target.blur()}
          value={currentCustomAnswer.net_result} />
        <button type="button" className="close" disabled={answerlist.length <= 1} name={index} onClick={handleDelete}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {!currentCustomAnswer.label && <div className="err-msg text-danger">Custom answer label cannot be blank</div>}
    </>
  )
}

export default CustomAnswer