import React from "react"

export const getLiveLifeOverallDesc = displayScore => {
  if (displayScore < 50) {
    return (
      <div>
        You scored in the
        {" "}
        <span className="category">Struggling</span>
        {" "}
        category. Your priority is to incrementally improve overall physical and psychological wellbeing. It might take a good 12 months or more for some people to get into the green zone. That’s okay – be realistic about short-term goals and the most important thing is to begin now and set an achievable plan. Start by putting your oxygen mask on first and regularly include self-care activities that energise you and nourish your soul.
      </div>
    )
  }
  if (displayScore >= 50 && displayScore <= 65) {
    return (
      <div>
        You scored in the
        {" "}
        <span className="category">Surviving</span>
        {" "}
        category. Your LiveLife Score shows there is opportunity to improve physical wellbeing and mental fitness. Focus on modifying behaviours in the areas where you scored lowest to move up into the Striving category. Start by putting your oxygen mask on first and regularly include self-care activities that energise you and nourish your soul.
      </div>
    )
  }
  if (displayScore >= 66 && displayScore <= 85) {
    return (
      <div>
        You scored in the
        {" "}
        <span className="category">Striving</span>
        {" "}
        category. Good work, your LiveLife Score indicates there are several areas where you scored well. Continue to focus on improving the areas/categories where you scored lowest to move into the LifeFit category.
      </div>
    )
  }
  return (
    <div>
      You scored in the
      {" "}
      <span className="category">LifeFit</span>
      {" "}
      category. Congratulations, your score indicates that you are optimising physical and psychological potential. Focus on sustaining your current wellbeing habits.
    </div>
  )
}

export const ageChangeScoreDisplay = score => {
  if (score > 0) {
    return `+${score}`
  }
  return score
}