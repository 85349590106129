import React from "react"

export const getMentalSkillsOverallDesc = displayScore => {
  if (displayScore <= 50) {
    return (
      <div>
        Your Mental Skills Calculator Score fell within the
        {" "}
        <span className="category">Languishing</span>
        {" "}
        category. Below are our recommendations to help you improve your Mental Skills, based on your lowest three scoring skill areas from your most recent Mental Skills Calculator.
      </div>
    )
  }

  if (displayScore <= 65) {
    return (
      <div>
        Your Mental Skills Calculator Score fell within the
        {" "}
        <span className="category">Surviving</span>
        {" "}
        category. Below are our recommendations to help you improve your Mental Skills, based on your lowest three scoring skill areas from your most recent Mental Skills Calculator.
      </div>
    )
  }

  if (displayScore < 85) {
    return (
      <div>
        Your Mental Skills Calculator Score fell within the
        {" "}
        <span className="category">Striving</span>
        {" "}
        category. Below are our recommendations to help you improve your Mental Skills, based on your lowest three scoring skill areas from your most recent Mental Skills Calculator.
      </div>
    )
  }

  return (
    <div>
      Congratulations. Your Mental Skills Calculator Score fell within the
      {" "}
      <span className="category">Flourishing</span>
      {" "}
      category. Below are our recommendations to help you improve your Mental Skills, based on your lowest three scoring skill areas from your most recent Mental Skills Calculator.
    </div>
  )
}

export const SUBCATEGORIES = {
  optimism: {
    heading: "Optimism ",
    description: " - believing the future will be okay.",
  },
  drive: {
    heading: "Drive ",
    description: " - setting a personal vision and achieving goals.",
  },
  confidence: {
    heading: "Confidence ",
    description: " - trusting yourself to manage in adversity.",
  },
  pre_performance_routines: {
    heading: "Pre-Performance Routines ",
    description: " - developing rituals and routines to support best performance.",
  },
  mental_imagery: {
    heading: "Mental Imagery ",
    description: " - developing rituals and routines to support best performance.",
  },
  focus: {
    heading: "Focus ",
    description: " - managing distractions and paying attention for best outcome.",
  },
  being_calm: {
    heading: "Being Calm ",
    description: " - learning to control nerves and making clear decisions.",
  },
  mindfulness: {
    heading: "Mindfulness ",
    description: " - being fully present and engaged in the moment.",
  },
  connectedness: {
    heading: "Connectedness ",
    description: " - communicating effectively and developing meaningful relationships.",
  },
  story_telling: {
    heading: "Story Telling ",
    description: " - managing your inner voice and cultivating meaning.",
  },
  personal_identity: {
    heading: "Personal Identity ",
    description: " - understanding who you are.",
  },
  energy_management: {
    heading: "Energy Management ",
    description: " - utilising and replenishing physical energy and psychological energy.",
  }
}

export const CATEGORIES_TOOLTIP = {
  self_discovery: "Self-Discovery consists of skills involving managing your inner voice and cultivating meaning, understanding who you are, and utilising and replenishing physical energy and psychological energy. A further breakdown of your score in this category can be viewed by clicking on the “>” icon.",
  foundation_skills: "Foundation Skills consists of slowing your breathing rate to relax the body and calm mind, being fully present and engaged in the moment, and learning to control nerves & making clear decisions, and having meaning for connection. A further breakdown of your score in this category can be viewed by clicking on the “>” icon.",
  performance_psychology: "Performance Psychology consists of developing rituals and routines to support best performance, believing the future will be okay, managing distractions and paying attention for best outcome, using sensory information to visualise a future outcome, setting a personal vision and achieving goals and trusting yourself to manage in adversity. A further breakdown of your score in this category can be viewed by clicking on the “>” icon."
}