import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"

import _ from "lodash"
import { getCalculatorQuestions } from "../../../requesters/CalculatorRequester"

import { getCalculatorResult, updateCalculatorResult } from "../../../requesters/CalculatorResultsRequester"
import FormSection from "../Matchfit/FormSection"
import GeneralInfoSection from "../Matchfit/GeneralInfoSection"
import LiveLifeHeader from "./LiveLifeHeader"
import {
  HeartRateFormSection, MovementFormSection, RecoveryFormSection, SittingTimeFormSection
} from "../Matchfit/SpecificFormSections"

const LiveLifeFormPage = ({ baseUrl, currentUser, match }) => {
  const [questionSets, setQuestionSets] = useState()
  const [form, setForm] = useState({})
  const [currentSection, setCurrentSection] = useState(100)
  const [totalQuestionLength, setTotalQuestionLength] = useState(0)
  const [lastPage, setLastPage] = useState(6)
  const [resultId, setResultId] = useState("")
  const { moduleId } = match.params
  const { addToast } = useToasts()

  useEffect(() => {
    document.title = "iStrive | LiveLife Score Questions"
  }, [])

  const fetchCalculatorResult = questions => {
    getCalculatorResult(baseUrl, currentUser, "live_life").then(res => {
      setCurrentSection(0)
      const {
        calc_responses: calculatorResponses,
        calc_responses_by_sorting_index: calculatorResponsesBySortingIndex,
      } = res.response.json?.calculator_results
      if (calculatorResponses?.length) {
        const retrievedAnswers = {}
        calculatorResponses.forEach(cr => {
          retrievedAnswers[cr.question_slug] = {
            answer_id: cr.answer_id,
            answer_slug: cr.answer_slug,
            free_text_field: cr.free_text_field,
            number_field: cr.float_field || cr.number_field,
            hours: cr.hours,
            minutes: cr.minutes,
            seconds: cr.seconds,
            // sorting_index: cr.question_sorting_index
          }
        })
        setForm(retrievedAnswers)

        if (calculatorResponsesBySortingIndex.length) {
          const lastAnswer = _.last(calculatorResponsesBySortingIndex)
          const slug = lastAnswer?.question_slug
          const lastQuestion = questions.find(q => q.questions.map(item => item.question_slug).includes(slug) || q.input_question_slugs.includes(slug))
          const currentSection = lastQuestion?.page_index
          setCurrentSection(currentSection)
        }

        // Retrieve User's progress on the module
        // switch (moduleId) {
        //   case "body":
        //     setCurrentSection(first_module_progress || 0)
        //     break
        //   case "movement":
        //     setCurrentSection(second_module_progress || 0)
        //     break
        //   case "physical-functioning":
        //     setCurrentSection(third_module_progress || 0)
        //     break
        //   case "brain":
        //     setCurrentSection(fourth_module_progress || 0)
        //     break
        //   default:
        //     setCurrentSection(0)
        //     break
        // }
      }
      setResultId(res.response.json?.calculator_results?.id)
    })
  }

  const fetchQuestionSet = () => {
    const params = { id: currentUser.active_live_life_id, calculator_type: "live_life", module_id: moduleId }
    getCalculatorQuestions(baseUrl, params, currentUser).then(res => {
      setQuestionSets(res.response.json.calc.grouped_questions)
      setTotalQuestionLength(res.response.json.calc.question_length)
      setLastPage(res.response.json.calc.last_page)
      fetchCalculatorResult(res.response.json.calc.grouped_questions)
    })
  }

  useEffect(() => {
    // Route to linking code page when User not enrolled in a program
    if (!currentUser?.active_live_life_id) {
      window.location.href = "/overview"
    } else {
      fetchQuestionSet()
      // fetchCalculatorResult()
    }
  }, [])

  const onChangeHandler = e => {
    const { name, id } = e.target
    const formCopy = { ...form }
    const answer_slug = e.target.getAttribute("slug")
    formCopy[name] = { answer_id: id, answer_slug }

    // TODO: wipe appropriate fields when user selects "No"
    if (answer_slug === "tobacco_yes_no_1") {
      delete formCopy.tobacco_cigarettes_quantity
      delete formCopy.tobacco_cigars_quantity
      delete formCopy.tobacco_pipes_quantity
      delete formCopy.tobacco_vapes_quantity
    } else if (answer_slug === "wears_activity_monitor_1") {
      delete formCopy.monitor_tracks_minutes
      delete formCopy.monitor_tracks_heartbeat
      delete formCopy.vo2_yes_no
      delete formCopy.vo2
    }

    setForm(formCopy)
  }

  const onFreeTextChangeHandler = e => {
    const { id, name, value } = e.target
    const formCopy = { ...form }
    formCopy[name] = { answer_id: id, free_text_field: value }
    setForm(formCopy)
  }

  const onInputChangeHandler = e => {
    const {
      name, id, value, hours, minutes, seconds, min, max
    } = e.target
    // if value is not within the range, set it to the min or max
    let errors = []
    if (min && max) {
      if (Number(value) > Number(max)) {
        errors = [`Please enter a ${name} value less than ${max}`]
      } else if (Number(value) < Number(min)) {
        errors = [`Please enter a ${name} value greater than ${min}`]
      }
      // if within the range, remove the error
      if (Number(value) <= Number(max) && Number(value) >= Number(min)) {
        errors = []
      }
    }

    const formCopy = { ...form }
    formCopy[name] = {
      answer_id: id,
      number_field: Number(value),
      hours,
      minutes,
      seconds,
      errors,
    }
    setForm(formCopy)
  }

  const onSaveHandler = e => {
    let finalSubmit = false

    // Submit only from the button Done
    if (e.target.name !== "saveDraft") {
      finalSubmit = true
    }

    const formWithFloatFields = { ...form }
    const {
      sit_to_stands,
      left_leg_balance,
      right_leg_balance,
      tobacco_cigarettes_quantity,
      tobacco_cigars_quantity,
      tobacco_pipes_quantity,
      tobacco_vapes_quantity,
      vo2,
    } = formWithFloatFields

    if (sit_to_stands?.number_field) {
      sit_to_stands.float_field = sit_to_stands.number_field
      delete sit_to_stands.number_field
    }
    if (left_leg_balance?.number_field) {
      left_leg_balance.float_field = left_leg_balance.number_field
      delete left_leg_balance.number_field
    }
    if (right_leg_balance?.number_field) {
      right_leg_balance.float_field = right_leg_balance.number_field
      delete right_leg_balance.number_field
    }
    if (tobacco_cigarettes_quantity?.number_field) {
      tobacco_cigarettes_quantity.float_field = tobacco_cigarettes_quantity.number_field
      delete tobacco_cigarettes_quantity.number_field
    }
    if (tobacco_cigars_quantity?.number_field) {
      tobacco_cigars_quantity.float_field = tobacco_cigars_quantity.number_field
      delete tobacco_cigars_quantity.number_field
    }
    if (tobacco_pipes_quantity?.number_field) {
      tobacco_pipes_quantity.float_field = tobacco_pipes_quantity.number_field
      delete tobacco_pipes_quantity.number_field
    }
    if (tobacco_vapes_quantity?.number_field) {
      tobacco_vapes_quantity.float_field = tobacco_vapes_quantity.number_field
      delete tobacco_vapes_quantity.number_field
    }
    if (vo2?.number_field) {
      vo2.float_field = vo2.number_field
      delete vo2.number_field
    }
    const payload = _.toArray(form)
    updateCalculatorResult(baseUrl, resultId, payload, finalSubmit, currentUser, moduleId, currentSection).then(res => {
      if (res.response?.status === "ok") {
        window.location.href = `/livelife-dashboard`
      }
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }

  return (
    <div className="container pb-5">
      <LiveLifeHeader form={form} onSaveHandler={onSaveHandler} />
      {/* Loading effect when formpage loading */}
      {currentSection === 100 && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {!!questionSets && questionSets.map(questionSet => {
        if (questionSet.section_title === "General Information") {
          return (
            <GeneralInfoSection
              calculatorName="LiveLife Score"
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              lastPage={lastPage}
              onChangeHandler={onFreeTextChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        if (questionSet.section_title === "Sitting") {
          return (
            <SittingTimeFormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              onInputChangeHandler={onInputChangeHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title} />
          )
        }
        if (questionSet.section_title === "Activity Monitor") {
          return (
            <FormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              isLivelife
              lastPage={lastPage}
              onChangeHandler={onChangeHandler}
              onInputChangeHandler={onInputChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        if (questionSet.section_title === "Heart Rate") {
          return (
            <HeartRateFormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              lastPage={lastPage}
              onChangeHandler={onChangeHandler}
              onInputChangeHandler={onInputChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        if (questionSet.section_title === "Movement") {
          return (
            <MovementFormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              lastPage={lastPage}
              onChangeHandler={onChangeHandler}
              onInputChangeHandler={onInputChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        if (questionSet.section_title === "Switching Off" || questionSet.section_title === "Physical Recovery") {
          return (
            <RecoveryFormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              lastPage={lastPage}
              onChangeHandler={onChangeHandler}
              onInputChangeHandler={onInputChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        return (
          <FormSection
            currentSection={currentSection}
            form={form}
            key={questionSet.page_index}
            isLivelife
            lastPage={lastPage}
            onChangeHandler={onChangeHandler}
            onInputChangeHandler={onInputChangeHandler}
            onSubmit={onSaveHandler}
            questionSet={questionSet}
            setCurrentSection={setCurrentSection}
            title={questionSet.section_title}
            description={questionSet.section_description}
            totalQuestionLength={totalQuestionLength} />
        )
      })}
    </div>
  )
}

export default LiveLifeFormPage
