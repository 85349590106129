import React, { useState } from "react"
import { useToasts } from "react-toast-notifications"
import { Button, Modal } from "react-bootstrap"
import { sendUnenrolUserProgram } from "../../../requesters/Backend/UserRequester"

const UnenrolModal = ({
  baseUrl, currentUser, refetch, userDetail: { id, role }
}) => {
  const [showModal, setShowModal] = useState(false)
  const { addToast } = useToasts()
  const handleToggleModal = () => setShowModal(!showModal)
  const handleUnenrolUser = () => {
    const params = { user_id: id }
    sendUnenrolUserProgram(baseUrl, params, currentUser)
      .then(res => {
        if (res.status === 200) {
          refetch()
          addToast("Unenrol user from the program", { appearance: "success" })
          handleToggleModal()
        }
        return null
      })
      .catch(err => {
        const errorMessage = _.get(err, "response.data.errors[0].detail", "")
        addToast(`${errorMessage}`, { appearance: "error" })
      })
  }

  if (role === "admin") return null
  return (
    <>
      <div className="dropdown-divider" />
      <button
        className="btn-outline-orange"
        onClick={handleToggleModal}
        type="button">
        Unenrol User from the program
      </button>
      <Modal backdrop="static" onHide={handleToggleModal} show={showModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Unenrol user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleToggleModal} variant="secondary">Cancel</Button>
          <Button onClick={handleUnenrolUser} variant="primary">Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

  export default UnenrolModal