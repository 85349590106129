import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getTemplates } from "../../requesters/Backend/CalculatorRequester"

const BackendTemplatesDash = ({ baseUrl, currentUser }) => {
  const [templateList, setTemplateList] = useState([])
  // TODO: fix filtered list below
  // eslint-disable-next-line no-unused-vars
  const [filteredList, setFilteredList] = useState([])
  const [searchTerm, setSearchTerm] = useState("")

  const fetchTemplates = () => {
    getTemplates(baseUrl, currentUser).then(res => {
      setTemplateList(res.response.json.calculator)
      setFilteredList(res.response.json.calculator)
      return null
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  useEffect(() => {
    fetchTemplates()
  }, [])

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredList(templateList)
    } else {
      const filtered = templateList.filter(
        template => template.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      setFilteredList(filtered)
    }
  }, [searchTerm])

  const onSearchChange = e => {
    setSearchTerm(e.target.value)
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Templates</h2>
            <Link
              to="/backend/create-template"
              className="btn btn-outline-main btn-sm">
              + Add New
            </Link>
          </div>
        </div>
        <div className="table-filters d-inline-flex">
          <input
            className="form-control"
            placeholder="Search Templates"
            type="search"
            onChange={onSearchChange} />
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Template Name</th>
                <th>Type</th>
                <th>Questions</th>
                <th>Last Modified</th>
              </tr>
            </thead>
            <tbody>
              {!!filteredList?.length && filteredList.map(template => (
                <tr key={template.id}>
                  <td>
                    <Link
                      to={`/backend/templates/${template.id}`}
                      className="link">
                      {template.name}
                    </Link>
                  </td>
                  <td>Pulse Survey</td>
                  <td>{template.question_length}</td>
                  <td>{template.updated_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default BackendTemplatesDash