import React from "react"
import ReactTooltip from "react-tooltip"
import WellbeingIndexChartTiny from "../WellbeingIndexChartTiny"
import { CATEGORIES_TOOLTIP } from "./constants"


const BurnoutProofBreakdownCard = ({
  surveyResults
}) => {
  const chartTinyHandler = section => surveyResults?.map(res => ({
    wellbeing_index: (res.subsection_results.find(sr => sr.subsection_name === section).subsection_net_result)
  }))

  return (
    <>
      <div className="dash-row card" id="ditem">
        <div className="card-body">
          <div className="pre-text">BPC</div>
          <h3>Breakdown of Burnout Symptoms</h3>
          <ul className="legend list-inline">
            <li style={{ display: "none" }}></li>
            <li className="">Category Score</li>
          </ul>
          <div className="ditem-grid-container global-wellbeing-grid mental-skills">
            <div id="ditem-grid" />
            <div className="ditem-questions">
              {!!surveyResults.length && surveyResults[0]?.subsection_results.map((section, index) => (
                <div className="row ditem-questions-item no-gutters" key={section.subsection_name}>

                  <div className="col-lg-6 col-xl-5 dimension-question">
                    <div className="burnout-wrapper">
                      <div className="mr-4 d-inline-flex align-items-center">
                        <h6 className="mb-0">{section.subsection_title}</h6>
                        <img
                          data-tip
                          data-for={`sectionDescriptor${index}`}
                          className="ml-2 d-none d-lg-block pointer"
                          src="/assets/icons/icn-info-b20ac7be94a46a48d108384fba227519562e4edd8457d223011e783ad10f0c2f.svg" />
                        <ReactTooltip backgroundColor="#4b4b8b" className="tooltip" effect="solid" id={`sectionDescriptor${index}`} place="right" type="info">
                          <span>{CATEGORIES_TOOLTIP[section.subsection_name]}</span>
                        </ReactTooltip>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="recharts-wrapper">
                          {surveyResults.length > 1 && <WellbeingIndexChartTiny results={chartTinyHandler(section.subsection_name)} />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-xl-7 dimension-graph">
                    <div className="progress" style={{ height: 1, display: "grid" }}>
                      <div className="progress-bar" role="progressbar" style={{ width: `${section.subsection_net_result}%` }} />
                    </div>
                  </div>
                </div>
            ))}
            </div>
          </div>
          <ul className="nav nav-fill ditem-weight">
            <li className="nav-item">0</li>
            <li className="nav-item">25</li>
            <li className="nav-item">50</li>
            <li className="nav-item">75</li>
            <li className="nav-item">100</li>
          </ul>
          <p className="mt-4 mb-0 text-right">
            <small>
              Higher score denotes higher risk
            </small>
          </p>
        </div>
      </div>

      <div className="dash-row card" id="ditem">
        <div className="card-body">
          <div className="ditem-grid-container global-wellbeing-grid mental-skills mt-4">
            <div id="ditem-grid" />
            <div className="ditem-questions">
              <div className="row ditem-questions-item no-gutters">
                <div className="col-lg-6 col-xl-5 dimension-question">
                  <div className="burnout-wrapper">
                    <div className="mr-4 d-inline-flex align-items-center">
                      <h6 className="mb-0">Overall Burnout Perception</h6>
                      <img
                        data-tip
                        data-for="sectionDescriptor_global"
                        className="ml-2 d-none d-lg-block pointer"
                        src="/assets/icons/icn-info-b20ac7be94a46a48d108384fba227519562e4edd8457d223011e783ad10f0c2f.svg" />
                      <ReactTooltip backgroundColor="#4b4b8b" className="tooltip" effect="solid" id="sectionDescriptor_global" place="right" type="info">
                        <span>{CATEGORIES_TOOLTIP.global}</span>
                      </ReactTooltip>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="recharts-wrapper">
                        {surveyResults.length > 1 && <WellbeingIndexChartTiny results={surveyResults.map(res => ({ wellbeing_index:  res.global_perception_result }))} />}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-xl-7 dimension-graph">
                  <div className="progress" style={{ height: 1, display: "grid" }}>
                    <div className="progress-bar" role="progressbar" style={{ width: `${surveyResults[0]?.global_perception_result}%` }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul className="nav nav-fill ditem-weight">
            <li className="nav-item">0</li>
            <li className="nav-item">25</li>
            <li className="nav-item">50</li>
            <li className="nav-item">75</li>
            <li className="nav-item">100</li>
          </ul>
          <p className="mt-4 mb-0 text-right">
            <small>
              Higher score denotes higher risk
            </small>
          </p>
        </div>
      </div>
    </>
    )
}


export default BurnoutProofBreakdownCard
