import React, { useEffect, useState } from "react"
import CustomQuestionCard from "./CustomQuestionCard"
import SystemQuestionCard from "./SystemQuestionCard"

const QuestionCard = ({
  index,
  form,
  setForm,
  question,
  systemQuestionList,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(question)
  const [answerList, setAnswerList] = useState(question.answers)

  const DEFAULT_CUSTOM_ANSWER = {
    label: `Custom Answer ${answerList.length + 1}`,
  }

  const handleUpdateQuestion = e => {
    e.preventDefault()
    setCurrentQuestion({ ...currentQuestion, [e.target.name]: e.target.value })
  }

  const handleDelete = () => {
    const clonedForm = [...form]
    clonedForm.splice(index, 1)
    setForm(clonedForm)
  }

  const handleDeleteOption = e => {
    e.preventDefault()
    const clonedAnswerList = [...answerList]
    clonedAnswerList.splice(e.target.value, 1)
    setAnswerList(clonedAnswerList)
  }

  const handleNewOption = () => {
    if (answerList.length < 20) {
      setAnswerList([...answerList, DEFAULT_CUSTOM_ANSWER])
    }
  }

  const handleUpdateAnswers = e => {
    const copiedArray = [...answerList]
    copiedArray[e.target.name] = e.target.value
    setAnswerList(copiedArray)
  }

  const handleSystemQuestionSelect = e => {
    const selectedSlug = e.target.value
    const selectedQuestion = systemQuestionList.find(q => q.question_slug === selectedSlug)
    setCurrentQuestion(selectedQuestion)
  }

  useEffect(() => {
    const clonedForm = [...form]
    if (currentQuestion.is_system) {
      clonedForm[index] = currentQuestion
    } else {
      clonedForm[index] = { ...currentQuestion, answers: answerList }
    }
    setForm(clonedForm)
  }, [currentQuestion, answerList])

  return (
    <div className="card custom-qns-set">
      <div className="card-header">
        <button type="button" className="close" onClick={handleDelete}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="card-body">
        {currentQuestion.status === "system" ? (
          <SystemQuestionCard
            currentQuestion={currentQuestion}
            handleChange={handleSystemQuestionSelect}
            index={index}
            systemQuestionList={systemQuestionList} />
        ) : (
          <CustomQuestionCard
            answerList={answerList}
            currentQuestion={currentQuestion}
            handleDeleteOption={handleDeleteOption}
            handleNewOption={handleNewOption}
            handleUpdateAnswers={handleUpdateAnswers}
            handleUpdateQuestion={handleUpdateQuestion}
            index={index} />
        )}
      </div>
    </div>
  )
}

export default QuestionCard