import React from "react"
import { Link } from "react-router-dom"
import _ from "lodash"

const ProgramTableCell = ({ flexRender, cell }) => {
  const rowData = _.get(cell, "row.original")
  const cellType = cell?.column?.columnDef?.id

  switch (cellType) {
    case "organisation_id": {
      const { organisation_id, organisation_name } = rowData
      return (
        <td key={cell.id}>
          <Link to={`/backend/organisations/${organisation_id}`}>{organisation_name}</Link>
        </td>
      )
    }
    case "unit_id": {
      const { unit_id, unit_name } = rowData
      return (
        <td key={cell.id}>
          <Link to={`/backend/units/${unit_id}`}>{unit_name}</Link>
        </td>
      )
    }
    case "id": {
      if (!rowData.unique_id) return <td />
      const { id, name } = rowData
      return (
        <td key={cell.id}>
          <Link to={`/backend/programs/${id}`}>{name}</Link>
        </td>
      )
    }
    default:
      return (
        <td key={cell.id}>
          {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
        </td>
      )
  }
}

export default ProgramTableCell
