import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Button, Modal } from "react-bootstrap"
import { getUserDetail, sendAdminResetPassword, sendResetPassword } from "../../requesters/Backend/UserRequester"
import UpdateEnrolment from "./UserDetail/UpdateEnrolment"
import UnenrolModal from "./UserDetail/UnenrolModal"

// Might be required in future
const getPdfUrl = id => `/calculator_results/${id}.pdf`
// const getPdfUrl = id => `${window.location.origin}/calculator_results/${id}.pdf`

const UserDetailPage = ({ baseUrl, currentUser, match }) => {
  const [userDetail, setUserDetail] = useState({})
  const [enrolmentDetails, setEnrolmentDetails] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [sendingToUser, setSendingToUser] = useState(true)
  const { addToast } = useToasts()

  const {
    burnout_proof_latest_submitted_id,
    latest_submitted_id: ten_dwc_submitted_id,
    matchfit_latest_submitted_id,
    mental_skills_latest_submitted_id,
    live_life_latest_submitted_id,
  } = enrolmentDetails

  const fetchUserDetail = () => {
    getUserDetail(baseUrl, match?.params?.userId, currentUser)
      .then(res => {
        if (res?.response?.json?.user) {
          setUserDetail(res.response.json.user)
        }
        if (res?.response?.json?.user?.active_enrolment) {
          setEnrolmentDetails(res.response.json.user?.active_enrolment)
        }
        return null
      })
      .catch(err => {
        addToast(`${err}, User not found`, { appearance: "error" })
      })
  }

  useEffect(() => {
    fetchUserDetail()
  }, [])

  const handleToggleModal = () => {
    setShowModal(!showModal)
  }

  const handleAdminResetPassword = () => {
    sendAdminResetPassword(baseUrl, userDetail.id, currentUser)
      .then(res => {
        if (res.status === 201) {
          addToast("Reset password email sent", { appearance: "success" })
          handleToggleModal()
        }
        return null
      })
      .catch(err => {
        addToast(`${err}, User not found`, { appearance: "error" })
      })
  }

  const handleSendResetPassword = () => {
    sendResetPassword(baseUrl, userDetail.email, currentUser)
      .then(res => {
        if (res.status === 201) {
          addToast("Reset password email sent", { appearance: "success" })
          handleToggleModal()
        }
        return null
      })
      .catch(err => {
        addToast(`${err}, User not found`, { appearance: "error" })
      })
  }

  const handleAdminResetModal = () => {
    setSendingToUser(false)
    handleToggleModal()
  }

  const handleUserResetModal = () => {
    setSendingToUser(true)
    handleToggleModal()
  }

  // const onChangeArchived = e => {
  //   const newStatus = e.target.checked ? "active" : "inactive"
  //   archiveProgram(baseUrl, e.target.name, newStatus, currentUser).then(res => {
  //     if (res.status === 201) {
  //       addToast(`Program Status successfully updated to: ${newStatus}`, { appearance: "success" })
  //     } else {
  //       addToast(`Program Status not updated`, { appearance: "error" })
  //     }
  //   }).catch(err => {
  //     addToast(`${err}`, { appearance: "error" })
  //   })
  // }

  return (
    <div className="content">
      <div className="container-fluid">
        <Modal backdrop="static" onHide={handleToggleModal} show={showModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>{sendingToUser ? "Reset Password (User)" : "Reset Password (Admin)"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {sendingToUser ? `This will send a reset password email to ${userDetail.email}`
              : `This will send a reset password email to your own email address: ${currentUser.email}. Please copy paste the link in the email into an incognito tab`
              }
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleToggleModal} variant="secondary">Cancel</Button>
            {sendingToUser ? (
              <Button onClick={handleSendResetPassword} variant="primary">Send</Button>
            ) : (
              <Button onClick={handleAdminResetPassword} variant="primary">Send</Button>
            )}
          </Modal.Footer>
        </Modal>
        <div className="form-container">
          <h1>{userDetail.name}</h1>
          <p>{userDetail.joined_on}</p>
          <div className="card-section">
            <div className="card">
              <div className="card-header"><h5>User Details</h5></div>
              <div className="card-body">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="row">Name</th>
                      <td>{userDetail.name}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email</th>
                      <td>{userDetail.email}</td>
                    </tr>
                    <tr>
                      <th scope="row">User ID</th>
                      <td>{userDetail.id}</td>
                    </tr>
                    <tr>
                      <th scope="row">Current Program</th>
                      <td>
                        <Link to={`/backend/programs/${userDetail.program_id}`}>
                          {userDetail.program_name}
                          {" "}
                          -
                          {" "}
                          {userDetail.program_code}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Enrolled at</th>
                      <td>
                        {userDetail.enrolled_at}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Verified at</th>
                      <td>{userDetail.confirmed_at}</td>
                    </tr>
                    <tr>
                      <th scope="row">Last Reset Password</th>
                      <td>{userDetail.reset_password_sent_at}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="2">
                        <button className="btn-outline-orange" onClick={handleUserResetModal} type="button">RESET PASSWORD (Send to User)</button>
                        <div className="dropdown-divider" />
                        {userDetail.role !== "admin" && <button className="btn-outline-orange" onClick={handleAdminResetModal} type="button">RESET PASSWORD (Send to Admin)</button>}
                        <UpdateEnrolment
                          baseUrl={baseUrl}
                          currentUser={currentUser}
                          refetch={fetchUserDetail}
                          userDetail={userDetail} />
                        {userDetail?.program_code && (
                          <>
                            <UnenrolModal
                              baseUrl={baseUrl}
                              currentUser={currentUser}
                              refetch={fetchUserDetail}
                              userDetail={userDetail} />
                          </>
                        )}
                        {!!burnout_proof_latest_submitted_id && (
                          <>
                            <div className="dropdown-divider" />
                            <Link
                              className="btn-outline-orange ml-1"
                              rel="noreferrer"
                              target="_blank"
                              to={getPdfUrl(burnout_proof_latest_submitted_id)}>
                              Latest Burnout Proof Report
                            </Link>
                          </>
                        )}
                        {!!ten_dwc_submitted_id && (
                          <>
                            <div className="dropdown-divider" />
                            <Link
                              className="btn-outline-orange ml-1"
                              rel="noreferrer"
                              target="_blank"
                              to={getPdfUrl(ten_dwc_submitted_id)}>
                              Latest 10DWC
                            </Link>
                          </>
                        )}
                        {!!matchfit_latest_submitted_id && (
                          <>
                            <div className="dropdown-divider" />
                            <Link
                              className="btn-outline-orange ml-1"
                              rel="noreferrer"
                              target="_blank"
                              to={getPdfUrl(matchfit_latest_submitted_id)}>
                              Latest Matchfit
                            </Link>
                          </>
                        )}
                        {!!mental_skills_latest_submitted_id && (
                          <>
                            <div className="dropdown-divider" />
                            <Link
                              className="btn-outline-orange ml-1"
                              rel="noreferrer"
                              target="_blank"
                              to={getPdfUrl(mental_skills_latest_submitted_id)}>
                              Latest Mental Skills
                            </Link>
                          </>
                        )}
                        {!!live_life_latest_submitted_id && (
                          <>
                            <div className="dropdown-divider" />
                            <Link
                              className="btn-outline-orange ml-1"
                              rel="noreferrer"
                              target="_blank"
                              to={getPdfUrl(live_life_latest_submitted_id)}>
                              Latest Live Life Report
                            </Link>
                          </>
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserDetailPage