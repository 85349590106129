import React from "react"
// import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
// import { render } from "react-dom"
import WellbeingIndexChart from "../WellbeingIndexChart"
import WellbeingIndexChartLlsBioAge from "../WellbeingIndexChartLlsBioAge"
import IndexGaugeSmall from "../IndexGaugeSmall"
import IndexGaugeSmallLlsBioAge from "../IndexGaugeSmallLlsBioAge"
import WellbeingIndexChartTiny from "../WellbeingIndexChartTiny"
import { ageChangeScoreDisplay } from "./constants"


const LiveLifeBreakdownCard = ({
  surveyResults
}) => {
  const mentalFitnessPrevResults = section => surveyResults?.map(res => ({
    wellbeing_index: (res?.mental_fitness_metric?.mental_fitness_categories?.find(sr => sr.category_name === section).category_score)
  }))

  const iconUpDownRender = score => {
    if (score <= 0) {
      return "/assets/icons/icn-up-e8d5c31c843bf3fb4ac17b435af749fb2606add0806bb29a81761fb0614a8bb4.svg"
    }
    return "/assets/icons/icn-down-fb56867ca128f33ff7661601e208c2c619b2148c1efbb857952c74e036cb2956.svg"
  }

  const mentalFitnessIndexes = surveyResults.map(result => ({ wellbeing_index: result?.mental_fitness_metric?.mental_score, completed_at_month: result?.completed_at_month }))
  const bioAgeIndexes = surveyResults.map(result => ({ wellbeing_index: result?.biological_age_metric?.age_change, completed_at_month: result?.completed_at_month }))

  // BioAge graph cap
  const BioAgeScoreCap = score => {
    if (score < -10) {
      return -10
    } if (score > 15) {
      return 15
    }
    return score
  }

  return (
    <div className="dash-row card lls" id="ditem">
      <div className="card-body">
        <div className="pre-text">LiveLife</div>
        <h3>Your LiveLife Score Breakdown</h3>
        <div className="breakdown-wrapper">
          <div className="breakdown-wrapper-inner">
            <div className="breakdown-section">
              <div>
                <div className="d-flex align-items-center mb-3">
                  <img src="/assets/icons/livelife/bio-age-gauge-18e724858500777172265ac430c03e6006f6bccb1319628aad1773f1bba09376.png" />
                  <h4 className="mb-0 ml-3">Biological Age</h4>
                </div>
                <small>
                  Biological Age indicates how well your body is functioning right now. Biological
                  Age calculates physical fitness, metabolic health and lifestyle factors that have been identified in scientific studies as major contributors to morbidity (chronic
                  disease) and longevity. If you rank well, your Biological Age will be lower than your
                  chronological age, with the overall goal to be 5 years younger. And it will be higher
                  than your chronological age if you have known health and longevity behavioural
                  risk factors. Biological Age, unlike chronological age, is reversible as assessment
                  items are all modifiable.
                </small>
              </div>
              <div className="col-md-5 p-0">
                <IndexGaugeSmallLlsBioAge
                  barIndex={surveyResults[0]?.biological_age_metric?.biological_age_percent}
                  color={surveyResults[0]?.biological_age_metric?.bio_age_color}
                  index={ageChangeScoreDisplay(surveyResults[0]?.biological_age_metric?.age_change)} />
              </div>
            </div>

            <a className="btn btn-yellow btn-chevron btn-sm mb-5" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="bioAgeTrend" href="#bioAgeTrend">See your trend</a>
            <div className="collapse" id="bioAgeTrend">
              <div className="mb-5">
                <h6>Biological Age Trend</h6>
                <div className="wellbeingTrend">
                  <div className="colour-bar">
                    <div className="bar-item bg-green" />
                    <div className="bar-item bg-gold" />
                    <div className="bar-item bg-red" />
                    <div className="bar-item bg-red" />
                    <div className="bar-item bg-red" />
                  </div>
                  <WellbeingIndexChartLlsBioAge results={bioAgeIndexes} />
                </div>
              </div>
            </div>
            <div className={`data-table ${surveyResults[0]?.biological_age_metric?.show_age_difference_note ? null : "mb-5"}`}>
              <dl>
                <dt><strong>Biological Age</strong></dt>
                <dd><strong>{surveyResults[0]?.biological_age_metric?.biological_age}</strong></dd>
              </dl>
              <dl>
                <dt>Chronological Age</dt>
                <dd>{surveyResults[0]?.biological_age_metric?.age}</dd>
              </dl>
              <dl>
                <dt>Difference</dt>
                <dd>{`${surveyResults[0]?.biological_age_metric?.show_age_difference_note ? "*" : ""}${ageChangeScoreDisplay(surveyResults[0]?.biological_age_metric?.age_change)}`}</dd>
              </dl>
            </div>

            {surveyResults[0]?.biological_age_metric?.show_age_difference_note && (
              <div className="mt-2 mb-3">
                <small>
                  * Minimum biological age is 18 years. The most an individual can reduce their
                  overall Biological Age is 10 years. Conversely, the most Biological Age can increase
                  is 15 years.
                </small>
              </div>
            )}

            <div className="biological-age">
              <div className="ditem-grid-container livelifescore-grid">
                <div id="ditem-grid" />
                <div className="ditem-questions">
                  {!!surveyResults[0]?.biological_age_metric?.bio_age_categories.length && surveyResults[0]?.biological_age_metric?.bio_age_categories.map(category => (
                    <div className="row ditem-questions-item no-gutters" key={category?.category_name}>
                      <div className="col-lg-6 dimension-question">
                        <div className="d-flex justify-content-between mr-4">
                          <div className="mr-4 d-inline-flex align-items-center">
                            <h6 className="mb-0">{category?.category_name}</h6>
                            <img
                              data-tip
                              data-for={`sectionDescriptor${category?.category_name}`}
                              className="ml-2"
                              src="/assets/icons/icn-info-b20ac7be94a46a48d108384fba227519562e4edd8457d223011e783ad10f0c2f.svg" />
                            <ReactTooltip backgroundColor="#4b4b8b" className="tooltip" effect="solid" id={`sectionDescriptor${category?.category_name}`} place="top" type="info">
                              <span>{category?.category_tooltip}</span>
                            </ReactTooltip>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              <strong className="mr-2">{ageChangeScoreDisplay(category?.category_score)}</strong>
                              {surveyResults.length > 1 && (
                                <img src={iconUpDownRender(category?.category_score)} />
                              )}
                            </div>
                          </div>
                        </div>
                        {/* <div className="d-lg-none mt-3">Is the ability to maintain a healthy quality of life that allows us to get the most out of daily activities without undue fatigue or physical stress.</div> */}
                      </div>
                      <div className="col-lg-6 dimension-graph">
                        <div className="progress" style={{ height: "1px", display: "grid" }}>
                          {category?.category_score >= 0 ? (
                            <div className="progress-bar" role="progressbar" style={{ width: `${Math.round((100 / 15) * BioAgeScoreCap(category?.category_score))}%` }} />
                          ) : (
                            <div className="progress-bar" role="progressbar" style={{ width: `calc(calc(100% - 33.3%) * ${Math.abs(BioAgeScoreCap(category.category_score)) / 10})`, transform: "translate(-100%, 10px) rotate(180deg)" }} />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="colour-bar">
                <div className="bar-item bg-green" />
                <div className="bar-item bg-green" />
                <div className="bar-item bg-red" />
                <div className="bar-item bg-red" />
                <div className="bar-item bg-red" />
              </div>
              <ul className="nav nav-fill ditem-weight">
                <li className="nav-item">-10</li>
                <li className="nav-item">-5</li>
                <li className="nav-item">0</li>
                <li className="nav-item">+5</li>
                <li className="nav-item">+10</li>
                <li className="nav-item">+15</li>
              </ul>
            </div>
          </div>
          <div className="breakdown-wrapper-inner">
            <div className="breakdown-section">
              <div>
                <div className="d-flex align-items-center mb-3">
                  <img src="/assets/icons/livelife/mental-fitness-gauge-6ed349d2ae8c98fd6e22de697afb70e935eb8d8da0f139f05e4d98e25f416c2d.png" />
                  <h4 className="mb-0 ml-3">Mental Fitness Gauge</h4>
                </div>
                <small>
                  Mental Fitness is your ability to manage stress state, think clearly and make
                  decisions. It means keeping your brain and emotional wellness in good shape.
                  Being mentally fit enables you to respond to the domains of everyday life and have
                  a buffer/capacity to fully engage in work and in your personal life, with a high sense
                  of achievement.
                  Your Mental Fitness Gauge is calculated from measures relating to Emotional
                  Wellness, Energy Performance, Stress & Coping and Strategic Recovery.
                </small>
              </div>

              <div className="col-md-5 p-0">
                <IndexGaugeSmall
                  color={surveyResults[0]?.mental_fitness_metric?.mental_color}
                  index={surveyResults[0]?.mental_fitness_metric?.mental_score} />
              </div>
            </div>
            <a className="btn btn-yellow btn-chevron btn-sm mb-5" role="button" data-toggle="collapse" aria-expanded="false" aria-controls="mental-guage-trend" href="#mental-guage-trend">See your trend</a>
            <div className="collapse" id="mental-guage-trend">
              <div className="mb-5">
                <h6>Mental Fitness Trend</h6>
                <WellbeingIndexChart results={mentalFitnessIndexes} />
              </div>
            </div>
            <div className="mental-gauge">
              <div className="ditem-grid-container global-wellbeing-grid">
                <div id="ditem-grid" />
                <div className="ditem-questions">
                  {!!surveyResults[0]?.mental_fitness_metric?.mental_fitness_categories?.length && surveyResults[0]?.mental_fitness_metric?.mental_fitness_categories.map(category => (
                    <div className="row ditem-questions-item no-gutters" key={category?.category_name}>
                      <div className="col-lg-6 dimension-question">
                        <div className="d-flex justify-content-between mr-4">
                          <div className="mr-4 d-inline-flex align-items-center">
                            <h6 className="mb-0">{category?.category_name}</h6>
                            <img
                              data-tip
                              data-for={`sectionDescriptor${category?.category_name}`}
                              className="ml-2"
                              src="/assets/icons/icn-info-b20ac7be94a46a48d108384fba227519562e4edd8457d223011e783ad10f0c2f.svg" />
                            <ReactTooltip backgroundColor="#4b4b8b" className="tooltip" effect="solid" id={`sectionDescriptor${category?.category_name}`} place="top" type="info">
                              <span>{category?.category_tooltip}</span>
                            </ReactTooltip>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="recharts-wrapper">
                              {surveyResults.length > 1 && <WellbeingIndexChartTiny results={mentalFitnessPrevResults(category?.category_name)} />}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 dimension-graph">
                        <div className="progress" style={{ height: "1px", display: "grid" }}>
                          <div className="progress-bar" role="progressbar" style={{ width: `${category?.category_score}%` }} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="colour-bar">
                <div className="bar-item bg-red" />
                <div className="bar-item bg-red" />
                <div className="bar-item bg-orange" />
                <div className="bar-item bg-gold" />
                <div className="bar-item bg-green" />
              </div>
              <ul className="nav nav-fill ditem-weight">
                <li className="nav-item">0</li>
                <li className="nav-item">25</li>
                <li className="nav-item">50</li>
                <li className="nav-item">75</li>
                <li className="nav-item">100</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LiveLifeBreakdownCard
