import React from "react"
import {
  LineChart, Line, CartesianGrid, ResponsiveContainer, XAxis, YAxis
} from "recharts"

const WellbeingIndexChart = ({ results }) => {
  const datas = [...results.map(r => ({ name: r.completed_at_month, value: r.wellbeing_index }))].reverse()

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={500}
        height={300}
        data={datas}
        margin={{
          top: 32, right: 5, bottom: 5, left: 0
        }}>
        <Line type="monotone" dataKey="value" stroke="#2A317D" strokeWidth={2} dot={{ stroke: "#2A317D", strokeWidth: 8 }} />
        <CartesianGrid stroke="#DCDDDE" />
        <XAxis dataKey="name" axisLine={false} tickLine={false} padding={{ left: 30, right: 30 }} tickMargin={20} height={40} />
        <YAxis domain={[0, 100]} axisLine={false} tickLine={false} tickMargin={20} />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default WellbeingIndexChart
