import React from "react"
import { Link } from "react-router-dom"
import { DashboardBody } from "../styles"

const AboutMatchfit = () => (
  <DashboardBody>
    <div className="container dashboard py-5">
      <div className="d-flex align-items-center my-4">
        <Link className="btn btn-outline-main" to="/matchfit-dashboard">Back</Link>
      </div>
      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <div className="pre-text">MFC</div>
              <h1 className="mb-4">What is MFC?</h1>
              <p>The MatchFit Calculator (MFC) is an evidence-based online assessment designed by the StriveStronger Research Institute that measures your physical and psychological wellbeing.</p>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-xl-9">
              <h4>The Origins of MatchFit</h4>
              <p>MatchFit is being able to play and compete at a consistently high level and continue performing at your best in an ever- changing environment. It is also about negating the biological decline of the body and brain post 40 years of age.</p>
              <p>The concept is based on the best-selling book, MatchFit, which brings together decades of Andrew May and Dr Tom Buckley&apos;s experience as athletes and sports coaches; degrees in exercise physiology, coaching psychology, health and medical sciences; and their experience in the corporate and clinical worlds.</p>
              <p>MatchFit is a holistic program that combines high-performance strategies from sports science, exercise physiology, performance psychology and medical science (including cardiac science and neuroscience) to help people optimise their life in six key areas: MOVE, FUEL, RECHARGE, CONNECT, THINK and PLAY.</p>
              <p>The power of being MatchFit lies in the way that these aspects work together. The scientific literature is increasingly highlighting how physical fitness and psychosocial wellness interrelate to promote healthy ageing and holistic wellbeing. MatchFit is three separate, yet interrelated components, including a:</p>
              <ol>
                <li>Metric (the MatchFit Calculator)</li>
                <li>Feeling (Energetic, vibrant and fresh)</li>
                <li>Way of Living (Optimising your body and brain)</li>
              </ol>
            </div>
          </div>

        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">The Importance of Being MatchFit</h2>
              <p>It has never been more important for employees, people leaders and entire organisations to focus on their physical and psychological wellbeing.</p>
              <p>The 6 MatchFit Levers contribute to physical, psychological, emotional, spiritual, social, intellectual, occupational and environmental health and vitality - meaning high performance whenever it is needed, and a life that is connected, longer, richer and more fulfilling.</p>
              <p>Being MatchFit builds capacity to manage fatigue and has been proven to reduce the risk of burnout. Being MatchFit means performing at your best in all areas of your life.</p>

              <div className="mt-5">
                <h4>Expanding Physical Capacity</h4>
                <p>Most adults reach their physiological peak at around 30 years of age. After that, unless you train regularly, especially resistance training, the body can potentially lose up to a kilogram of muscle every year and gain half a kilogram of fat.</p>
                <p>Following the MatchFit program supports you to keep a buffer between your physical capacity and the demands on your body and brain. You&apos;ll notice a circle where demand meets capacity. For many people, particularly men, this happens between 50 to 55 years of age.</p>
              </div>

              <div className="row">
                <div className="col-xl-9">
                  <img src="/assets/matchfit-physical-chart-c993c370e68ef38e224e8241409e805103014dfa81b1728a3971a986a21c2c59.svg" alt="MFC physical chart" width="100%" />
                </div>
              </div>

              <div className="mt-5">
                <h4>Expanding Psychological Capacity</h4>
                <p>Just when we need our bodies and brains to be at their best, they inevitably start to decline. Not only do our creativity and memory recall start to decline, but many hormonal levels (including human growth hormone DHEA, testosterone and, for women, progesterone and estrogen) begin dropping, which decreases muscle mass and changes the shape of our bodies. MatchFit is all about preserving physical and psychological function throughout life.</p>
              </div>

              <div className="row">
                <div className="col-xl-9">
                  <img src="/assets/matchfit-psychological-chart-75092c3928d9e1cc694f30f3a5011f6171908d772bac7450b9ff05b60b0b6bca.svg" alt="MFC psychological chart" width="100%" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-5">Understanding Your Results</h2>
              <h4>Your overall MatchFit Score</h4>

              <p>The MatchFit Score is calculated out of 100 and the scores defined in the following categories:</p>
              <li>85 and above - MatchFit</li>
              <li>Between 65-85 - Striving</li>
              <li>Between 50-65 - Surviving</li>
              <li>Less than 50 - Languishing</li>

              <p>As you complete more than one MFC assessment, a trend of your recent MatchFit scores is shown on your calculator dashboard.</p>

              <div className="mt-5">
                <h4>Breakdown of the MatchFit Score</h4>
                <p>The MatchFit Score is broken down into 2 main categories:</p>
                <ol>
                  <li>
                    your
                    {" "}
                    <b>MatchFit Body Score</b>
                    {" "}
                    is calculated from your Physical Recovery, Physical Functioning, Nutrition, Movement and Biological Age, Physical Activity Profile, Recovery Rating and Nutrition.
                  </li>
                  <li>
                    your
                    {" "}
                    <b>MatchFit Brain Score</b>
                    {" "}
                    is calculated from measures relating to Switching Off, Social Connectedness, Psychological Performance, Emotional Wellbeing and Cognitive Flexibility
                  </li>
                </ol>
                <p>Both your MatchFit Body Score and MatchFit Brain Score are shown on your calculator dashboard.</p>
                <p>A further breakdown and explanation of your scores can be viewed by clicking on the “&gt;” icon next to each subcategory.</p>
              </div>
              <div className="mt-5">
                <h4>Your Recommendations</h4>
                <p>A set of recommendations is tailored for you based on your overall MatchFit Score and your three lowest scores across both the MatchFit Body and MatchFit Brain categories.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">Accessing Your Latest Report</h2>
              <p>You can download a PDF report of your latest MFC assessment at any time by clicking on the “Download Latest Report” button. This report contains your latest results, information on MFC and your recommendations in one easy-to-read document. You are also sent a copy of this report via email each time you complete an assessment.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardBody>
)

export default AboutMatchfit
