import React from "react"
import { Link } from "react-router-dom"
import { DashboardBody } from "../styles"

const AboutLiveLife = () => (
  <DashboardBody>
    <div className="container dashboard py-5">
      <div className="d-flex align-items-center my-4">
        <Link className="btn btn-outline-main" to="/livelife-dashboard">Back</Link>
      </div>
      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <div className="pre-text">LLS</div>
              <h1 className="mb-4">What is LiveLife Score?</h1>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-xl-9">
              <h4>Wellbeing and Living Life</h4>
              <p>Wellbeing, or to be well, comes from the old English word &apos;wiellan&apos; meaning &apos;to spring, rise, flow.&apos; It is the act of looking at ourselves and looking at our lives with a holistic view that helps us to &apos;spring into life&apos; or &apos;to rise up.&apos; At StriveStronger, we call this &apos;living life&apos;.</p>
              <div className="mt-5">
                <h4>It Pays to be Well</h4>
                <p>Wellbeing is associated with decreased risk of disease, illness, and injury; better immune functioning; speedier recovery; increased longevity; and people who report high levels of wellbeing are more productive at work and are more likely to contribute to their communities.</p>
                <p>Wellbeing is not just about being physically or psychologically able. It is about being connected and in harmony across multiple parts of our lives. Wellbeing also fluctuates depending on circumstances and events and is not a static measure.</p>
              </div>
              <div className="mt-5">
                <h4>The LiveLife Score</h4>
                <p>The LiveLife Score is an evidenced-based metric designed by the StriveStronger Research Institute. This includes both Biological Age, based on scientific evidence measuring your physiological capacity to meet demands of everyday life and the ability to spend and restore energy, which incorporates physical fitness, metabolic health, and key lifestyle factors. And a Mental Fitness Gauge measuring the ability to manage stress, think clearly and make better decisions. These metrics, identified from reviewing hundreds of scientific studies, are considered essential building blocks of sustained wellbeing and positive functioning.</p>
                <p>The LiveLife Score is designed with an efficient, easy to understand method for tracking modifiable physical wellbeing and mental fitness metrics. Scientific literature highlights many people become overwhelmed when data is too complex or detailed. Studies also show self-tracking and self-reflection are crucial for stress management and mental wellbeing.</p>
                <p>The LiveLife Score delivers a user-friendly way of collecting and presenting information known to promote self-improvement in physical activity, weight loss, sleep and increased personal productivity.</p>
              </div>
            </div>
          </div>

          <div className="row mt-5 ">
            <img
              className="ml-3"
              src="/assets/icons/livelife/about-lls-134aed26f9aeb155712990921948d87c72cd587a32a09eb44541460ee1758a92.png"
              alt="LiveLife Categories chart"
              width="70%" />
          </div>

        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">Understanding Your Results</h2>
              <h4>Your overall LiveLife Score</h4>
              <p>The LiveLife Score is calculated out of 100 and the scores defined in the following categories:</p>
              <li>85 and above - LifeFit</li>
              <li>Between 65-85 - Striving</li>
              <li>Between 50-65 - Surviving</li>
              <li>Less than 50 - Struggling</li>

              <div className="mt-3">
                <p>As you complete more than one LiveLife Score survey, a trend of your recent LiveLife Scores is shown on your calculator dashboard.</p>
              </div>

              <div className="mt-5">
                <h4>Breakdown of the LiveLife Score</h4>
                <p>The LiveLife Score is broken down into 2 main categories:</p>
                <ol>
                  <li>
                    <span className="strong">Biological Age</span>
                    {" "}
                    indicates how well your body is functioning right now and is calculated from Physical Activity, Strength and Health Behaviours
                  </li>
                  <li>
                    <span className="strong">Mental Fitness Gauge</span>
                    {" "}
                    shows your ability to manage stress state, think clearly, make decisions and is calculation from Emotional Wellness, Energy Performance, Stress & Coping and Strategic Recovery.
                  </li>
                </ol>
                <div className="mt-3">
                  <p>Both your Biological Age and Mental Fitness Gauge are shown on your calculator dashboard.</p>
                  <p>
                    A further explanation of each scoring sub-category can be viewed by clicking on the
                    <img
                      className="mx-2"
                      src="/assets/icons/icn-info-b20ac7be94a46a48d108384fba227519562e4edd8457d223011e783ad10f0c2f.svg" />
                    icon next to each subcategory.
                  </p>
                </div>
              </div>

              <div className="mt-5">
                <h4>Your Recommendations</h4>
                <p>A set of recommendations is tailored for you based on your overall LiveLife Score and your three lowest scores across both the Biological Age and Mental Fitness Gauge.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dash-row card">
        <div className="card-body">
          <div className="row">
            <div className="col-xl-9">
              <h2 className="mb-4">Accessing Your Latest Report</h2>
              <p>You can download a PDF report of your latest LiveLife Score at any time by clicking on the “Download Latest Report” button. This report contains your latest results, information on the LiveLife Score and your recommendations in one easy-to-read document. You are also sent a copy of this report via email each time you complete an assessment.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardBody>
)

export default AboutLiveLife
