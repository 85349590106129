/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { DashboardBody } from "../styles"
import { getPulseDashboardResults } from "../../../requesters/CalculatorResultsRequester"
import WellbeingIndexChart from "../WellbeingIndexChart"
import IndexGauge from "../IndexGauge"
import PulseBreakdownCard from "./PulseBreakdownCard"
import { OVERVIEW_PAGE_CONTENT } from "../constants"

const Dashboard = ({ baseUrl, currentUser }) => {
  const [surveyResults, setSurveyResults] = useState([])
  const [previousHistory, setPreviousHistory] = useState([])
  // const [wellbeingIndexes, setWellbeingIndexes] = useState([])
  // const [results, setResults] = useState({})
  const [inflight, setInflight] = useState(true)

  useEffect(() => {
    document.title = "iStrive | Pulse Check Dashboard"
  }, [])

  const fetchResults = () => {
    getPulseDashboardResults(baseUrl, currentUser).then(res => {
      if (res.response.json?.calculator_results) {
        // const recentResults = res.response.json?.calculator_results
        setSurveyResults(res.response.json?.calculator_results)
        setPreviousHistory(res.response.json?.previous_history)
        // setResults(res.response.json?.calculator_results[0]?.calc_responses)
        // setWellbeingIndexes(recentResults.map(result => result.wellbeing_index))
      }
      setInflight(false)
      return null
    }).catch(err => {
      setInflight(false)
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  useEffect(() => {
    fetchResults()
  }, [])

  if (inflight) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_enrolment) {
    window.location.href = "/overview"
  } else if (!surveyResults?.length && !inflight) {
    window.location.href = "/overview"
  }

  return (
    <DashboardBody>
      <div className="content">
        <div className="container dashboard" id="dashboard-10dwc">
          <div className="d-flex align-items-center justify-content-between py-4">
            <div className="d-flex align-items-center">
              <Link
                to="/overview"
                className="back-to-overview">
                <img src="/assets/icons/icn-back-009e79b5747b122738def7d3b12743f2fc8a033afbefde260aa458eb38a9f2db.svg" />
              </Link>
              <img src={OVERVIEW_PAGE_CONTENT.pulse_check.logoSrc} />
            </div>
            <div className="start-survey">
              <Link className="btn btn-main ml-4" to="/pulse-check/questions">Start Survey</Link>
              <div className="small">{`Last completed on ${surveyResults[0]?.completed_at}`}</div>
            </div>
          </div>
          <div className="dash-row row wellness-index">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="pre-text">Your Pulse Index</div>
                  <div className="gauge">
                    <IndexGauge
                      category={surveyResults[0]?.wellbeing_index_category}
                      color={surveyResults[0]?.wellbeing_index_color}
                      index={surveyResults[0]?.wellbeing_index} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <div className="pre-text">Your Pulse Index Trend</div>
                  <WellbeingIndexChart results={surveyResults} />
                </div>
              </div>
            </div>
          </div>
          <PulseBreakdownCard previousHistory={previousHistory} surveyResults={surveyResults} />
        </div>
      </div>
    </DashboardBody>
  )
}

export default Dashboard
