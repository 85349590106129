import React from "react"

const ScoringSplit = ({
  form, setForm
}) => {
  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }
  return (
    <div className="form-group" style={{ pointerEvents: "none" }}>
      <label>Minimum score to achieve (%)</label>
      <div className="row mt-3">
        <div className="col-3">
          <div className="colour-block bg-red" />
          <input
            className="form-control"
            type="text"
            onChange={onChange}
            name="languishing_calculation"
            defaultValue={form.languishing_calculation || 0} />
          <input
            className="form-control"
            type="text"
            defaultValue="Languishing" />
        </div>
        <div className="col-3">
          <div className="colour-block bg-orange" />
          <input
            className="form-control"
            type="text"
            onChange={onChange}
            name="surviving_calculation"
            defaultValue={form.surviving_calculation || 50} />
          <input
            className="form-control"
            type="text"
            defaultValue="Surviving" />
        </div>
        <div className="col-3">
          <div className="colour-block bg-yellow" />
          <input
            className="form-control"
            type="text"
            onChange={onChange}
            name="striving_calculation"
            defaultValue={form.striving_calculation || 65} />
          <input
            className="form-control"
            type="text"
            defaultValue="Striving" />
        </div>
        <div className="col-3">
          <div className="colour-block bg-green" />
          <input
            className="form-control"
            type="text"
            onChange={onChange}
            name="flourishing_calculation"
            defaultValue={form.flourishing_calculation || 85} />
          <input
            className="form-control"
            type="text"
            defaultValue="Flourishing" />
        </div>
      </div>
    </div>
  )
}

export default ScoringSplit