import React from "react"
import Answer from "./Answer"

const Question = ({
  answers, descriptor, formIndex, label, longType, onChangeHandler, selectedAnswer, surveyType
}) => {
  let surveyTypeSuffix = surveyType ? `-${surveyType}` : ""
  if (longType) {
    surveyTypeSuffix = `-long`
  }
  return (
    <div className={`q-group${surveyTypeSuffix}`}>
      <p>{label}</p>
      {descriptor && (
        <div className="mb-3 small">
          <i>{descriptor}</i>
        </div>
      )}
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        {answers && answers.map(answer => (
          <Answer
            answer={answer}
            onChangeHandler={onChangeHandler}
            key={answer.id}
            selectedAnswer={selectedAnswer}
            formIndex={formIndex} />
        ))}
      </div>
    </div>
  )
}

export default Question