import React from "react"

const PreviewAnswer = ({
  answer
}) => (
  <button
    className="btn btn-survey"
    id={answer.id}
    slug={answer.slug}
    type="button"
    defaultValue={answer.label}>
    <input type="radio" />
    {answer.label}
  </button>
)

export default PreviewAnswer