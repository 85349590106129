import React, { useEffect, useState, useCallback } from "react"
import { Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import dayjs from "dayjs"
import { ReactTags } from "react-tag-autocomplete"

import {
  getProgramDetail, getProgramStats, updateProgramDetail
} from "../../requesters/Backend/ProgramRequester"

import { getTagsForProgram, saveTag } from "../../requesters/Backend/TagRequester"

import { getLearnWorldProduct } from "../../requesters/Backend/LearnWorldProductRequester"
import {
  exportUsersCsv, getTemplates, setSelectedPulseSurvey, createReminders
} from "../../requesters/Backend/CalculatorRequester"
import { convertSnakeToTitle } from "../../util"
import { ALLOWED_CALCULATOR_TYPES } from "../Dashboard/constants"
import SwitchWithInflight from "./Programs/SwitchWithInflight"
// eslint-disable-next-line no-unused-vars
import Reminder from "./Programs/Reminder"

function debounce(fn, delay = 100) {
  let timeoutID

  return function (...args) {
    clearTimeout(timeoutID)
    timeoutID = setTimeout(() => fn(...args), delay)
  }
}

const ProgramDetailPage = ({
  baseUrl, currentUser, match
}) => {
  const today = dayjs().format("YYYY-MM-DD")
  const programId = match?.params?.programId
  const [form, setForm] = useState([])
  const [changesDetected, setChangesDetected] = useState(false)
  const [programDetail, setProgramDetail] = useState({})
  const [learnWorldProducts, setLearnWorldProducts] = useState({})
  const [customTemplates, setCustomTemplates] = useState([])
  const [selectedCustomTemplate, setSelectedCustomTemplate] = useState({})
  const [selectedTag, setSelectedTag] = useState([])
  const [isUpdatingTemplate, setIsUpdatingTemplate] = useState(false)
  const [calculatorPublishDate, setCalculatorPublishDate] = useState("")
  const [startDate, setStartDate] = useState("")
  const [integrationType, setIntegrationType] = useState("")
  const [endDate, setEndDate] = useState(today)
  const [reminderList, setReminderList] = useState([])
  const [calculatorStats, setCalculatorStats] = useState([])
  const [programStats, setProgramStats] = useState([])
  const { addToast } = useToasts()
  const minDate = dayjs(programDetail?.created_at).format("YYYY-MM-DD")
  const [suggestions, setSuggestions] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  const fetchProgramDetail = () => {
    getProgramDetail(baseUrl, programId, currentUser)
      .then(res => {
        const program = res.response?.json?.program
        if (program) {
          setForm(program?.socio_question_set?.questions_status)
          setProgramDetail(program)
          setSelectedCustomTemplate(program?.current_pulse_check)
          setReminderList(program?.current_pulse_check?.reminders || [])
          setCalculatorPublishDate(program?.current_pulse_check?.published_date)
          setStartDate(dayjs(program?.created_at).format("YYYY-MM-DD"))
          setIntegrationType(program?.integration_type)
          setSelectedTag(program?.tags)
        }
        return null
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(typeof err, Object.keys(err))
        addToast(`Program not found: ${err.errors[0].detail}`, { appearance: "error" })
      })
  }

  const fetchLearnWorldProduct = () => {
    getLearnWorldProduct(baseUrl, currentUser)
      .then(res => {
        const products = res.response?.json?.products
        if (products) {
          setLearnWorldProducts(products)
        }
        return null
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(typeof err, Object.keys(err))
        addToast(`Learn World Products not found: ${err.errors[0].detail}`, { appearance: "error" })
      })
  }


  const fetchProgramStats = (startDate, endDate) => {
    const params = { program_id: programId, start_date: startDate, end_date: endDate }
    getProgramStats(baseUrl, params, currentUser)
      .then(res => {
        const program = res.response?.json?.program
        const mis = res.response?.json?.mis
        if (program) {
          setProgramStats(mis)
          setCalculatorStats(program)
        }
        return null
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log(typeof err, Object.keys(err))
        addToast(`Program not found: ${err.errors[0].detail}`, { appearance: "error" })
      })
  }

  const fetchTemplates = () => {
    getTemplates(baseUrl, currentUser).then(res => {
      setCustomTemplates(res.response.json.calculator)
      return null
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }


  const fetchTags = value => {
    try {
      const query = encodeURIComponent(value)
      const response = getTagsForProgram(baseUrl, query, currentUser)

      return response.then(res => res.response.json.tags)
    } catch (error) {
      return []
    }
  }

  useEffect(() => {
    fetchProgramDetail()
    fetchTemplates()
    fetchLearnWorldProduct()
  }, [])


  useEffect(() => {
    if (endDate && startDate && endDate >= startDate) {
      const start = dayjs(startDate).toISOString()
      const end = dayjs(endDate).endOf("day").toISOString()
      fetchProgramStats(start, end)
    } else if (endDate && startDate && endDate < startDate) {
      addToast("End date should be greater than start date", { appearance: "error" })
    }
  }, [startDate, endDate])

  function onSubmit() {
    const payload = { ...programDetail }
    if (changesDetected) {
      payload.active = form.map(item => item.active && item.label)
      payload.inactive = form.map(item => !item.active && item.label)
      payload.tags = selectedTag.map(tag => tag.value)

      updateProgramDetail(baseUrl, payload, currentUser).then(res => {
        if (res.response.status === "ok") {
          // window.location.href = `/backend/units/${programDetail.unit_id}`
          addToast("Program updated successfully", { appearance: "success" })
          // window.location.reload()
          fetchProgramDetail()
          setChangesDetected(false)
        }
      })
    }
    if (selectedCustomTemplate && isUpdatingTemplate) {
      const form = { ...selectedCustomTemplate, program_id: programId, new_published_date: calculatorPublishDate }
      setSelectedPulseSurvey(baseUrl, form, currentUser)
        .then(res => {
          const selectedCalculatorID = res?.response?.json?.calculator_id
          if (selectedCalculatorID && reminderList?.length) {
            const form = { reminder_data: reminderList, calculator_id: selectedCalculatorID }
            createReminders(baseUrl, form, currentUser)
          }
        })
        .catch(err => {
          addToast(`Something went wrong: ${err?.request?.responseText}`, { appearance: "error" })
        })
    }
  }

  const onSaveTag = tag => {
    saveTag(baseUrl, tag, currentUser).then(() => {})
  }

  const onUpdatePulseCalculator = e => {
    const selectedTemplateName = e.target.value
    const selectedTemplate = customTemplates.find(template => template.name === selectedTemplateName)
    setSelectedCustomTemplate({ ...selectedTemplate, published_date: dayjs().format("YYYY-MM-DD") })
    setCalculatorPublishDate(dayjs().format("YYYY-MM-DD"))
    setChangesDetected(true)
    setIsUpdatingTemplate(true)
  }

  const onUpdatePublishDate = e => {
    const selectedPublishDate = e.target.value
    setCalculatorPublishDate(selectedPublishDate)
    setIsUpdatingTemplate(true)
    setChangesDetected(true)
  }

  const onChange = e => {
    if (e.target.name === "integration_type") {
      setIntegrationType(e.target.value)
    }
    setChangesDetected(true)
    setProgramDetail({ ...programDetail, [e.target.name]: e.target.value })
  }

  const onSwitchClick = e => {
    setChangesDetected(true)
    const newForm = [...form]
    newForm[e.target.name].active = e.target.checked
    setForm(newForm)
  }

  const DEFAULT_REMINDER = {
    title: "",
    body: "",
    trigger_date: "",
  }

  // eslint-disable-next-line no-unused-vars
  const onAddReminder = () => {
    if (reminderList.length < 5) {
      setReminderList([...reminderList, DEFAULT_REMINDER])
    }
  }

  const handleStartDate = e => {
    setStartDate(e.target.value)
  }

  const handleEndDate = e => {
    setEndDate(e.target.value)
  }

  const onTagAdd = useCallback(
    newTag => {
      setSelectedTag([...selectedTag, newTag])
      setSuggestions([])
      onSaveTag(newTag.value)
      setChangesDetected(true)
    },
    [selectedTag]
  )

  const onTagDelete = useCallback(
    index => {
      setSelectedTag(selectedTag.filter((_, i) => i !== index))
      setChangesDetected(true)
    },
    [selectedTag]
  )

  const onInput = useCallback(
    debounce(async value => {
      if (isBusy) return

      setIsBusy(true)

      try {
        const suggestions = await fetchTags(value)
        setSuggestions(suggestions)
      } catch (error) {
        console.error(error)
      } finally {
        setIsBusy(false)
      }
    }),
    [isBusy]
  )

  const noOptionsText = isBusy && !suggestions.length ? "Loading..." : "No characters found"

  const handleExportUsers = () => {
    exportUsersCsv(baseUrl, programId, currentUser).then(res => {
      if (res.response.status === "ok") {
        addToast(`Export enqueued successfully`, { appearance: "success" })
      } else {
        addToast(`Enqueuing failed: ${_.get(res, "response.errors.0.detail")}`, { appearance: "error" })
      }
    }).catch(err => {
      addToast(`Something went wrong: ${err?.request?.responseText}`, { appearance: "error" })
    })
  }

  if (!programDetail?.name) {
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="form-container">
            <h1>Program Not Found</h1>
            <div>Please double check the Program Id is correct</div>
            {/* TO DO: Error page */}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="form-container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={`/backend/organisations/${programDetail.organisation_id}`}>{programDetail.organisation_name}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/backend/units/${programDetail.unit_id}`}>{programDetail.unit_name}</Link>
              </li>
              <li aria-current="page" className="breadcrumb-item active">{`${programDetail.name}`}</li>
            </ol>
          </nav>
          <h1>Edit Program</h1>
          <div className="card-section">
            <div className="card">
              <div className="card-header"><h5>Program Details</h5></div>
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="program-name">Program name</label>
                  <input className="form-control" name="name" type="text" value={programDetail.name || ""} onChange={onChange} id="program-name" autoComplete="off" />
                </div>
                <div className="form-group">
                  <label htmlFor="offer-redirect-url">Offer Redirect Url</label>
                  <input id="offer-redirect-url" className="form-control" name="offer_redirect_url" type="text" value={programDetail.offer_redirect_url || ""} onChange={onChange} />
                </div>
                <div className="form-group">
                  <label htmlFor="program-header">Header</label>
                  <input className="form-control" name="header" type="text" value={programDetail.header || ""} onChange={onChange} id="program-header" />
                </div>
                <div className="form-group">
                  <label htmlFor="body">Body</label>
                  <textarea className="form-control" name="body" value={programDetail.body || ""} onChange={onChange} id="body" rows="3" />
                </div>
                <div className="form-group">
                  <div>Choose Integration:</div>
                  <div className="btn-group btn-group-toggle">
                    <label className={`btn btn-outline-main ${ integrationType === "learn_world" ? "active" : ""}`} htmlFor="learn_world">
                      <input type="radio" name="integration_type" value="learn_world" checked={integrationType === "learn_world"} onChange={onChange} id="learn_world" />
                      Learn Worlds
                    </label>
                    <label className={`btn btn-outline-main mt-0 ${ integrationType === "kajabi" ? "active" : ""}`} htmlFor="kajabi">
                      <input type="radio" name="integration_type" value="kajabi" checked={integrationType === "kajabi"} onChange={onChange} id="kajabi" />
                      Kajabi
                    </label>
                  </div>
                </div>

                {integrationType === "learn_world" ? (
                  <div>
                    <div className="form-group">
                      <label htmlFor="learn_world_product_id">Select course or bundle</label>
                      <select
                        id="learn_world_product_id"
                        className="form-control"
                        name="learn_world_product_id"
                        value={programDetail.learn_world_product_id || ""}
                        onChange={onChange}>
                        <option value="">Select a course or bundle</option>
                        {learnWorldProducts?.map(group => (
                          <React.Fragment key={group.product_type}>
                            <option disabled></option>
                            <optgroup label={group.product_type} key={group.product_type}>
                              {group.products.map(product => (
                                <option key={product.id} value={product.product_id}>
                                  {product.title}
                                </option>
                              ))}
                            </optgroup>
                          </React.Fragment>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="tags">Tags</label>
                      <ReactTags
                        allowNew
                        labelText="Tags"
                        selected={selectedTag}
                        onAdd={onTagAdd}
                        onDelete={onTagDelete}
                        suggestions={suggestions}
                        onInput={onInput}
                        noOptionsText={noOptionsText} />
                    </div>
                  </div>
                    ) : null}

                {integrationType === "kajabi" ? (
                  <div className="form-group">
                    <label htmlFor="webhook-url">Kajabi Webhook Url</label>
                    <input id="webhook-url" className="form-control" name="kajabi_offer_webhook_url" type="text" value={programDetail.kajabi_offer_webhook_url || ""} onChange={onChange} />
                  </div>
                    ) : null}
                {/* TODO Linking code later */}
                <div className="form-group">
                  <div>Unique Code</div>
                  <div className="row align-items-center">
                    <div className="col-6"><h5>{programDetail.unique_id}</h5></div>
                    {/* <div className="col-6"><div className="btn btn-outline-main btn-sm">Re-generate</div></div> */}
                  </div>
                </div>
                <div className="form-group">
                  <div>Unique Sign Up URL</div>
                  <div className="row align-items-center">
                    <div className="col-12"><h5>{programDetail.unique_signup_url}</h5></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header"><h5>Program Statistics</h5></div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="start-date">From Date</label>
                    <input
                      id="start-date"
                      defaultValue={minDate}
                      onChange={handleStartDate}
                      max={today}
                      min={minDate}
                      className="form-control"
                      type="date" />
                  </div>
                  <div className="col-md-6 mt-4 mt-md-0">
                    <label htmlFor="end-date">To Date</label>
                    <input
                      id="end-date"
                      defaultValue={today}
                      onChange={handleEndDate}
                      max={today}
                      min={minDate}
                      className="form-control"
                      type="date" />
                  </div>
                  {programStats.map(stat => (
                    <div className="col-md-6" key={stat.label}>
                      <div className="background-grey p-3 mt-4">
                        <p>{stat.label}</p>
                        <h2>{stat.number}</h2>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 mt-4">
                    <button className="btn btn-outline-main btn-sm" onClick={handleExportUsers} type="button">Export</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header"><h5>MIS Stats</h5></div>
              <div className="card-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Total Completed Attempts</th>
                      <th scope="col">Completed Once (=1)</th>
                      <th scope="col">
                        Completed More than Once (
                        {">"}
                        1)
                      </th>
                      <th scope="col">
                        Completed at Least Once (
                        {">="}
                        1)
                      </th>
                      <th scope="col">In Progress</th>
                      {/* <th scope="col">Avg Completion Time</th>
                      <th scope="col">Avg Resumption Time</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {calculatorStats.map(stat => (
                      <tr key={stat.id}>
                        <td><b>{stat.calculator_type}</b></td>
                        <td className="">
                          {stat.completions}
                        </td>
                        <td className="">
                          {stat.completed_once}
                        </td>
                        <td className="">
                          {stat.completed_more_than_once}
                        </td>
                        <td className="">
                          {stat.completed_at_least_once}
                        </td>
                        <td className="">
                          {stat.in_progress}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h5>Calculators</h5>
              </div>
              <div className="card-body">
                <table className="table">
                  <tbody>
                    {ALLOWED_CALCULATOR_TYPES.map(calculatorType => (
                      <tr key={calculatorType}>
                        <td className="align-middle">{convertSnakeToTitle(calculatorType)}</td>
                        <td className="text-right">
                          <SwitchWithInflight
                            baseUrl={baseUrl}
                            currentUser={currentUser}
                            calculatorType={calculatorType}
                            calculatorDetail={programDetail.calculators.find(calc => calc.calculator_type === calculatorType)}
                            programDetail={programDetail}
                            programId={programId} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h5>Pulse Check</h5>
              </div>
              <div className="card-body">
                {customTemplates && (
                  <>
                    <div className="form-group">
                      <label htmlFor="pulse-check">Pulse Check</label>
                      <select
                        id="pulse-check"
                        className="form-control"
                        name="question_type"
                        onChange={onUpdatePulseCalculator}
                        value={selectedCustomTemplate?.name}>
                        <option value="">Select a Pulse Check</option>
                        {customTemplates.map(template => (
                          <option key={template.name} value={template.name}>{template.name}</option>
                        ))}
                      </select>
                    </div>
                    {selectedCustomTemplate && (
                      <p>
                        Current Live Calculator:
                        {" "}
                        <strong>{selectedCustomTemplate?.name}</strong>
                      </p>
                    )}
                    {/* <div className="form-group-action mb-4">
                      <button
                        className="btn btn-link"
                        type="button"
                        onClick={onAddReminder}>
                        + Add Reminder
                      </button>
                      {!!reminderList?.length && reminderList.map((reminder, index) => (
                        <Reminder
                          // DEVNOTE: using index as key is okay in this case where there are no unique_ids
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          reminderList={reminderList}
                          reminder={reminder}
                          setReminderList={setReminderList}
                          index={index} />
                        ))}
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="publish-date">Publish Date</label>
                      <input
                        id="publish-date"
                        className="form-control"
                        onChange={onUpdatePublishDate}
                        name="published_date"
                        type="date"
                        value={dayjs(calculatorPublishDate, "YYYY-MM-DD").format("YYYY-MM-DD")} />
                      {/* TODO: add validations if needed */}
                      {/* {!calculatorPublishDate && (<p className="mt-3 text-danger">Please enter an age of 16 or older.</p>)} */}
                      {selectedCustomTemplate?.status === "active" && (
                        <p>
                          Current Live Calculator published at:
                          {" "}
                          <strong>{selectedCustomTemplate?.published_date_pretty}</strong>
                        </p>
                      )}
                    </div>
                    {/* <div className="btn btn-main btn-xs mr-2">Export CSV</div> */}
                    {/* <div className="btn btn-main btn-xs" data-target="#pulse-group-result" data-toggle="modal" type="button">Generate Group Result</div> */}
                    {/* <div aria-hidden="true" aria-labelledby="pulse-group-resultLabel" className="modal fade" id="pulse-group-result" role="dialog" tabIndex={-1}>
                      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="pulse-group-resultLabel">Group Result</h5>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true"> ×</span></button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-6 col-md-4">
                                <div className="form-group">
                                  <label>From Date</label>
                                  <input className="form-control" type="date" />
                                </div>
                              </div>
                              <div className="col-6 col-md-4">
                                <div className="form-group">
                                  <label>To Date</label>
                                  <input className="form-control" type="date" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header"><h5>Demographic Questions</h5></div>
              <div className="card-body">
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Age</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch1"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name={0}
                            checked={form[0]?.active || false} />
                          <label className="custom-control-label" htmlFor="customSwitch1" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Job Level</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch2"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name={1}
                            checked={form[1]?.active || false} />
                          <label className="custom-control-label" htmlFor="customSwitch2" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Sex</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch3"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name={2}
                            checked={form[2]?.active || false} />
                          <label className="custom-control-label" htmlFor="customSwitch3" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Socialdemographic - Language at home</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch4"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name={3}
                            checked={form[3]?.active || false} />
                          <label className="custom-control-label" htmlFor="customSwitch4" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Socialdemographic - Aboriginal/Torres Strait Islander</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch5"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name={4}
                            checked={form[4]?.active || false} />
                          <label className="custom-control-label" htmlFor="customSwitch5" />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Socialdemographic - Country of Birth</td>
                      <td className="text-right">
                        <div className="custom-control custom-switch">
                          <input
                            className="custom-control-input"
                            id="customSwitch6"
                            type="checkbox"
                            onChange={onSwitchClick}
                            name={5}
                            checked={form[5]?.active || false} />
                          <label className="custom-control-label" htmlFor="customSwitch6" />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="form-container-cta fixed-bottom">
            <Link
              className="btn btn-outline-main"
              to={`/backend/units/${programDetail.unit_id}`}>
              Cancel
            </Link>
            <button
              className="btn btn-main"
              disabled={!changesDetected}
              onClick={onSubmit}
              type="button">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProgramDetailPage
