import React, { useEffect, useState } from "react"

const InputQuestion = ({
  answer, descriptor, formIndex, label, imageElement, onChangeHandler, questionType, selectedAnswer, minValue, maxValue
}) => {
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    if (questionType.includes("time_input")) {
      setHours(selectedAnswer?.hours)
      setMinutes(selectedAnswer?.minutes)
      setSeconds(selectedAnswer?.seconds)
    }
  }, [selectedAnswer])

  const onTimeChange = e => {
    const { name, value } = e.target
    if (name === "hours") {
      setHours(value)
    } else if (name === "minutes") {
      setMinutes(value)
    } else if (name === "seconds") {
      setSeconds(value)
    }
  }

  const onTimeBlur = () => {
    onChangeHandler({
      target: {
        name: formIndex, id: answer.id, hours, minutes, seconds
      }
    })
  }

  const parseDescription = description => {
    if (description) {
      const [label, ...parsedDescription] = description.split("LINEBREAK")
      return (
        <td>
          <div className="strong mb-2">{label}</div>
          {!!parsedDescription.length && parsedDescription.map(line => (
            <div className="mb-2" key={line}><span>{line}</span></div>
          ))}
        </td>
      )
    }
    return null
  }

  return (
    <>
      <div className="q-group no-scroll">
        <p>{parseDescription(label)}</p>
        {!!imageElement && (imageElement(formIndex))}
        <div className="small">
          {/* Possibly need to use LINEBREAK function here */}
          <i>{descriptor}</i>
        </div>
        <div className="mt-4">
          {questionType === "input" ? (
            <div>
              <div className="form-group row">
                <label className="col-5 col-md-3 col-form-label strong">{answer.label}</label>
                <div className="col-6 col-sm-5">
                  <input
                    className="form-control"
                    id={answer.id}
                    name={formIndex}
                    onChange={onChangeHandler}
                    step="1"
                    min={minValue}
                    max={maxValue}
                    onWheel={e => e.target.blur()}
                    pattern="[0-9]*"
                    type="number"
                    inputMode="decimal"
                    value={selectedAnswer?.number_field || ""} />
                </div>
              </div>
              {(selectedAnswer && selectedAnswer?.errors?.length > 0) && (selectedAnswer.errors.map(error => (
                <p key={error} className="bottom-cta mb-5 pb-3 d-flex justify-content-center bg-warning">{error}</p>
              )))}
            </div>
          ) : (
            <>
              {questionType.includes("hours") && (
                <div className="form-group row">
                  <label className="col-5 col-md-3 col-form-label strong">Hours</label>
                  <div className="col-6 col-sm-5">
                    <input
                      className="form-control mr-2"
                      max={167}
                      min={0}
                      name="hours"
                      onBlur={onTimeBlur}
                      onChange={onTimeChange}
                      onWheel={e => e.target.blur()}
                      // pattern="/d+"
                      pattern="[0-9]*"
                      step="1"
                      type="number"
                      value={hours || ""} />
                  </div>
                </div>
              )}
              {questionType.includes("minutes") && (
                <div className="form-group row">
                  <label className="col-5 col-md-3 col-form-label strong">Minutes</label>
                  <div className="col-6 col-sm-5">
                    <input
                      className="form-control mr-2"
                      max={59}
                      min={0}
                      name="minutes"
                      onBlur={onTimeBlur}
                      onChange={onTimeChange}
                      onWheel={e => e.target.blur()}
                      pattern="/d+"
                      step="1"
                      type="number"
                      value={minutes || ""} />
                  </div>
                </div>
              )}
              {/* {questionType.includes("seconds") && (
                <div className="form-group row">
                  <label className="col-5 col-md-3 col-form-label strong">Seconds</label>
                  <div className="col-6 col-sm-5">
                    <input
                      className="form-control"
                      max={60}
                      min={0}
                      name="seconds"
                      onBlur={onTimeBlur}
                      onChange={onTimeChange}
                      onWheel={e => e.target.blur()}
                      pattern="/d+"
                      step="15"
                      type="number"
                      value={seconds || ""} />
                  </div>
                </div>
              )} */}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default InputQuestion